import React, { Component } from "react";
import { Spin } from "@arco-design/web-react";
import { IconStarFill, IconRight } from "@arco-design/web-react/icon";
import { getconfig } from "api/apis";
import "./index.scss";
// 封装组件
class Billingmethod extends Component {
  constructor(props) {
    super(props);
    this.state = {
      loadingbody: false,
      data: {
        heads: {
          //头部配置-width：头部对应宽度
          套餐类型: { width: "15%" },
          收费项目: { width: "20%" },
          收费计量: { width: "20%" },
          收费金额: { width: "20%" },
          资费说明: { width: "25%" },
        },
        bodys: [],
      },
    };
  }
  componentDidMount() {
    this.gitconfig();
  }
  UNSAFE_componentDidUpdate() {}
  setObject(arr) {
    let newarray = [];
    for (let key in arr) {
      newarray.push(key);
    }
    return newarray;
  }

  gitconfig() {
    getconfig({ key: "commercial-rules" }).then((res) => {
      if (res.code === 200) {
        let list = res.data;
        let obj = list.value;
        const test = {
          "heads": {
            "产品": {
              "width": "10%"
            },
            "服务项": {
              "width": "25%"
            },
            "说明": {
              "width": "35%"
            },
            "费用": {
              "width": "10%"
            },
            "资费说明": {
              "width": "20%"
            },
          },
          "bodys": [
            {
              "type": "智能PPT",
              "payservice": [
                {
                  "title": "生成PPT",
                  "function":["主题/描述生成PPT","大纲生成PPT"],
                  "metering": [
                    "一句话描述生成PPT",
                    "基于给定的大纲结构文本生成PPT",
                  ],
                  "moneys": [
                    "0",
                    "0",
                  ]
                },
                {
                  "title": "步骤生成PPT",
                  "function":["生成大纲与标题","生成PPT预览图","重新生成PPT"],
                  "metering": [
                    "根据描述需求生成大纲与标题内容",
                    "获取生成文档的实时渲染预览图",
                    "基于当前生成PPT进行重新生成PPT（一句话描述）",
                  ],
                  "moneys": [
                    "0",
                    "0",
                    "0",
                  ]
                },
                {
                  "title": "导出下载",
                  "function":["下载导出PDF文件","下载长图文件","下载导出PPTX文件"],
                  "metering": [
                    "下载导出PDF文件",
                    "下载长图文件",
                    "可编辑的PPTX文件",
                  ],
                  "moneys": [
                    "0",
                    "0",
                    "1.5元/份",
                  ]
                },
                {
                  "title": "深度编辑",
                  "function":["新增单页","全文字体","全文动画","更改模版","更改主题色","生成备注/演讲稿"],
                  "metering": [
                    "基于指定需求描述生成单页（需要给定task_id）补充生成",
                    "基于当前文档全文更换字体，例如宋体、楷书等",
                    "基于当前文件设置全文PPT动画",
                    "重新更改当前文档风格",
                    "更改当前文件的全文风格颜色",
                    "基于给定的文件生成全文演讲稿",
                  ],
                  "moneys": [
                    "0.1元/次",
                    "0.1元/次",
                    "0.1元/次",
                    "0.2元/次",
                    "0.2元/次",
                    "0.1元/次",
                  ]
                },
                {
                  "title": "特色文档生成",
                  "function":["通过论文生成PPT（特色生成）","通过文件生成PPT（特色生成）","基于网页链接生成"],
                  "metering": [
                    "导出论文文档生成PPT（可以指定风格、学校等主题色）",
                    "基于给定的文件进行生成PPT，支持Word、PDF、脑图等23种文件格式",
                    "基于给定的在线网页链接生成PPT",
                  ],
                  "moneys": [
                    "1.5元/次",
                    "0.5元/次",
                    "0.5元/次",
                  ]
                },
          ],
          "moneysMetering":`阶梯式充值：<br/>
                        （1）充值金额<2W，无折扣<br/>
                        （2）2W≤充值金额<5W，享受调用费用八折优惠<br/>
                        （3）5W≤充值金额，享受调用费用六折优惠`
        },
        {
          "type": "智能PPT(Sass版本)",
          "payservice": [
            {
              "title": "PPT文档生成",
              "function":["快速模式"],
              "metering": [
                "基于用户指令生成标题、大纲、模版、丰富度，最终生成PPT",
              ],
              "moneys": [
                "0.5元/次",
              ]
            },
            {
              "title": "AI标题大纲生成",
              "function":["快速模式"],
              "metering": [
                "基于用户指令生成标题、大纲",
              ],
              "moneys": [
                "0.05元/次",
              ]
            },
            {
              "title": "AI模版生成（风格、主题色）",
              "function":["/"],
              "metering": [
                "生成文档的实时渲染预览图",
              ],
              "moneys": [
                "0.2元/次",
              ]
            },
            {
              "title": "PPT单页生成",
              "function":["/"],
              "metering": [
                "生成单页PPT",
              ],
              "moneys": [
                "0.05元/次",
              ]
            },
            {
              "title": "多文本生成 （最大30M）",
              "function":["/"],
              "metering": [
                "基于用户上传多份文档生成标题、流式大纲、模板、丰富度，最终生成PPT",
              ],
              "moneys": [
                "2元/次",
              ]
            },
            {
              "title": "PPT下载",
              "function":["PDF","PNG","PPTX"],
              "metering": [
                "下载PDF文件",
                "下载PNG文件",
                "下载PPTX文件",
              ],
              "moneys": [
                "0",
                "0",
                "1.5元/份",
              ]
            },
            {
              "title": "智能编辑",
              "function":["更换主题色","更换模版","更换字体","翻译文档"],
              "metering": [
                "根据指令更换模板颜色",
                "根据所选模版，基于已生成的内容生成新的ppt",
                "全文更换字体",
                "根据指令翻译文档为指定语言",
              ],
              "moneys": [
                "0.2元/次",
                "0.2元/次",
                "0.1元/次",
                "0.2元/次"
              ]
              },
            ],
           "moneysMetering":`支持品牌LOGO联名展示，个性化产品页背景设计。特设汇报人、企业名称及LOGO专属字段`
            },
          {
              "type": "智能简历",
              "payservice": [
                {
                  "title": "简历生成与美化",
                  "function":["简历生成","简历美化"],
                  "metering": [
                    "基于关键词生成一份内容完整的简历",
                    "基于简历文件更换简历模板",
                  ],
                  "moneys": [
                    "0.5元/次",
                    "0.2元/次",
                  ]
                },
                {
                  "title": "简历处理与分析",
                  "function":["简历解析","简历分析"],
                  "metering": [
                    "解析简历文件，将简历内容数据结构化",
                    "评估简历质量，提供优化建议",
                  ],
                  "moneys": [
                    "0.1元/次",
                    "0.5元/次",
                  ]
                },
                {
                  "title": "求职辅助与匹配",
                  "function":["人岗匹配","人岗匹配"],
                  "metering": [
                    "基于用户简历和岗位JD，智能分析人岗是否匹配",
                    "推送符合用户职业发展规划的岗位信息",
                  ],
                  "moneys": [
                    "0.1元/次",
                    "1元/次（10个匹配结果）",
                  ]
                },
          ],
          "moneysMetering":``
        },
    {
      "type": "智能营销",
      "payservice": [
        {
          "title": "营销文案",
          "function":["/"],
          "metering": [
            "基于文案要求，智能生成符合需求的营销文案",
          ],
          "moneys": [
            "0.05元/千字符",
          ]
        },
      ],
      "moneysMetering":`费用 = 实际字符数 × 0.00005元`
    },
    {
      "type": "智能合同",
      "payservice": [
        {
          "title": "合同生成",
          "function":["/"],
          "metering": [
            "基于合同要求，智能生成符合需求的合同",
          ],
          "moneys": [
            "0.3元/次",
          ]
        },
      ],
      "moneysMetering":``
    }

        ]
        }
        
        this.setState({
          data: test,
        });
        // this.setheight("th_id00", 2, "th_id000");
      }
    });
  }
  setbackground(color) {
    return color ? color : "transparent";
  }
  setheight(e, explains_length, explains_id) {
    setTimeout(() => {
      let height = document.getElementById(e);
      if (height) {
        let new_height = height.clientHeight / explains_length + "px";
        // console.log(new_height, "height.clientHeight", e);
        document.getElementById(explains_id).height = new_height;
      }
    }, 10000);
  }
  render() {
    const { loadingbody, data } = this.state;
    var widthleft = 0;
    return (
      <div className="billing-method">
        {loadingbody ? (
          <Spin loading={loadingbody} dot className="loadingbody"></Spin>
        ) : (
          <>
            <div className="Header-init">
              <div className="Header-title">计费方式</div>
            </div>
            <div className="payment-explain">
              <div className="payment-explain-lable AlibabaPuHuiTi-Medium">
                <IconStarFill />
                使用说明
              </div>
              <div>
                1.系统采用专有权益包、权益包、预充值三种计费模式，以下表格是预充值的计费说明。
                <br />
                2.预充值以接口请求成功次数作为核算口径进行计费，不同的产品，单次请求的价格不同。
              </div>
            </div>
            <div className="billing-list">
              <div className="billingBanner">
                <div className="AlibabaPuHuiTi-Medium">
                  韦尼克智能创作平台资源计费说明
                </div>
                <div>支持企业级定制需求，详情请联系客服</div>
              </div>
              <div className="billing-table">
                <div className="table-th table-head rowStart">
                  {this.setObject(data.heads).map((item, key) => {
                    return (
                      <div
                        className="table-td rowCenter"
                        key={key}
                        style={{ "--width": data.heads[item].width }}
                      >
                        {item}
                      </div>
                    );
                  })}
                </div>
                <div className="table-bodys">
                  {this.setObject(data.heads).map((item, key) => {
                    widthleft += parseInt(data.heads[item].width);
                    return (
                      <div
                        className="table-hr"
                        key={key}
                        style={{ "--left": widthleft + "%" }}
                      ></div>
                    );
                  })}

                  {data.bodys.map((item, key) => {
                    let id = "th_id" + key;
                    return (
                      <div className="table-th rowStart" key={key} id={id}>
                        <div
                          className="table-td columnCenter"
                          style={{
                            "--width":
                              data.heads[this.setObject(data.heads)[0]].width,
                          }}
                        >
                          <div
                            className="table-td-full columnCenter"
                            style={{
                              "--background": this.setbackground("#FAFAFA"),
                              "--width":
                                data.heads[this.setObject(data.heads)[0]].width,
                            }}
                          >
                            {item.type}
                          </div>
                        </div>

                        <div
                          className="table-td"
                          style={{
                            "--width":
                              parseInt(
                                data.heads[this.setObject(data.heads)[1]].width
                              ) +
                              parseInt(
                                data.heads[this.setObject(data.heads)[2]].width
                              ) +
                              parseInt(
                                data.heads[this.setObject(data.heads)[3]].width
                              ) +
                              "%",
                          }}
                        >
                          {item.payservice.map((item2, key2) => {
                            let ids = id + key2;
                            return (
                              <div
                                className="table-th rowCenter"
                                key={key2}
                                id={ids}
                              >
                                {/* <div className="table-td">{item2.title}</div> */}

                                <div className="table-td"
                                 style={{
                                  "--width": "50%",
                                  "position":"relative"
                                }}>
                                  <div className="table-th" key={key2}
                                   style={{
                                    "margin-left": "40%",
                                  }}
                                  >
                                    {item2.function.map((item3, index) => {
                                      return (
                                        <div
                                          className="table-th columnCenter"
                                          key={index}
                                          style={{
                                            "width": "60%",
                                          }}
                                        >
                                          <div>{item3}</div>
                                        </div>
                                      );
                                    })}
                         
                                  </div>
                                       <div 
                                      className="table-th table-product"
                                      style={{
                                        height: item2.metering.length * 58 + "px",
                                        // "top": key2 * 57 + "px",
                                        // "--width":
                                        // parseInt(data.heads[this.setObject(data.heads)[0]].width.replace('%', ''), 10) / 2 + "%",
                                      }}
                                      >
                                        {item2.title}
                                        </div>
                                </div>

                                <div className="table-td"
                                style={{
                                  "--width": "70%",
                                }}>
                                  <div className="table-th">
                                    {item2.metering.map((item3, index) => {
                                      return (
                                        <div
                                          className="table-th columnCenter"
                                          key={index}
                                        >
                                          <div
                                          style={{
                                            "width": "95%",
                                          }}>{item3}</div>
                                        </div>
                                      );
                                    })}
                                  </div>
                                </div>
                                <div className="table-td"
                                style={{
                                  "--width": "20%",
                                }}>
                                  <div className="table-th" key={key2}>
                                    {item2.moneys.map((item3, index) => {
                                      return (
                                        <div
                                          className="table-th columnCenter"
                                          key={index}
                                        >
                                          <div>{item3}</div>
                                        </div>
                                      );
                                    })}
                                  </div>
                                </div>

                              

                              </div>
                            );
                          })}
                        </div>

                        <div className="table-td"
                                 style={{
                                  "--width":
                                    data.heads[this.setObject(data.heads)[4]].width,
                                  "padding":"0 10px"
                                }}>
                                  <div className="table-th" key={key}>
                                    <div
                                      className="table-th columnCenter"
                                      style={{
                                        "textAlign":'left'
                                      }}
                                    >
                                      {/* <div>{item.moneysMetering}</div> */}
                                      <div dangerouslySetInnerHTML={{ __html: item.moneysMetering }}></div>
                                    </div>
                                  </div>
                                </div>

                        {/* <div
                          className="table-td table-explains columnCenter"
                          style={{
                            "--background": this.setbackground(),
                            "--width":
                              data.heads[this.setObject(data.heads)[0]].width,
                          }}
                        >
                          {item.function.map((item_html, item_key) => {
                            let explains_id = id + "0" + item_key;
                            return (
                              <div
                                key={item_key}
                                className="setheight table-th columnCenter"
                                style={{
                                  "--height":
                                    item.function.length > 1 ? "200px" : "",
                                }}
                                id={explains_id}
                                dangerouslySetInnerHTML={{
                                  __html: item_html,
                                }}
                              ></div>
                            );
                          })}
                        </div> */}
                      </div>
                    );
                  })}
                </div>
              </div>
            </div>
          </>
        )}
      </div>
    );
  }
}
export default Billingmethod;
