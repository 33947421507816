import React, { Component } from "react";
import {
  Grid,
  DatePicker,
  Radio,
  Space,
  Divider,
  Typography,
  Button,
  Select,
} from "@arco-design/web-react";
import {
  IconCalendar,
  IconShareExternal,
  IconDownload,
} from "@arco-design/web-react/icon";
import SaasBreadcrumb from "components/SaasBreadcrumb";
import LineEcharts from "components/LineEcharts/index";
import { getstatistics, getmodelList } from "api/apis";
import "./appdataset.scss";
const Option = Select.Option;
const Row = Grid.Row;
const Col = Grid.Col;
const RangePicker = DatePicker;
class AppDataset extends Component {
  constructor(props) {
    super(props);
    this.state = {
      data: {
        x: [],
        y: [],
      },
      dataerr: {
        x: [],
        y: [],
      },
      value: "近30日",
      rangeValue: ["", ""],
      postrangeValue: ["", ""],
      request_uri: "/v2/resume/continue",
      record: JSON.parse(localStorage.getItem("record")),
      Selectoptions: JSON.parse(localStorage.getItem("Selectoptions")),
      Tablist: ["今日", "昨日", "近7日", "近30日"],
      ishowdata: false,
      optionVlue: "",
      optionlist: [{ id: "", name: "全部" }],
      pagination: {
        total: 0,
        pageSize: 20,
        current: 1,
      },
    };
  }

  handleResize = () => {
    var winHeight = 0;
    if (window.innerHeight) {
      winHeight = window.innerHeight;
    } else if (document.body && document.body.clientHeight) {
      winHeight = document.body.clientHeight;
    }
    if (document.documentElement && document.documentElement.clientHeight) {
      winHeight = document.documentElement.clientHeight;
    }
    let height =
      winHeight -
      document.getElementById("layoutHeaders").clientHeight -
      document.getElementById("Headertitle").clientHeight -
      60;
    document.getElementById("order_id").style.height = height + "px";
    // //console.log(height, 'divHeight')
  };
  componentDidMount() {
    window.addEventListener("resize", this.handleResize);
    setTimeout(() => {
      this.handleResize();
    }, 100);
    this.getstatistics();
    this.getmodelList();
    document.addEventListener("click", this.hideAllMenu);
  }
  componentWillUnmount() {
    localStorage.removeItem("props");
    window.removeEventListener("resize", this.handleResize);
  }
  hideAllMenu = () => {
    if (this.state.ishowdata) {
      this.setState({
        ishowdata: !this.state.ishowdata,
      });
    }
  };
  openDate(event) {
    this.stopPropagation(event);
    this.setState({
      ishowdata: !this.state.ishowdata,
    });
  }
  onClickdate(event) {
    this.stopPropagation(event);
  }
  stopPropagation(e) {
    e.nativeEvent.stopImmediatePropagation();
  }
  getstatistics() {
    let type = "";
    if (this.state.value === "今日") {
      type = "today";
    } else if (this.state.value === "昨日") {
      type = "yesterday";
    } else if (this.state.value === "近7日") {
      type = "7days";
    } else if (this.state.value === "近30日") {
      type = "30days";
    }
    let page = {
      type: type, //today、yesterday、7days、30days
      app_id: this.state.record.app_id
        ? this.state.record.app_id
        : this.state.record.id,
      model_id: this.state.optionVlue,
      start_date: this.state.postrangeValue[0],
      end_date: this.state.postrangeValue[1],
    };
    getstatistics(page).then((res) => {
      let list = res.data;
      let ner = {
        x: [],
        y1: [],
        y2: [],
      };
      for (var i = 0; i < list.length; i++) {
        ner.x.push(list[i].key);
        ner.y1.push(list[i].value[0]);
        ner.y2.push(list[i].value[1]);
      }
      this.setState({
        data: ner,
      });
      //console.log(list, "list");
    });
  }
  getmodelList() {
    let pagination = this.state.pagination;
    let page = {
      page: pagination.current,
      per_page: pagination.pageSize,
      train_status: "训练成功",
      model_capability: this.state.record.model_capability,
    };
    getmodelList(page).then((res) => {
      if (res.code === 200) {
        pagination.total = res.data.count + 1;
        this.setState({
          optionlist: this.state.optionlist.concat(res.data.data),
          pagination: pagination,
        });
      }
    });
  }
  setValue(v) {
    this.state.value = v;
    this.setState({
      value: v,
    });
    this.getstatistics();
    //console.log(v);
  }
  setDates(dateString) {
    this.setState({
      rangeValue: dateString,
    });
  }
  onChange(dateString) {
    this.state.postrangeValue = dateString ? dateString : ["", ""];
    this.state.value = "";
    this.setState({
      rangeValue: dateString,
      value: "",
    });
  }
  onOk(dateString, date) {
    //console.log(date, "event");
    this.state.postrangeValue = dateString ? dateString : ["", ""];
    this.state.value = "";
    this.setState({
      postrangeValue: dateString,
      value: "",
      ishowdata: !this.state.ishowdata,
    });
    this.getstatistics();
  }
  setoptionVlue(value) {
    this.setState({
      optionVlue: value,
    });
    this.state.optionVlue = value;
    this.getstatistics();
  }
  popupScrollHandler(element) {
    const { scrollTop, scrollHeight, clientHeight } = element;
    const scrollBottom = scrollHeight - (scrollTop + clientHeight);
    if (
      scrollBottom === 0 &&
      this.state.pagination.total !== this.state.optionlist.length
    ) {
      let pagination = this.state.pagination;
      if (pagination.total !== this.state.optionlist.length) {
        pagination.current++;
      }
      this.getmodelList();
    }
  }
  render() {
    const {
      Tablist,
      Selectoptions,
      record,
      value,
      rangeValue,
      ishowdata,
      data,
      dataerr,
      optionlist,
      optionVlue,
    } = this.state;
    return (
      <div className="appdataset">
        <div className="Header-init" id="Headertitle">
          <div className="Header-title">调用详情</div>
        </div>
        <div id="order_id">
          <div className="GroupTable-fixe">
            <Space size={"medium"}>
              <Radio.Group
                type="button"
                name="direction"
                value={value}
                onChange={this.setValue.bind(this)}
                options={Tablist}
                className="GroupTable"
              ></Radio.Group>
              <div
                className="DatePicker-RangePicker"
                onClick={this.onClickdate.bind(this)}
              >
                <Button
                  shape="circle"
                  className="custom-White-button"
                  icon={<IconCalendar />}
                  onClick={this.openDate.bind(this)}
                />
                {ishowdata ? (
                  <DatePicker.RangePicker
                    triggerElement={null}
                    showTime
                    className="RangePicker-box"
                    value={rangeValue}
                    onSelect={(valueString, value) => {
                      this.setDates(value);
                    }}
                    onChange={(v) => this.onChange(v)}
                    onOk={this.onOk.bind(this)}
                    disabledDate={(current) => {
                      if (rangeValue && rangeValue.length && current) {
                        const tooLate =
                          rangeValue[0] &&
                          Math.abs(current.diff(rangeValue[0], "day")) > 28;
                        const tooEarly =
                          rangeValue[1] &&
                          Math.abs(rangeValue[1].diff(current, "day")) > 28;
                        return tooEarly || tooLate;
                      }
                      return false;
                    }}
                  />
                ) : (
                  ""
                )}
              </div>
            </Space>
            <div>
              {/* <Select
                value={optionVlue}
                placeholder="模型选择"
                style={{ width: 220 }}
                className="versionsSelect"
                onPopupScroll={this.popupScrollHandler.bind(this)}
                onChange={this.setoptionVlue.bind(this)}
                getPopupContainer={(triggerNode) => triggerNode.parentNode}
              >
                {optionlist.map((option, index) => (
                  <Option key={index} value={option.id}>
                    {option.name}
                  </Option>
                ))}
              </Select> */}
              <Button
                shape="circle"
                disabled
                className="custom-White-button"
                icon={<IconDownload />}
              />
            </div>
          </div>
          <div className="appdataset-card">
            <Typography.Title heading={6} className="appdataset-card-title">
              调用详情
            </Typography.Title>
            <Divider />
            <div className="appdataset-Echarts">
              <div className="linecharts">
                <LineEcharts data={data} yname="单位：条" title="" />
              </div>
              <Divider />
              <Space align="center" size={"large"} className="line-lable">
                <Space align="center" size={"medium"}>
                  <div className="blue"></div>
                  <div>成功调用总量</div>
                </Space>
                <Space size={"medium"}>
                  <div className="cyan"></div>
                  <div>失败调用总量</div>
                </Space>
              </Space>
            </div>
          </div>
        </div>
      </div>
    );
  }
}

export default AppDataset;
