import React, { Component } from "react";
import {
  Grid,
  DatePicker,
  Radio,
  Space,
  Divider,
  Empty,
  Button,
  Select,
  Spin,
  Table,
  Tag,
} from "@arco-design/web-react";
import {
  IconCalendar,
  IconDownload,
  IconCheckCircleFill,
  IconCloseCircleFill,
} from "@arco-design/web-react/icon";
import { call_statistics } from "api/apis";
import { getCurrentDate } from "common/utils";
import Emptydata_img from "asserts/Emptydata_img.svg";
import "./calldetails.scss";
const Option = Select.Option;
const Row = Grid.Row;
const Col = Grid.Col;
const RangePicker = DatePicker;
class CallDetails extends Component {
  constructor(props) {
    super(props);
    this.state = {
      loadingbody: true,
      value: "今日",
      rangeValue: ["", ""],
      postrangeValue: ["", ""],
      Tablist: ["今日", "昨日", "近7日", "近30日"],
      ishowdata: false,
      data: [{}],
      columns: [
        {
          title: "接口名称",
          dataIndex: "model_capability",
          align: "center",
          headerCellStyle: {
            textAlign: "center",
          },
        },

        // {
        //   title: "模型名称",
        //   dataIndex: "model_name",
        //   align: "center",
        //   headerCellStyle: {
        //     textAlign: "center",
        //   },
        // },
        {
          title: "调用次数",
          dataIndex: "total",
          align: "center",
          headerCellStyle: {
            textAlign: "center",
          },
          width: 168,
        },
        {
          title: "调用状态",
          dataIndex: "result",
          align: "center",
          headerCellStyle: {
            textAlign: "center",
          },
          render: (col, record, index) => (
            <span>
              {
                <Tag
                  color={col === "SUCCESS" ? "green" : "gray"}
                  icon={
                    col === "SUCCESS" ? (
                      <IconCheckCircleFill style={{ color: "#00B42A" }} />
                    ) : (
                      <IconCloseCircleFill style={{ color: "#86909C" }} />
                    )
                  }
                >
                  {col === "SUCCESS" ? "调用成功" : "调用失败"}
                </Tag>
              }
            </span>
          ),
        },
        // {
        //   title: "资源抵扣选项",
        //   dataIndex: "package_name",
        //   align: "center",
        //   headerCellStyle: {
        //     textAlign: "center",
        //   },
        // },
        {
          title: "调用时间",
          dataIndex: "date",
          align: "center",
          headerCellStyle: {
            textAlign: "center",
          },
        },
      ],
      columns2: [
        {
          title: "接口名称",
          dataIndex: "app_name",
          align: "center",
          headerCellStyle: {
            textAlign: "center",
          },
        },

        // {
        //   title: "模型名称",
        //   dataIndex: "model_name",
        //   align: "center",
        //   headerCellStyle: {
        //     textAlign: "center",
        //   },
        // },
        {
          title: "调用次数",
          dataIndex: "num",
          align: "center",
          headerCellStyle: {
            textAlign: "center",
          },
          width: 168,
        },
        {
          title: "调用状态",
          dataIndex: "status",
          align: "center",
          headerCellStyle: {
            textAlign: "center",
          },
          render: (col, record, index) => (
            <span>
              {
                <Tag
                  color={col === "SUCCESS" ? "green" : "gray"}
                  icon={
                    col === "SUCCESS" ? (
                      <IconCheckCircleFill style={{ color: "#00B42A" }} />
                    ) : (
                      <IconCloseCircleFill style={{ color: "#86909C" }} />
                    )
                  }
                >
                  {col === "SUCCESS" ? "调用成功" : "调用失败"}
                </Tag>
              }
            </span>
          ),
        },
        // {
        //   title: "资源抵扣选项",
        //   dataIndex: "package_name",
        //   align: "center",
        //   headerCellStyle: {
        //     textAlign: "center",
        //   },
        // },
        {
          title: "调用时间",
          dataIndex: "created_at",
          align: "center",
          headerCellStyle: {
            textAlign: "center",
          },
        },
      ],
      pagination: {
        sizeCanChange: false,
        showTotal: true,
        total: 0,
        pageSize: 10,
        current: 1,
        pageSizeChangeResetCurrent: true,
        showJumper: false,
      },
      loading: true,
      no_data: false,
      activeIndex: "PACKAGE",
      typeList: {
        PACKAGE: "接口调用量",
        // "REAL-TIME": "实时",
      },
      record: JSON.parse(localStorage.getItem("record")),
    };
  }

  componentDidMount() {
    document.addEventListener("click", this.hideAllMenu);
    this.getpackage();
  }
  hideAllMenu = () => {
    if (this.state.ishowdata) {
      this.setState({
        ishowdata: !this.state.ishowdata,
      });
    }
  };
  openDate(event) {
    this.stopPropagation(event);
    this.setState({
      ishowdata: !this.state.ishowdata,
    });
  }
  onClickdate(event) {
    this.stopPropagation(event);
  }
  stopPropagation(e) {
    e.nativeEvent.stopImmediatePropagation();
  }
  setValue(v) {
    this.state.value = v;
    let packagePagination = this.state.pagination;
    packagePagination.current = 1;
    this.setState({
      value: v,
      pagination: packagePagination,
    });
    this.getpackage();
    //console.log(v);
  }
  setDates(dateString) {
    this.setState({
      rangeValue: dateString,
    });
  }
  onChange(dateString) {
    this.state.postrangeValue = dateString ? dateString : ["", ""];
    this.state.value = "";
    this.setState({
      rangeValue: dateString,
      value: "",
    });
  }
  onOk(dateString, date) {
    //console.log(date, "event");
    this.state.postrangeValue = dateString ? dateString : ["", ""];
    this.state.value = "";
    let packagePagination = this.state.pagination;
    packagePagination.current = 1;
    this.setState({
      postrangeValue: dateString,
      value: "",
      ishowdata: !this.state.ishowdata,
      pagination: packagePagination,
    });
    this.getpackage();
  }
  getpackage() {
    let type = "";
    if (this.state.value === "今日") {
      type = "today";
    } else if (this.state.value === "昨日") {
      type = "yesterday";
    } else if (this.state.value === "近7日") {
      type = "7days";
    } else if (this.state.value === "近30日") {
      type = "30days";
    }
    let page = {
      per_page: this.state.pagination.pageSize,
      page: this.state.pagination.current,
      dateType: type, //today、yesterday、7days、30days
      app_id: this.state.record?.show_id
        ? this.state.record?.show_id
        : '',
      start_date: type ? "" : this.state.postrangeValue[0],
      end_date: type ? "" : this.state.postrangeValue[1],
      type: "list",
    };
    call_statistics(page).then((res) => {
      if (res.code === 200) {
        let no_data = false;
        let list = res.data;
        let packagePagination = this.state.pagination;
        packagePagination.total = list.length;
        if (list.length === 0) {
          no_data = true;
        } else {
          no_data = false;
        }
        this.setState({
          no_data: no_data,
          data: list,
          pagination: packagePagination,
          loading: false,
          loadingbody: false,
        });
      } else {
        this.setState({
          loadingbody: false,
          loading: false,
          no_data: true,
          data: [],
        });
      }
    });
  }
  onChangeTable(pagination) {
    const { current, pageSize } = pagination;
    this.setState({
      loading: true,
    });
    setTimeout(() => {
      let setPagination = this.state.pagination;
      setPagination.current = current;
      setPagination.pageSize = pageSize;
      this.setState({
        loading: false,
        pagination: setPagination,
      });
      this.getpackage();
    }, 100);
  }
  onTabs(index) {
    let packagePagination = this.state.pagination;
    packagePagination.current = 1;
    this.state.activeIndex = index;
    this.setState({
      activeIndex: index,
      pagination: packagePagination,
    });
    this.getpackage();
  }
  render() {
    const {
      loadingbody,
      Tablist,
      value,
      rangeValue,
      ishowdata,
      data,
      loading,
      pagination,
      no_data,
      columns,
      columns2,
      activeIndex,
      typeList,
    } = this.state;
    return (
      <div className="callDetails">
        <div className="Header-init" id="Headertitle">
          <div className="Header-title">调用明细</div>
        </div>
        <div id="order_id">
          <div className="GroupTable-fixe">
            <Space size={"medium"}>
              <Radio.Group
                type="button"
                name="direction"
                value={value}
                onChange={this.setValue.bind(this)}
                options={Tablist}
                className="GroupTable"
              ></Radio.Group>
              <div
                className="DatePicker-RangePicker"
                onClick={this.onClickdate.bind(this)}
              >
                <Button
                  shape="circle"
                  className="custom-White-button"
                  icon={<IconCalendar />}
                  onClick={this.openDate.bind(this)}
                />
                {ishowdata ? (
                  <DatePicker.RangePicker
                    triggerElement={null}
                    showTime
                    className="RangePicker-box"
                    value={rangeValue}
                    onSelect={(valueString, value) => {
                      this.setDates(value);
                    }}
                    onChange={(v) => this.onChange(v)}
                    onOk={this.onOk.bind(this)}
                    disabledDate={(current) => {
                      if (rangeValue && rangeValue.length && current) {
                        const tooLate =
                          rangeValue[0] &&
                          Math.abs(current.diff(rangeValue[0], "day")) > 28;
                        const tooEarly =
                          rangeValue[1] &&
                          Math.abs(rangeValue[1].diff(current, "day")) > 28;
                        return tooEarly || tooLate;
                      }
                      return false;
                    }}
                  />
                ) : (
                  ""
                )}
              </div>
            </Space>
            <div>
              <Button
                shape="circle"
                className="custom-White-button"
                disabled
                icon={<IconDownload />}
              />
            </div>
          </div>
        </div>
        {loadingbody ? (
          <Spin dot className="table-spin-loading" />
        ) : (
          <>
            <div className="table-card">
              <div className="table-tabs rowStart">
                {Object.keys(typeList).map((item, index) => {
                  return (
                    <div
                      className={`table-lable ${
                        item === activeIndex ? `active` : ``
                      }`}
                      key={index}
                      onClick={this.onTabs.bind(this, item)}
                    >
                      <div
                        className={`tabs-title ${
                          item === activeIndex ? `AlibabaPuHuiTi-Medium` : ``
                        }`}
                      >
                        {typeList[item]}
                      </div>
                      <div className="table-hr"></div>
                    </div>
                  );
                })}
              </div>
              {pagination.total === 0 ? (
                <Empty
                  imgSrc={Emptydata_img}
                  description={"暂无相关资源"}
                  style={{ marginTop: 100, marginBottom: 100 }}
                />
              ) : (
                <Table
                  virtualized
                  no_data={no_data}
                  border={{ wrapper: false, cell: false }}
                  noDataElement={
                    <Empty
                      imgSrc={Emptydata_img}
                      description={"暂无相关资源"}
                    />
                  }
                  loading={loading}
                  columns={activeIndex === "PACKAGE" ? columns : columns2}
                  data={data}
                  pagination={pagination.total === 0 ? false : pagination}
                  onChange={this.onChangeTable.bind(this)}
                  // renderPagination={(paginationNode) => (
                  //   <div
                  //     style={{
                  //       display: "flex",
                  //       justifyContent: "flex-end",
                  //       marginTop: 10,
                  //     }}
                  //   >
                  //     {paginationNode}
                  //   </div>
                  // )}
                />
              )}
            </div>
          </>
        )}
      </div>
    );
  }
}

export default CallDetails;
