import React, { Component } from 'react'
import {
  Layout,
  Typography,
  Button,
  Space,
  Empty,
  Input,
  Select,
  Modal,
  Divider,
  Image,
  Notification,
} from '@arco-design/web-react'
import { IconDownload, IconPlus } from '@arco-design/web-react/icon'
import Emptydata_img from 'asserts/Emptydata_img.svg'
import Generating_img from 'asserts/Generating_img.gif'
import errorIcon from 'asserts/error_icon.svg'
import {
  getApiInfo,
  getRequest,
  putapp,
  putmodelversion,
  check_resource,
  getAllapiList,
  newGetappList,
  newEditappList,
} from 'api/apis'
import history from 'common/utils/history'
import { CreateLink } from 'common/utils/business'
import { debounce } from 'common/utils'
import { sourceCancelClass } from 'api/cancel-request'
import { localAxios } from "api/request";
import UpgradeTips from 'components/UpgradeTips'
import $ from 'jquery'
import './resumeDocument.scss'
const Content = Layout.Content
const Option = Select.Option

class SmartContract extends Component {
  constructor(props) {
    super(props)
    this.state = {
      Selectoptions: JSON.parse(localStorage.getItem('Selectoptions')),
      no_data: false,
      optionVlue: '',
      TextAreaValue: '',
      versions: [],
      ParagraphContinuation: [],
      ContinuationLoding: false,
      record: JSON.parse(localStorage.getItem('record')),
      apiInfo: {
        model_id: '',
      },
      package: 'false',
      requestMsg: '',
      requestMsgText: '您还未输入合同关键信息',
      attachment: [],
      fileList: [],
      visible: false,
      visibleimg: null,
      loading: false,
      keyword: '',
      downloadLoading: false,
      cancelToken: null,
      height: 240,
      input_describe: '珠海，产品经理，标准，劳动合同',
      setChangeAbatchTime: '',
      setRequestTime: '',
      visibleUpgrade: false,
      upgradetips: null,
      identity: JSON.parse(localStorage.getItem('userinfo'))
        ? JSON.parse(localStorage.getItem('userinfo')).group_permission
        : '',
      apiValue:'',
      interfaceOption:[],
      api_id:'',
      postValue:'',
    }
    this.isputapp = debounce(this.isputapp, 1000)
    this.isPhoneLegal = debounce(this.isPhoneLegal, 1000)
  }
  componentDidMount() {
    this.getApiInfo()
    window.addEventListener('resize', this.handleResize)
    setTimeout(() => {
      this.handleResize()
    }, 300)
  }
  componentWillUnmount() {
    localStorage.removeItem('props')
    window.removeEventListener('resize', this.handleResize)
    clearTimeout(this.state.setRequestTime)
    clearTimeout(this.state.setChangeAbatchTime)
    sourceCancelClass()
  }
  handleResize = () => {
    let winHeight = 0
    if (window.innerHeight) {
      winHeight = window.innerHeight
    } else if (document.body && document.body.clientHeight) {
      winHeight = document.body.clientHeight
    }
    if (document.documentElement && document.documentElement.clientHeight) {
      winHeight = document.documentElement.clientHeight
    }
    let layoutHeaders_h = document.getElementById('layoutHeaders').clientHeight
    if (layoutHeaders_h > winHeight) {
      layoutHeaders_h = 64
    }
    let height =
      winHeight -
      layoutHeaders_h -
      document.getElementById('renderHeaders').clientHeight -
      32 -
      45
    document.getElementById('layout_id').style.height = height + 'px'

    if (document.getElementById('continuation')) {
      this.initSlider()
      let height2 =
        height - document.getElementById('result_title').clientHeight - 34
      document.getElementById('continuation').style.height = height2 - 30 + 'px'
    }
  }

  openInterface(value){
    const { interfaceOption } = this.state;
    this.setState({
      api_id: interfaceOption[value].api_id,
      apiValue: interfaceOption[value].value,
      newApi_id: interfaceOption[value].api_id,
    })
  }
  
  getApiInfo(put) {
    const { interfaceOption } = this.state;
    
    let params = {
      id: this.state.record.show_id,
    }
    newGetappList(params).then((res) => {
      if (res.code === 200) {
        let list = res.data

          if (put) {
            this.setState({
              apiInfo: list,
            })
          } else{
            this.setState({
              apiInfo: list,
              apiValue: res.data.api_list[0].name,
              api_id:res.data.api_list[0].api_id,
              interfaceOption:interfaceOption,
              newApi_id:res.data.api_list
            })
          }
          this.getInterfaceParameter();
        //console.log(res.data, "api服务");
      } else {
        Notification.warning({ content: res.data.message })
      }
    })
  }
  onPressEnter(e) {
    const { keyword, postValue,input_describe,ParagraphContinuation } = this.state;

    this.setState({
      ContinuationLoding: true,
    })
    if(keyword == ''){
      this.state.keyword = this.state.input_describe
    }
    let params = {
      "text":keyword || input_describe || '',
    }
    let postApi = localAxios("post", "/contract/build");
    postApi(params).then((res) => {
      if(res.code === 200){
        let list = res.data;
        this.setState({
          ParagraphContinuation: list,
          ContinuationLoding: false,
          loading:false,
        })
      } else {
        this.setState({
          ContinuationLoding: false,
          loading:false,
        })
        Notification.error({ content: res.msg })
      }
    })
  }
  
  referData(e){
    const { keyword, postValue,input_describe,ParagraphContinuation } = this.state;

    this.setState({
      ContinuationLoding: false,
    })
    if(keyword == ''){
      this.state.keyword = this.state.input_describe
    }
    let params = {
      "text":keyword || input_describe || '',
    }
    let postApi = localAxios("post", "/contract/build");
    postApi(params).then((res) => {
      if(res.code === 200){
        let list = res.data;
        this.setState({
          ParagraphContinuation: {
            ...ParagraphContinuation,
            image: ParagraphContinuation.image.concat(list.image)
          },
          ContinuationLoding: false,
          loading:false,
        })
      } else if(res.code === 3003){
        this.setState({
          ContinuationLoding: false,
          loading:false,
        })
        Notification.warning({ content: res.msg })
      } else {
        this.setState({
          ContinuationLoding: false,
          loading:false,
        })
        Notification.error({ content: res.msg })
      }
    })
  }
  putmodelversion(optionVlue) {
    let params = {
      id: optionVlue,
    }
    putmodelversion(params).then((res) => {
      if (res.code === 200) {
        let list = res.data
        if (this.state.keyword) {
          this.isPhoneLegal()
        }
      } else {
        Notification.warning({ content: '模型版本激活失败！' })
      }
    })
  }
  onsetPosition(value) {
    //console.log(value, "value");
    this.setState({
      optionVlue: value,
    })
    this.putmodelversion(value)
  }
  onTextArea(value) {
    this.setState({
      keyword: value,
    })
  }
  isPhoneLegal = () => {
    this.setRequest()
  }
  setRequest() {
    clearTimeout(this.state.setRequestTime)
    clearTimeout(this.state.setChangeAbatchTime)
    if (this.state.keyword === '') {
      this.setState({
        ParagraphContinuation: [],
        ContinuationLoding: false,
        requestMsg: '',
        requestMsgText: '您还未输入合同关键信息',
      })
    } else {
      let params = {
        app_id: this.state.record.id,
        model_id: this.state.apiInfo?.model_id,
        text: this.state.keyword,
        package: this.state.package,
      }
      const { ParagraphContinuation } = this.state

      this.setState({
        loading: true,
      })
      let that = this
      this.getCheckResource(function (result) {
        if (result.code === 200) {
          getRequest(params).then((res) => {
            if (res.code === 200) {
              let list = res.data
              that.setState({
                ParagraphContinuation: ParagraphContinuation.concat(list),
                ContinuationLoding: false,
                requestMsg: '',
              })
              if (that.state.ParagraphContinuation.length % 7 !== 0) {
                that.state.setRequestTime = setTimeout(() => {
                  that.isPhoneLegal()
                }, 4000)
              } else {
                that.setState({
                  loading: false,
                })
              }
              if (
                that.state.ParagraphContinuation.length === 1 &&
                list.length > 0
              ) {
                setTimeout(() => {
                  that.handleResize()
                }, 100)
              }
            } else if (
              res.code === 400 ||
              res.code === 503 ||
              res.code === 3003
            ) {
              that.setState({
                ContinuationLoding: false,
                requestMsg: '',
                loading: false,
              })
              if (ParagraphContinuation.length > 0) {
                Notification.warning({ content: res.msg })
              } else {
                that.setState({
                  ParagraphContinuation: [],
                  requestMsgText: res.msg,
                })
              }
            } else {
              that.setState({
                ContinuationLoding: false,
                requestMsg: '',
                loading: false,
              })
              if (ParagraphContinuation.length > 0) {
                Notification.warning({ content: res.msg })
              } else {
                that.setState({
                  ParagraphContinuation: [],
                  requestMsgText: res.msg,
                  requestMsg: 'error',
                })
              }
            }
          })
        } else {
          that.setState({
            ContinuationLoding: false,
            requestMsg: '',
            loading: false,
          })
          if (ParagraphContinuation.length > 0) {
            Notification.warning({ content: result.msg })
          } else {
            that.setState({
              ParagraphContinuation: [],
              requestMsgText: result.msg,
              requestMsg: 'error',
            })
          }
        }
      })
    }
  }
  getCheckResource(callback) {
    let params = { model_capability: this.state.apiInfo?.model_capability }
    let PACKAGES = ['PACKAGE_FINISH', 'PACKAGE_EXPIRED']
    check_resource(params).then((res) => {
      if (res.code === 200) {
        if (res.data) {
          let new_upgradetips = res.data
          new_upgradetips.model_capability = params.model_capability
          this.setState({
            upgradetips: new_upgradetips,
          })
          if (PACKAGES[new_upgradetips.notice_type]) {
            Notification.warning({ content: new_upgradetips.message })
          } else {
            setTimeout(() => {
              this.setState({
                visibleUpgrade: true,
              })
            }, 100)
          }
        }
        callback(res)
      } else {
        callback(res)
      }
    })
  }
  initSlider() {
    var screenWidth = $('.Continuation-item').width() //浏览器页面的宽度
    var height = screenWidth * 1.41 //14.2
    this.setState({
      height: height,
    })
    // $(".Continuation-img").height(height + 'px');//设置每一个div的宽度
  }
  goResources() {
    history.push('/myapp/resources')
    localStorage.setItem('record', JSON.stringify(this.state.record))
    window.location.reload()
  }
  goInterfacetest() {
    history.push('/myapp/interfacetest')
    localStorage.setItem('record', JSON.stringify(this.state.record))
    window.location.reload()
  }
  ChangeAbatch(type) {
    sourceCancelClass()
    clearTimeout(this.state.setRequestTime)
    clearTimeout(this.state.setChangeAbatchTime)
    if (type === 'start') {
      this.setState({
        ParagraphContinuation: [],
        loading: false,
        ContinuationLoding: true,
        requestMsg: '',
        ContinuationLoding: false,
      })
    } else {
      this.setState({
        loading: true,
        requestMsg: '',
        ContinuationLoding: false,
      })
    }
    this.state.setChangeAbatchTime = setTimeout(() => {
      // this.isPhoneLegal()
      this.referData()
    }, 1000)
  }
  setdownload(visible) {
    this.setState({
      visible: visible,
    })
  }
  opendownload(item) {
    this.setdownload(true)
    this.setState({
      visibleimg: item,
    })
  }
  getUrlBase64() {
    this.setState({
      downloadLoading: true,
    })
    const url = this.state.ParagraphContinuation.document // 获取图片地址
    // var a = document.createElement('a');          // 创建一个a节点插入的document
    // var event = new MouseEvent('click')           // 模拟鼠标click点击事件
    // a.download = '样机图'                  // 设置a节点的download属性值
    // a.target = "_blank"
    // a.href = url;                                 // 将图片的src赋值给a节点的href
    // a.dispatchEvent(event)
    fetch(url)
      .then(async (res) => await res.blob())
      .then((blob) => {
        const a = document.createElement('a')
        a.style.display = 'none'
        a.href = URL.createObjectURL(blob)
        a.download = '智能合同_' + url.substr(url.lastIndexOf('/') + 1)
        document.body.appendChild(a)
        a.click()
        document.body.removeChild(a)
        this.setState({
          downloadLoading: false,
        })
      })
  }
  getInterfaceParameter = () =>{
    const { api_id,newApi_id } = this.state;

    let params = {
        id: newApi_id ? newApi_id[0].api_id : api_id,
        // id: '95YT1z',
    };
    getAllapiList(params).then((res) => {
      if (res.code === 200) {
        console.log(res.data);
        this.setState({
          postValue:res.data.api_url
        })
      }
    })
  }
  isputapp = (name, desc) => {
    this.putapp(name, desc)
  }
  putapp(name, desc) {
    let params = {
      id: this.state.record.show_id,
      desc: desc,
      name:name,
    }

    newEditappList(params).then((res) => {
      if (res.code === 200) {
      } else {
        this.getApiInfo(true)
        Notification.error({ content: res.msg })
      }
    })
  }
  onChangetitle(type, value) {
    //console.log(value, "value");
    var apiInfo = this.state.apiInfo
    if (type === 'desc') {
      if (value.length > 100) {
        apiInfo.desc = value.substring(0, 100)
        Notification.warning({ content: '修改内容超过100个字！' })
        return
      }
      apiInfo.desc = value
      this.setState({
        apiInfo: apiInfo,
      })
      this.putapp(apiInfo.name, value)
    } else if (type === 'name') {
      if (value.length > 10) {
        apiInfo.name = value.substring(0, 20)
        Notification.warning({ content: '修改标题超过20个字！' })
        return
      }
      apiInfo.name = value
      this.setState({
        apiInfo: apiInfo,
      })
      this.putapp(value, apiInfo.desc)
    }
  }
  goaddApp() {
    history.replace('/myapp/addmyapp')
    let state = {
      model_capability: this.state.apiInfo.model_capability,
      checkedtype: this.state.apiInfo.model_id,
      model_id: this.state.apiInfo.model_id,
      name: this.state.apiInfo.model_name,
    }
    localStorage.setItem('props', JSON.stringify(state))
    localStorage.removeItem('record')
    window.location.reload()
  }
  addRow() {
    localStorage.removeItem('props')
    localStorage.setItem('record', JSON.stringify(this.state.apiInfo))
    history.replace('/enginefactory/model/management/add')
    window.location.reload()
  }
  setvisibleUpgrade(value) {
    this.setState({
      visibleUpgrade: value,
    })
  }
  render() {
    const {
      visibleUpgrade,
      upgradetips,
      apiInfo,
      versions,
      optionVlue,
      input_describe,
      keyword,
      downloadLoading,
      loading,
      visibleimg,
      ParagraphContinuation,
      ContinuationLoding,
      requestMsg,
      requestMsgText,
      apiValue,
      interfaceOption,
    } = this.state
    return (
      <div className="resumeDocument">
        <div id="renderHeaders">
          <div className="GroupTable-fixe GroupTable-fixe-end">
            <div className="edit-title">
              <div className="Header-init">
                <div className="Header-title">
                  <Typography.Paragraph
                    className="Header-uptitle"
                    editable={
                      apiInfo.type === 0
                        ? null
                        : {
                            onChange: this.onChangetitle.bind(this, 'name'),
                          }
                    }
                  >
                    {apiInfo.name}
                  </Typography.Paragraph>
                </div>
              </div>
              <div className="Header-upParagraph">
                <Typography.Paragraph
                  className="Header-uptitle"
                  editable={
                    apiInfo.type === 0
                      ? null
                      : {
                          onChange: this.onChangetitle.bind(this, 'desc'),
                        }
                  }
                >
                  {apiInfo.desc}
                </Typography.Paragraph>
              </div>
            </div>
            <Space size={'medium'} align="center">
              {apiInfo.type === 0 ? (
                <Button
                  className="primarybtn custom-White-button"
                  onClick={this.goaddApp.bind(this)}
                  style={{ marginLeft: 0 }}
                >
                  保存应用
                </Button>
              ) : (
                <>
                  {versions.length === 0 ? (
                    <div></div>
                  ) : (
                    <Select
                      value={optionVlue}
                      placeholder="版本选择"
                      style={{ width: 220 }}
                      className="versionsSelect"
                      onChange={this.onsetPosition.bind(this)}
                      dropdownRender={(menu) => (
                        <div>
                          {menu}
                          <Divider
                            style={{
                              margin: 0,
                              marginTop: 6,
                              borderColor: '#f2f2f2',
                            }}
                          />
                          <div
                            style={{
                              display: 'flex',
                              alignItems: 'center',
                              padding: '5px 0px',
                            }}
                          >
                            <Button
                              style={{
                                width: '100%',
                                height: 41,
                                fontSize: 14,
                                padding: '0 6px',
                                borderRadius: 10,
                              }}
                              type="text"
                              onClick={this.addRow.bind(this)}
                            >
                              <IconPlus />
                              新增模型版本
                            </Button>
                          </div>
                        </div>
                      )}
                    >
                      {versions.map((option, index) => (
                        <Option key={index} value={option.id}>
                          {apiInfo.model_name}V{option.version}
                        </Option>
                      ))}
                    </Select>
                  )}
                  {apiInfo.type === 9 ? (
                    <div className="primary_model_name">
                      <div className="primary_model_name_fix">
                        <span className="primary_model_name_title">
                          模型
                          <Divider type="vertical" />
                        </span>
                        <Typography.Paragraph ellipsis>
                          {apiInfo.model_name}
                        </Typography.Paragraph>
                      </div>
                    </div>
                  ) : (
                    ''
                  )}
                  {/* <Button
                    shape="circle"
                    className="custom-White-button"
                    onClick={this.goResources.bind(this)}
                  >
                    应用资源
                  </Button> */}
                  {/* <Button
                    shape="circle"
                    className="custom-White-button share_button_wnk"
                    onClick={CreateLink.bind(this, apiInfo)}
                  >
                    分享应用
                  </Button> */}
                  <Button
                    style={{
                      display:
                        this.state.identity &&
                        this.state.identity !== 'PERSONAL'
                          ? 'inline-block'
                          : 'none',
                    }}
                    shape="circle"
                    className="custom-White-button"
                    onClick={this.goInterfacetest.bind(this)}
                  >
                    接口调用
                  </Button>
                </>
              )}
            </Space>
          </div>
          <div className="Search-input">
            <Input
              placeholder={input_describe}
              className="Search"
              value={keyword}
              onChange={this.onTextArea.bind(this)}
              onPressEnter={this.onPressEnter.bind(this)}
            />
            <Button
              type="primary"
              className="Header-init-btn"
              loading={false}
              onClick={this.onPressEnter.bind(this)}
            >
              生成合同
            </Button>
          </div>
        </div>
        <Layout className="semanticprototype-Layout" id="layout_id">
          <Content>
            <div className="experience-result" id="result">
              <div id="result_title" className="rowSpaceBetween result_title">
                <Typography>生成结果</Typography>
                <Typography.Paragraph className="result-remarks">
                  (内容基于韦尼克AI创作引擎生成，仅供参考)
                </Typography.Paragraph>
              </div>
              {ParagraphContinuation.image || ContinuationLoding ? (
                <div className="Paragraph-generate">
                  {ContinuationLoding ? (
                    <Empty
                      className="Continuation-loding"
                      icon={<img src={Generating_img} />}
                      description={
                        <Typography
                          style={{
                            color: '#4e5969',
                            marginTop: 21,
                            fontSize: 16,
                          }}
                        >
                          正在生成中...
                        </Typography>
                      }
                    />
                  ) : (
                    <div className="Continuation-result" id="continuation">
                      <div className="Continuation-list">
                        {ParagraphContinuation.image.map((item, index) => {
                          return (
                            <div
                              className="Continuation-item"
                              key={index}
                              onClick={this.opendownload.bind(this, item)}
                            >
                              <div
                                className={`Continuation-img ${
                                  ParagraphContinuation.file_type === 'docx'
                                    ? `file-docx`
                                    : ParagraphContinuation.file_type === 'docx'
                                    ? 'file-pdf'
                                    : ``
                                }`}
                                style={{ height: this.state.height }}
                              >
                                <Image
                                  key={index}
                                  src={item}
                                  alt={`lamp${index + 1}`}
                                  loaderClassName="loaderName"
                                />
                              </div>
                            </div>
                          )
                        })}
                      </div>
                      {loading ? (
                        <Empty
                          className="more-loding"
                          icon={<img src={Generating_img} />}
                          description={
                            <Typography
                              style={{
                                color: '#4e5969',
                                marginTop: 21,
                                fontSize: 16,
                              }}
                            >
                              正在生成中...
                            </Typography>
                          }
                        />
                      ) : (
                        <Button
                          id="iconSync"
                          className="IconSync-btn custom-White-border-button"
                          onClick={this.ChangeAbatch.bind(this)}
                        >
                          生成更多
                        </Button>
                      )}
                    </div>
                  )}
                </div>
              ) : requestMsg === 'error' ? (
                <Empty
                  className="Continuation-Empty Empty-error"
                  imgSrc={errorIcon}
                  description={
                    <Space direction="vertical">
                      <Typography
                        style={{
                          color: '#4e5969',
                          marginTop: 30,
                          fontSize: 16,
                        }}
                      >
                        抱歉，服务器生成错误
                      </Typography>
                      <Typography style={{ color: '#4e5969', fontSize: 16 }}>
                        可尝试
                        <span
                          style={{ color: '#406EFF', cursor: 'pointer' }}
                          onClick={this.ChangeAbatch.bind(this, 'start')}
                        >
                          {' '}
                          重新生成
                        </span>
                      </Typography>
                    </Space>
                  }
                />
              ) : (
                <Empty
                  className="Continuation-Empty Empty-none"
                  imgSrc={Emptydata_img}
                  description={
                    <Space direction="vertical">
                      <Typography
                        style={{
                          color: '#4e5969',
                          marginTop: 30,
                          fontSize: 16,
                        }}
                      >
                        暂无生成结果
                      </Typography>
                      <Typography style={{ color: '#4e5969', fontSize: 16 }}>
                        {requestMsgText}
                      </Typography>
                    </Space>
                  }
                />
              )}
            </div>
          </Content>
        </Layout>
        <Modal
          style={{
            width: 479,
          }}
          onCancel={() => this.setdownload(false)}
          className="custom-modal-download-document"
          title={null}
          visible={this.state.visible}
          autoFocus={false}
          footer={
            <>
              <Button
                type="primary"
                className="download-btn"
                loading={downloadLoading}
                onClick={this.getUrlBase64.bind(this)}
              >
                {!downloadLoading && <IconDownload />}立即下载
              </Button>
            </>
          }
        >
          <div className="custom-modal-download-content">
            {visibleimg ? <img src={visibleimg} /> : ''}
          </div>
        </Modal>
        <UpgradeTips
          visible={visibleUpgrade}
          notification_status={upgradetips?.notice_type}
          notification_message={upgradetips?.message}
          model_capability={upgradetips?.model_capability}
          onCancel={() => this.setvisibleUpgrade(false)}
        ></UpgradeTips>
      </div>
    )
  }
}
export default SmartContract
