import React, { Component } from 'react'
import { Outlet } from 'react-router-dom'
import { getUrlToken } from 'common/utils'
import {
  Layout,
  Message,
  Button,
  Tag,
  Space,
  Grid,
  Typography,
  Link,
  Modal,
  Checkbox,
  BackTop,
  Affix,
  Notification,
  Trigger,
  Spin,
} from '@arco-design/web-react'
import {
  IconPlus,
  IconHome,
  IconArrowRight,
  IconExclamationCircleFill,
  IconCopy,
  IconMessage,
  IconCheck,
  IconDriveFile,
} from '@arco-design/web-react/icon'
import Headers from 'components/header'
import BaseMenu from 'components/BaseMenu'
import ModalSelect from 'components/ModalSelect'
import ALLURL from 'envconfig/config' //自定义多环境api请求路径
import errorImg from 'asserts/Figure_crack.svg'
import './index.scss'
import history from 'common/utils/history'
import userlogo from 'asserts/LOGO.png'
import { getuserInfo, outAuth, getopen, putopen } from 'api/apis'
import { Imageinit, CheckImgExists, getQuery} from 'common/utils/index'
import JQ from 'jquery'
import copy from 'copy-to-clipboard'
import model_img from "asserts/model_img.png";
import ai_ppt from "asserts/ai_ppt.png";
import ai_resume from "asserts/ai_resume.png";
import ai_more from "asserts/ai_more.png";
import ai_marketing from "asserts/ai_marketing.png";
import ai_pact from "asserts/ai_pact.png";
const Sider = Layout.Sider
const Content = Layout.Content

function imgError(e) {
  let evn = e
  let img = evn.srcElement ? evn.srcElement : evn.target
  img.src = userlogo || errorImg
  img.onerror = null //控制不要一直跳动
  if (img) {
    JQ(img).css({
      width: '100%',
      height: '100%',
    })
  }
}
// 封装组件
class Index extends Component {
  constructor(props) {
    super(props)
    this.state = {
      // width: 0,
      // height: 1000,
      count: 2,
      dot: true,
      username: JSON.parse(localStorage.getItem('userinfo'))?.nickname,
      avatar: JSON.parse(localStorage.getItem('userinfo'))?.avatar,
      loading: false,
      visible: false,
      scale: this.getScale(),
      width: 1920,
      height: 1080,
      scaleshow: false,
      Modalvisible: false,
      checkedvaue: false,
      ModalContactService: false,
      onchecked: false,
      Selectoptions: JSON.parse(localStorage.getItem('Selectoptions'))
        ? JSON.parse(localStorage.getItem('Selectoptions'))
        : [],
      current: '',
      openKeys: [],
      identity: JSON.parse(localStorage.getItem('userinfo'))?.group_permission,
      has_enterprise: JSON.parse(localStorage.getItem('userinfo'))
        ?.has_enterprise,
      enterprise_logo: JSON.parse(localStorage.getItem('userinfo'))
        ?.enterprise_logo,
      notification_message: '',
      notification_status: '',
      notificationVisible: false,
      createVisible:false,
      model_capability: '',
      userinfo: JSON.parse(localStorage.getItem('userinfo')),
      visitModal: false,
      checkModel: [
        { lable: '团队成员', value: 0, unit: '人' },
        { lable: '服务应用', value: 0, unit: '个' },
        { lable: '训练模型', value: 0, unit: '个' },
      ],
      selectApply:-1,
      createTypeList:[
        {
          type:"ai_ppt",
          createType:1,
          label:"智能PPT(API版)",
          title:'介绍介绍',
          desc:"轻松制作专业级PPT，智能布局与设计，助您一键生成高质量演示文稿。",
          model_icon:ai_ppt,
          backgroundColor:'#FFEADE',
          isDiable:JSON.parse(localStorage.getItem('userinfo'))?.group_permission === "PERSONAL" ? true : false,
        },
        {
          type:"saas_ppt",
          createType:1,
          label:"智能PPT(SaaS版)",
          title:'介绍介绍',
          desc:"轻松制作专业级PPT，智能布局与设计，助您一键生成高质量演示文稿。",
          model_icon:ai_ppt,
          backgroundColor:'#FFEADE',
          isDiable:false,
        },
        {
          type:"ai_resume",
          createType:1,
          label:"智能简历",
          title:'介绍介绍',
          desc:"智能匹配职位需求，一键生成个性化简历，让您在求职路上脱颖而出。",
          model_icon:ai_resume,
          backgroundColor:'#D3EEFF',
          isDiable:false,
        },
        {
          type:"ai_marketing",
          createType:1,
          label:"智能营销",
          title:'介绍介绍',
          desc:"涵盖多种文档类型，智能编辑与排版，让文档处理更高效、更便捷。",
          model_icon:ai_marketing,
          backgroundColor:'#FAEDFF',
          isDiable:false,
        },
        {
          type:"ai_pact",
          createType:1,
          label:"智能合同",
          title:'介绍介绍',
          desc:"涵盖多种文档类型，智能编辑与排版，让文档处理更高效、更便捷。",
          model_icon:ai_pact,
          backgroundColor:'#ECE8FF',
          isDiable:false,
        }
      ],
      visibleUpgrade: false,
      locationKey: '',
    }
    this.myRef = React.createRef()
    this.mymain = React.createRef()
    this.container = React.createRef()
  }

  componentDidMount() {
    this.state.loading = true;
    this.initLinkJump()
    this.checkJump();
    let moduleIdType = {
      writing: ['ZeRSj2Sekq', 'u4s698NFVb'],
    }
    localStorage.setItem('moduleIdType', JSON.stringify(moduleIdType))
    // this.handleResize();
    // window.addEventListener('resize', this.handleResize);
    window.addEventListener('resize', this.setScale)
    window.addEventListener('pageshow', function (e) {
      //如果检测到页面是从“往返缓存”中读取的，刷新页面
      if (e.persisted) {
        window.location.reload()
      }
    })
    this.getopen()
  
    setTimeout(() => {
      if (localStorage.getItem('userinfo')) {
        this.setinfo()
        this.Imageinit()
      } else {
        this.setinfo()
      }
        //针对个人用户。不开放智能ppt的api
    // if(this.state.userinfo && this.state.userinfo.group_permission === "PERSONAL"){
    //   this.state.createTypeList.splice(0,1)
    //   console.log(this.state.createTypeList);
    // }
    }, 500)
    // if (window.history && window.history.pushState) {
    //   history.pushState(null, null, document.URL)
    window.addEventListener('popstate', this.hiandleBack, false)
    // }
  }

  componentWillUnmount() {
    window.removeEventListener('popstate', this.hiandleBack, false)
  }

  // 判断是否官网跳转 打开创建应用弹窗
  checkJump(){
    // 获取当前页面的完整 URL
    const url = window.location.href;
        // 查询 model_capability 参数
    const modelCapability = getQuery(url, 'model_capability');
    if(modelCapability){
      //获取应用的索引，选中应用
      const Index = this.state.createTypeList.findIndex(item => item.type === modelCapability);
      this.setState({
        createVisible: true,
        selectApply:Index
      });
    }
  }
  initLinkJump() {
    if (localStorage.getItem('locationKey')) {
      this.state.locationKey = localStorage.getItem('locationKey')
    }
    let { locationKey } = this.state
    if (locationKey === 'openSelect') {
      // 从官网平台进入打开创建应用窗口
      if (!localStorage.getItem('locationKey')) {
        localStorage.setItem('locationKey', locationKey)
      }
      if (!localStorage.getItem('openModleBox')) {
        localStorage.setItem('openModleBox', true)
      }
    } else if (locationKey === 'createModle') {
      // 从官网平台进入打开具体创建的模型
      if (localStorage.getItem('modleClassName')) {
        let obj = {
          model_capability: localStorage.getItem('modleClassName'),
        }
        if (localStorage.getItem('props')) {
          localStorage.removeItem('props')
        }
        localStorage.setItem('props', JSON.stringify(obj))
      }
    }
  }

  handleSetPage() {
    switch (this.state.locationKey) {
      case 'openSelect':
        if (
          localStorage.getItem('openModleBox') &&
          localStorage.getItem('openModleBox') === 'true'
        ) {
          this.setState({
            visible: true,
          })
        }
        break
      case 'createModle':
        history.push('/myapp/addmyapp')
        window.location.reload()
        localStorage.removeItem('locationKey')
        localStorage.removeItem('modleClassName')
        break
      case 'app':
        this.handleJumpMapp()
        break

      default:
        break
    }
  }
  handleJumpMapp() {
    let record = {
      id: localStorage.getItem('appidOfficial'),
    }
    localStorage.setItem('record', JSON.stringify(record))
    let model_capability = localStorage.getItem('modleClassName')
    if (model_capability === 'Interaction') {
      history.push('/myapp/question')
    } else if (model_capability === 'Continuation') {
      history.push('/myapp/productExperience')
    } else if (model_capability === 'SemanticPrototype') {
      history.push('/myapp/semanticprototype')
    } else if (model_capability === 'Ner') {
      history.push('/myapp/entityExtraction')
    } else if (model_capability === 'SmartResume') {
      history.push('/myapp/resumeDocument')
    } else if (model_capability === 'textPolish') {
      history.push('/myapp/embroidery')
    } else if (
      model_capability === 'writing' ||
      model_capability === 'textsummarization'
    ) {
      history.push('/myapp/writing')
    } else if (model_capability === 'SemanticWordCloud') {
      history.push('/myapp/wordCloud')
    } else if (model_capability === 'TextWithPictures') {
      history.push('/myapp/image2text')
    } else if (model_capability === 'KnowledgeJd') {
      history.push('/myapp/knowledge')
    } else if (model_capability === 'Classification') {
      history.push('/myapp/wordClass')
    } else if (model_capability === 'Correction') {
      history.push('/myapp/wordErrorReset')
    } else if (model_capability === 'SmartContract') {
      history.push('/myapp/smartContract')
    } else if (model_capability === 'SentimentAnalysis') {
      history.push('/myapp/emotionClass')
    } else if (model_capability === 'SensitiveWords') {
      history.push('/myapp/sensitiveWords')
    } else if (model_capability === 'Similarity') {
      history.push('/myapp/similarity')
    } else if (model_capability === 'KnowledgeReasoning') {
      history.push('/myapp/knowledgereasoning')
    }
    window.location.reload()
    localStorage.removeItem('locationKey')
    localStorage.removeItem('modleClassName')
    localStorage.removeItem('appidOfficial')
  }
  setinfo() {
    getuserInfo().then((res) => {
      if (res.code === 200) {
        let userinfodata = res.data
        localStorage.setItem('userinfo', JSON.stringify(userinfodata))
        this.state.userinfo = userinfodata
        let checkModel = this.state.checkModel
        checkModel[0].value = userinfodata.max_staff
        checkModel[1].value = userinfodata.max_app
        checkModel[2].value = userinfodata.max_model
        this.setState({
          userinfo: userinfodata,
          username: JSON.parse(localStorage.getItem('userinfo')).nickname,
          avatar: JSON.parse(localStorage.getItem('userinfo')).avatar,
          identity: JSON.parse(localStorage.getItem('userinfo'))
            .group_permission,
          has_enterprise: JSON.parse(localStorage.getItem('userinfo'))
            .has_enterprise,
          enterprise_logo: JSON.parse(localStorage.getItem('userinfo'))
            .enterprise_logo,
          vip_level: JSON.parse(localStorage.getItem('userinfo')).vip_level,
          checkModel: checkModel,
          loading:false,
        })
        this.handleSetPage()
      }
    })
  }
  getopen() {
    getopen().then((res) => {
      if (res.code === 200) {
        let list = res.data
        if (res.code === 200) {
          this.setState({
            Modalvisible: !list.open,
            notification_status: list.notice_type,
            notification_message: list.message,
            model_capability: list.model_capability,
            visibleUpgrade: list.group_open,
          })
          if (list.notice_type) {
            this.setNotificationVisible(true, list.notice_type)
          }
        }
      }
    })
  }
  /*
   * val--是否显示弹窗提示
   * false：关闭
   * true:显示
   * type--显示类型
   * VIP_EXPIR：会员到期
   * PACKAGE_STATEMENT：资源包消耗完，账户余额充足
   * BALANCE_PACKAGE：资源包消耗完，账户余额不充足
   * BALANCE_LACK：账户余额不充足
   * button_type--按钮类型
   * 1：关闭（左）
   * 2：确认（右）
   */
  setNotificationVisible(val, type, button_type) {
    this.setState({
      notificationVisible: val,
    })
    if (type === 'BALANCE_LACK' && button_type === 2) {
      history.push('/payment/accountrecharge')
      history.go()
    } else if (type === 'VIP_EXPIR' && button_type === 2) {
      history.push('/privilege')
      history.go()
    } else if (
      (type === 'BALANCE_PACKAGE' || type === 'PACKAGE_STATEMENT') &&
      button_type === 2
    ) {
      let record = {
        model_capability: this.state.model_capability,
      }
      localStorage.setItem('record', JSON.stringify(record))
      history.push('/promotion')
      history.go()
    } else if (type === 'BALANCE_PACKAGE' && button_type === 1) {
      history.push('/payment')
      history.go()
    }
  }
  //得到呈现的屏幕宽高比
  getScale = () => {
    const { width = 1920, height = 1080 } = this.props
    let ww = window.innerWidth / width
    let wh = window.innerHeight / height
    return ww < wh ? ww : wh
  }
  setScale = () => {
    let thia = this
    setTimeout(() => {
      let scale = this.getScale()
      thia.setState({ scale: scale })
    }, 300)
  }
  //取父div的height
  handleResize = () => {
    //console.log(this.myRef, this.mymain, "divHeight");
    const divHeight =
      this.mymain.current.clientHeight - this.myRef.current.clientHeight
    divHeight != null && this.setState({ height: divHeight })
  }
  gotoDot() {
    this.setState({
      //点击修改span里的值
      count: this.state.count > 0 ? this.state.count - 1 : 0,
    })
    //console.log("用户消息", this.state.count);
  }
  AccessManager() {
    const userinfo = JSON.parse(localStorage.getItem('userinfo'))
    this.setState({
      userinfo: userinfo,
    })
    this.state.userinfo = userinfo
    this.onVisitModal(true)
  }
  switchAccounts() {
    // var old_url = window.location.href;

    localStorage.clear()
    const loginUrl =
      ALLURL.loginURL + '/login.html?ref=' + window.location.origin
    window.location = loginUrl
  }

  setoutAuth() {
    // localStorage.clear();
    // window.location.reload();
    outAuth().then((res) => {
      if (res.code === 200) {
        localStorage.clear()
        window.location.reload()
      } else {
        Message.error(res.msg)
      }
    })
  }
  onClickBtn() {
    // this.setState({
    //   visible: true,
    //   Selectoptions: JSON.parse(localStorage.getItem('Selectoptions'))
    //     ? JSON.parse(localStorage.getItem('Selectoptions'))
    //     : [],
    // })
    this.setState({
      createVisible: true,
    });
  }
  Changechecked(value) {
    this.setState({
      checkedvaue: value,
    })
  }
  inOpen() {
    if (this.state.checkedvaue) {
      putopen().then((res) => {
        if (res.code === 200) {
          this.setState({
            Modalvisible: false,
            onchecked: false,
          })
        } else {
          Message.error(res.msg)
        }
      })
    } else {
      this.setState({
        onchecked: true,
      })
    }
  }
  initkey(key) {
    let nowkey = this.sliceURL(key)
    this.state.openKeys = nowkey
    this.state.current = key
    this.setState({
      openKeys: nowkey,
    })

    //console.log(this.state.openKeys, this.state.current, "initkey");
  }
  sliceURL(key) {
    let listmap = key.split('/').filter((i) => i)
    let nowkey = listmap.map((_, index) => {
      let url = `/${listmap.slice(0, index + 1).join('/')}`
      return url
    })
    return nowkey
  }
  cut(value) {
    let newArr = value.slice(0)
    newArr.pop()
    return newArr
  }
  onBack() {
    //console.log(current);
    //console.log("历史路由回退记录");
    let replaceList = [
      '/myapp/addmyapp',
      '/enginefactory/model/add',
      '/enginefactory/model/management/add',
      '/enginefactory/dataset/add',
    ]
    const urlParams = new URL(window.location.href)
    const pathname = urlParams?.pathname
    // 回退移除一条记录
    let HistoryList = JSON.parse(localStorage.getItem('HistoryList'))
    if (HistoryList) {
      if (
        HistoryList[HistoryList.length - 2].url ===
        HistoryList[HistoryList.length - 1].url
      ) {
        let newHistoryList = []
        if (replaceList.includes(pathname)) {
          history.replace(HistoryList[HistoryList.length - 2].url)
          // 读取存贮信息
          if (HistoryList[HistoryList.length - 2].record) {
            localStorage.setItem(
              'record',
              HistoryList[HistoryList.length - 2].record
            )
          }
          if (HistoryList[HistoryList.length - 2].props) {
            localStorage.setItem(
              'props',
              HistoryList[HistoryList.length - 2].props
            )
          }
          newHistoryList = HistoryList.splice(0, HistoryList.length - 1)
        } else {
          history.replace(HistoryList[HistoryList.length - 3].url)
          // 读取存贮信息
          if (HistoryList[HistoryList.length - 3].record) {
            localStorage.setItem(
              'record',
              HistoryList[HistoryList.length - 3].record
            )
          }
          if (HistoryList[HistoryList.length - 3].props) {
            localStorage.setItem(
              'props',
              HistoryList[HistoryList.length - 3].props
            )
          }
          newHistoryList = HistoryList.splice(0, HistoryList.length - 2)
        }
        window.location.reload()
        localStorage.setItem('HistoryList', JSON.stringify(newHistoryList))
      } else {
        history.replace(HistoryList[HistoryList.length - 2].url)
        window.location.reload()
        this.cut(HistoryList)
        if (HistoryList[HistoryList.length - 2].record) {
          localStorage.setItem(
            'record',
            HistoryList[HistoryList.length - 2].record
          )
        }
        if (HistoryList[HistoryList.length - 2].props) {
          localStorage.setItem(
            'props',
            HistoryList[HistoryList.length - 2].props
          )
        }
        localStorage.setItem(
          'HistoryList',
          JSON.stringify(this.cut(HistoryList))
        )
      }
    } else {
      history.replace('/')
      window.location.reload()
    }
  }
  hiandleBack(re) {
    /// 当点击浏览器的 后退和前进按钮 时才会被触发，
    window.history.pushState('forward', null, '')
    window.history.forward(1)
    history.go()
  }
  onVisitModal(val) {
    this.setState({
      visitModal: val,
    })
  }
  onCancelContact(value) {
    this.setState({
      ModalContactService: value,
    })
  }
  onCancel() {
    if (localStorage.getItem('openModleBox')) {
      localStorage.removeItem('locationKey')
      localStorage.removeItem('openModleBox')
    }
    this.setState({
      visible: false,
    })
  }
  setVisible(event) {
    return
    if (event.model_capability) {
      history.replace('/myapp/addmyapp')
      localStorage.setItem('props', JSON.stringify(event))
      localStorage.removeItem('record')
      window.location.reload()
      this.onCancel()
    } else {
      Notification.warning({ content: '请选择服务类型！' })
    }
  }
  goOverview() {
    history.push('/')
    window.location.reload()
  }
  Imageinit() {
    let logo = this.state.enterprise_logo
      ? this.state.enterprise_logo
      : this.state.identity === 'PERSONAL'
      ? userlogo
      : ''
      if (logo) {
        // 创建一个新的 Image 对象来检测图像是否有效
        const img = new Image();
        img.src = logo;
    
        img.onload = () => {
          // 图像加载成功，调用 Imageinit 方法
          Imageinit(logo, JQ('.enterprise_logo'), JQ('.userlogo'), 1);
        };
    
        img.onerror = () => {
          // 图像加载失败，使用默认图像或处理错误
          if (this.state.identity === 'PERSONAL') {
            // 如果是个人身份，使用默认用户图像
            logo = userlogo;
          } else {
            // 其他情况下，使用空字符串或其他默认图像
            logo = '';
          }
          Imageinit(logo, JQ('.enterprise_logo'), JQ('.userlogo'), 1);
        };
      }
  }
  oncopy(value) {
    if (copy(value)) {
      Notification.success({ content: '复制成功!' })
    } else {
      Notification.error({ content: '复制失败!' })
    }
  }
  CreateApp(){
    let { selectApply, createTypeList } = this.state
    if(selectApply != -1){
      let params = {};
      createTypeList.forEach((item) => {
        params[item.type] = {...item}
      })
      //跳转企业认证
      if(createTypeList[selectApply].isDiable){
        history.push("/authentication");
        window.location.reload();
        return
      }
      params.model_capability = createTypeList[selectApply].type
      // console.log(params,'params');
      history.replace("/myapp/addmyapp");
      localStorage.setItem("props", JSON.stringify(params));
      localStorage.setItem("Selectoptions", JSON.stringify(params));
      localStorage.removeItem("record");
      window.location.reload();
      this.setState({
        createVisible:false,
      })
    } else {
      Notification.warning({ content: "请选择创建的产品类型！" });
    }
  }
  setVisibles(value) {
    this.setState({
      createVisible: value,
    });
  }
    //选择创建应用的点击方法
    selestCreate(item,index){
      console.log(item,index);
      const { has_enterprise } = this.state;
      if(has_enterprise && item.isDiable){
        Notification.warning({ content: "当前应用只为企业用户提供，请切换到企业账户体验更多功能！" });
      } else if(item.isDiable){
        Notification.warning({ content: "当前应用只为企业用户提供，点击确认前往认证！" });
      }
      this.setState({
        selectApply:index
      })
    }
  setvisibleUpgrade() {
    history.push('/privilege')
    history.go()
  }
  render() {
    const { width = 1920, height = 1080 } = this.props
    const {
      Selectoptions,
      onchecked,
      visible,
      Modalvisible,
      checkedvaue,
      ModalContactService,
      notification_message,
      notification_status,
      notificationVisible,
      userinfo,
      visitModal,
      identity,
      checkModel,
      visibleUpgrade,
      createVisible,
      confirmLoading,
      createTypeList,
      selectApply,
      loading
    } = this.state
    return (
        <div
          className={`main ${
            identity === 'PERSONAL' || !identity ? '' : 'identityVip'
          } ${`vip_level` + userinfo?.vip_level}`}
        >
          <div className="layout-basic-demo">
            <Layout style={{ height: '100%' }}>
              <Sider
                width={'auto'}
                style={{ background: 'transparent', boxShadow: 'none' }}
              >
                <div style={{ background: '#ffffff' }}>
                  <div>
                    <Space
                      direction="vertical"
                      style={{
                        marginTop: 37,
                        textAlign: 'right',
                        cursor: 'pointer',
                      }}
                      onClick={this.goOverview.bind(this)}
                    >
                      <div className="userlogo">
                        {this.state.enterprise_logo || this.state.identity ? (
                          <img
                            className="enterprise_logo"
                            src={
                              this.state.enterprise_logo
                                ? this.state.enterprise_logo
                                : this.state.identity === 'PERSONAL'
                                ? userlogo
                                : ''
                            }
                            onError={imgError}
                          />
                        ) : (
                          <img
                            className="enterprise_logo"
                            src={ userlogo }
                            onError={imgError}
                          />
                        )}
                      </div>
                      {this.state.identity ? (
                        this.state.identity !== 'PERSONAL' ? (
                          <Tag
                            bordered
                            className="Tag-bordered"
                            style={{
                              border: 0,
                              width: 112,
                              margin: 'auto',
                              color: '#C9A969',
                              background:
                                'linear-gradient(172deg, #fff0c6 32%, #ebcc86 95%)',
                              borderRadius: 4,
                            }}
                          >
                            Wernicke智创平台
                          </Tag>
                        ) : (
                          <Tag
                            style={{
                              border: 0,
                              width: 112,
                              margin: 'auto',
                              color: '#ba8928',
                              background:
                                'linear-gradient(90deg,#fff0c1, #f6d493)',
                              borderRadius: 4,
                            }}
                          >
                            Wernicke智创平台
                          </Tag>
                        )
                      ) : (
                        ''
                      )}
                    </Space>
                  </div>
                  {this.state.identity ? (
                    <Button
                      type="primary"
                      id="addapp"
                      className={`${
                        this.state.identity && this.state.identity !== 'PERSONAL'
                          ? 'Upgrade-svp'
                          : ''
                      }`}
                      onClick={this.onClickBtn.bind(this)}
                      style={{
                        width: 150,
                        height: 40,
                        borderRadius: 10,
                        margin: '21px 0px',
                      }}
                    >
                      <IconPlus className="AlibabaPuHuiTi-Medium" />
                      <span className="AlibabaPuHuiTi-Medium">创建应用</span>
                    </Button>
                  ) : (
                    ''
                  )}
                </div>
                <BaseMenu />
                <div style={{ background: '#ffffff' }}>
                  <div
                    className="Support"
                    id="supportBtn"
                    onClick={() => {
                      this.onCancelContact(true)
                    }}
                  >
                    <Button
                      className={
                        this.state.identity && this.state.identity !== 'PERSONAL'
                          ? `custom-vip-services`
                          : `custom-Black-button`
                      }
                      icon={<IconMessage />}
                    >
                      专属客服
                    </Button>
                  </div>
                  <div style={{ marginBottom: 23 }}>
                    <Space
                      align={'center'}
                      style={{
                        fontSize: 14,
                        color: '#86909C',
                        cursor: 'pointer',
                      }}
                    >
                      <Link
                        className="yoo-link"
                        href={process.env.REACT_APP_ENV === 'development' ? "https://test.yoo-ai.com/" : "https://www.yoo-ai.com/"}
                        target="_blank"
                        icon={<IconHome />}
                      >
                        必优科技
                      </Link>
                      <Link
                        style={{
                          display:
                            this.state.identity &&
                            this.state.identity !== 'PERSONAL'
                              ? 'block'
                              : 'none',
                        }}
                        className="yoo-link"
                        href="https://wiki.yoo-ai.com/guide.html"
                        target="_blank"
                        icon={<IconDriveFile />}
                      >
                        API文档
                      </Link>
                    </Space>
                  </div>
                </div>
              </Sider>
              <Layout
                id="custom_backtop0"
                ref={(node) => {
                  this.container = node
                }}
              >
                <Affix
                  ref={(ref) => (this.affixRef = ref)}
                  target={() => this.container}
                  offsetTop={0}
                  targetContainer={() => window}
                  className="affix-headers"
                >
                  <Headers
                    username={this.state.username}
                    count={this.state.count}
                    dot={this.state.dot}
                    avatar={this.state.avatar}
                    identity={this.state.identity}
                    has_enterprise={this.state.has_enterprise}
                    vip_level={this.state.vip_level}
                    gotoDot={this.gotoDot.bind(this)}
                    setoutAuth={this.setoutAuth.bind(this)}
                    switchAccounts={this.switchAccounts.bind(this)}
                    AccessManager={this.AccessManager.bind(this)}
                    onBack={this.onBack.bind(this)}
                  />
                </Affix>
                <Content>
                  <Outlet />
                </Content>
              </Layout>
            </Layout>
          </div>
          <ModalSelect
            visible={visible}
            selectoptions={Selectoptions}
            onCancel={this.onCancel.bind(this)}
            setVisible={this.setVisible.bind(this)}
          ></ModalSelect>
          <Modal
            visible={Modalvisible}
            closeIcon={false}
            footer={null}
            className="heollmodal"
            maskStyle={{ background: 'rgba(33,85,163,0.16)' }}
          >
            <div className="imgsheoll"></div>
            <Checkbox
              checked={checkedvaue}
              onChange={this.Changechecked.bind(this)}
            >
              我已阅读并同意
              <Link href="/privacyAgreement" target="_blank">
                《隐私政策》
              </Link>
              和
              <Link href="/agreement" target="_blank">
                《服务条款》
              </Link>
            </Checkbox>
            {onchecked && !checkedvaue ? (
              <Space align="center" className="Exclamation-tip">
                <IconExclamationCircleFill style={{ color: '#FF7D00' }} />
                需同意协议
              </Space>
            ) : (
              ''
            )}
            <div style={{ textAlign: 'center' }} className="Exclamation-open">
              <Space align={'center'}>
                <Typography.Text
                  style={{ cursor: 'pointer', fontSize: 22 }}
                  onClick={this.inOpen.bind(this)}
                >
                  开启智能创作
                </Typography.Text>
                <IconArrowRight
                  onClick={this.inOpen.bind(this)}
                  className="IconArrowRight"
                  style={{
                    cursor: 'pointer',
                    strokeLinecap: 'round',
                    strokeLinejoin: 'round',
                    strokeWidth: 4,
                    marginLeft: 12,
                    fontSize: 26,
                    color: '#406EFF',
                  }}
                />
              </Space>
            </div>
          </Modal>
          <Modal
            visible={ModalContactService}
            title={<div style={{ textAlign: 'left' }}>专属客服</div>}
            footer={null}
            className="Contactmodal"
            maskStyle={{ background: 'rgba(33,85,163,0.16)' }}
            onCancel={() => {
              this.onCancelContact(false)
            }}
          >
            <div style={{ textAlign: 'center' }}>
              <div className="serviceQRcode"></div>
              <Typography>您好，请扫码添加专属客服</Typography>
            </div>
          </Modal>
          <BackTop
            visibleHeight={30}
            style={{ position: 'absolute' }}
            target={() => document.getElementById('custom_backtop0')}
          />
          <Modal
            visible={notificationVisible}
            title={<div style={{ textAlign: 'left', fontSize: 20 }}>提示</div>}
            cancelText={
              notification_status === 'BALANCE_PACKAGE' ? '自动月结' : '确定'
            }
            okText={
              notification_status === 'VIP_EXPIR'
                ? '续费会员'
                : notification_status === 'PACKAGE_STATEMENT' ||
                  notification_status === 'BALANCE_PACKAGE'
                ? '购买资源包'
                : notification_status === 'BALANCE_LACK'
                ? '账户充值'
                : ''
            }
            className="Contactmodal global_prompt"
            maskStyle={{ background: 'rgba(33,85,163,0.16)' }}
            onCancel={() => {
              this.setNotificationVisible(false, notification_status, 1)
            }}
            onOk={() =>
              this.setNotificationVisible(false, notification_status, 2)
            }
          >
            <div style={{ textAlign: 'center', fontSize: 18 }}>
              <Typography>{notification_message}</Typography>
              <div></div>
            </div>
          </Modal>
          <Modal
            visible={visitModal}
            title={
              <div style={{ textAlign: 'left', fontSize: 22 }}>访问管理</div>
            }
            footer={null}
            className="Contactmodal visit"
            maskStyle={{ background: 'rgba(33,85,163,0.16)' }}
            onCancel={() => {
              this.onVisitModal(false)
            }}
          >
            <div className="keyinfo">
              <div className="keyinfo-title">接口密钥信息</div>
              <div className="rowStartWrap">
                <span className="key-li">AccessSecret：</span>
                <span className="key-value">
                  {userinfo?.access_secret}
                  <Trigger
                    showArrow
                    trigger="hover"
                    position="top"
                    popupAlign={{
                      bottom: [-20, 5],
                    }}
                    popup={() => (
                      <div className="demo-trigger-popup">请注意保护好ID信息</div>
                    )}
                  >
                    <span
                      className="operation-copy"
                      onClick={this.oncopy.bind(this, userinfo?.access_secret)}
                    >
                      <IconCopy />
                    </span>
                  </Trigger>
                </span>
              </div>
              <div className="rowStartWrap" style={{ marginTop: 24 }}>
                <span className="key-li">AccessToken：</span>
                <span className="key-value">
                  {userinfo?.access_key}
                  <Trigger
                    showArrow
                    trigger="hover"
                    position="bottom"
                    popupAlign={{
                      bottom: [-20, 5],
                    }}
                    popup={() => (
                      <div className="demo-trigger-popup">请注意保护好ID信息</div>
                    )}
                  >
                    <span
                      className="operation-copy"
                      onClick={this.oncopy.bind(this, userinfo?.access_key)}
                    >
                      <IconCopy />
                    </span>
                  </Trigger>
                </span>
              </div>
            </div>
          </Modal>
          <Modal
            visible={visibleUpgrade}
            footer={null}
            className="enterprise"
            maskStyle={{ background: 'rgba(0, 0, 0, 0.5)' }}
            onCancel={() => {
              this.setState({ visibleUpgrade: false })
            }}
          >
            <div className="enterprise-hell">
              <div className="enterprise-hell-title">
                欢迎进入韦尼克企业创作平台
              </div>
              <div className="enterprise-hell-title">
                您当前的账户等级为
                <span className="AlibabaPuHuiTi-Medium">
                  VIP{userinfo?.vip_level}
                </span>
              </div>
              <div className="identity-level">
                {identity && identity !== 'PERSONAL' ? (
                  <div
                    className={`identity-level-bg ${
                      `svip` + userinfo?.vip_level + `_icon`
                    }`}
                  ></div>
                ) : (
                  <div
                    className={`identity-level-bg ${
                      `svip` + userinfo?.vip_level + `_bule_icon`
                    }`}
                  ></div>
                )}
              </div>
              <div className="rowCenter enterprise-hell-line">
                <div></div>
                <span>账户权益</span>
                <div></div>
              </div>
              <div className="enterprise-hell-check">
                {checkModel?.map((item, i) => {
                  return (
                    <div
                      className="rowSpaceBetween enterprise-hell-check-item"
                      key={i}
                    >
                      <div className="rowCenter enterprise-hell-check-title">
                        <div className="hell-check">
                          <IconCheck />
                        </div>
                        {item.lable}
                      </div>
                      <div className="enterprise-hell-check-value">
                        {item.value}
                        {item.unit}
                      </div>
                    </div>
                  )
                })}
              </div>
              <div className="enterprise-btns">
                <button
                  type="primary"
                  className="Upgrade-svp enterprise-btn"
                  onClick={() => this.setvisibleUpgrade()}
                >
                  升级企业账户，获取更多权限
                </button>
              </div>
            </div>
          </Modal>
          <Modal
            style={{
              width: 900,
            }}
            className="selectModalAdd createListModal"
            title="创作应用"
            visible={createVisible}
            okText="确认"
            cancelText="取消"
            confirmLoading={confirmLoading}
            onOk={this.CreateApp.bind(this)}
            onCancel={() => this.setVisibles(false)}
          >
            <div className="create-desiction">请选择你需要创建的应用产品类型</div>
            <div className="create-list">
                  {createTypeList.map((item, index) => (
                    <div key={index} 
                    className={`create-list-item ${selectApply == index ? "create-list-item-active" : ""} ${item.isDiable ? "create-list-item-disable" : ""}`} 
                    onClick={this.selestCreate.bind(this, item,index)}>
                      <img
                        src={item.model_icon ? item.model_icon : model_img}
                        className="model_img"
                        alt=""
                        style={{backgroundColor: item.backgroundColor}}
                      />
                      <span className="create-name">{item.label}</span>
                    </div>
                  ))}
                </div>
          </Modal>
        </div>
    )
  }
}
export default Index
