import numeral from "numeral";
export function getCurrentDate(separator = "", separatorend = ":", datatime) {
  let newdate = new Date(datatime * 1000);
  let milliseconds = newdate.getMilliseconds();
  let seconds = newdate.getSeconds();
  let minutes = newdate.getMinutes();
  let hours = newdate.getHours();
  let date = newdate.getDate();
  let month = newdate.getMonth() + 1;
  let year = newdate.getFullYear();

  return `${year}${separator}${
    month < 10 ? `0${month}` : `${month}`
  }${separator}${date < 10 ? `0${date}` : `${date}`}   ${
    hours < 10 ? `0${hours}` : `${hours}`
  }${separatorend}${
    minutes < 10 ? `0${minutes}` : `${minutes}`
  }${separatorend}${seconds < 10 ? `0${seconds}` : `${seconds}`}`;
}
export function getNewCurrentDate(
  separator = "",
  separatorend = ":",
  datatime
) {
  let newdate = new Date(datatime);
  let milliseconds = newdate.getMilliseconds();
  let seconds = newdate.getSeconds();
  let minutes = newdate.getMinutes();
  let hours = newdate.getHours();
  let date = newdate.getDate();
  let month = newdate.getMonth() + 1;
  let year = newdate.getFullYear();

  return `${year}${separator}${
    month < 10 ? `0${month}` : `${month}`
  }${separator}${date}   ${hours}${separatorend}${minutes}${separatorend}${seconds}`;
}
/**
 * ---------------------------------------------------------------------
 * 计算两个时间戳之间相差的日时分秒
 * @param startTime 开始时间戳
 * @param endTime 结束时间戳
 * @author liqiuyue
 * ---------------------------------------------------------------------
 */
export function timeConsuming(startTime, endTime) {
  // 开始时间
  let date1 = new Date(startTime);
  // 结束时间
  let date2 = new Date(endTime);

  let lefttime = date2.getTime() - date1.getTime(),
    leftd = Math.floor(lefttime / (24 * 3600 * 1000)), //计算天数
    lefth = Math.floor((lefttime / (1000 * 60 * 60)) % 24), //计算小时数
    leftm = Math.floor((lefttime / (1000 * 60)) % 60), //计算分钟数
    lefts = Math.floor((lefttime / 1000) % 60); //计算秒数
  //距离结束时间的毫秒数
  if (lefttime > 0) {
    return `${leftd > 0 ? leftd + "天" : ""}${
      lefth < 10 ? "0" + lefth : lefth
    }小时${leftm < 10 ? "0" + leftm : leftm}分${
      lefts < 10 ? "0" + lefts : lefts
    }秒`;
  } else {
    return "";
  }
}

export function getUrlToken(name) {
  var reg = new RegExp("(^|&)" + name + "=([^&]*)(&|$)"); //构造一个含有目标参数的正则表达式对象
  var r = window.location.search.substr(1).match(reg); //匹配目标参数
  if (r != null) return decodeURI(r[2]);
  return null; //返回参数值escape()   unescape() encodeURI()   decodeURI() encodeURIComponent()    decodeURIComponent()
}

// 判断数据是否存在
export function isParams(params) {
  if (typeof params === "undefined") {
    return "";
  } else {
    return params;
  }
}

export function delQueryString(url, paramKey) {
  let beforeUrl = url.substr(0, url.indexOf("?")); //?之前主地址
  let afterUrl = url.substr(url.indexOf("?") + 1); //？之后参数路径
  let nextUrl = "";

  let arr = new Array();
  if (afterUrl != "") {
    let urlParamArr = afterUrl.split("&"); //将参数按照&符分成数组
    for (let i = 0; i < urlParamArr.length; i++) {
      let paramArr = urlParamArr[i].split("="); //将参数键，值拆开
      //如果键雨要删除的不一致，则加入到参数中
      if (paramArr[0] !== paramKey) {
        arr.push(urlParamArr[i]);
      }
    }
  }
  if (arr.length > 0) {
    nextUrl = "?" + arr.join("&");
  }
  url = beforeUrl + nextUrl;
  return url;
}

// var data = 'aaa-bbb'
// //截取符号前面部分
// getCaption(data,0) //输出aaa
// //截取符号后面部分
// getCaption(data,1) //输出bbb
export function getCaption(obj, state, type = "-") {
  var index = obj.lastIndexOf(type);
  if (state === 0) {
    obj = obj.substring(0, index);
  } else {
    obj = obj.substring(index + 1, obj.length);
  }
  return obj;
}

/* eslint-disable */
/**
 * @description: 设置style-config
 * @param {obj} :notNeed ['title','content','border','margin','padding'] 不需要属性去除
 * @return {obj} style对象
 */
const alphaHexMap = {
  "1.00": "FF",
  0.99: "FC",
  0.98: "FA",
  0.97: "F7",
  0.96: "F5",
  0.95: "F2",
  0.94: "F0",
  0.93: "ED",
  0.92: "EB",
  0.91: "E8",
  "0.90": "E6",
  0.89: "E3",
  0.88: "E0",
  0.87: "DE",
  0.86: "DB",
  0.85: "D9",
  0.84: "D6",
  0.83: "D4",
  0.82: "D1",
  0.81: "CF",
  "0.80": "CC",
  0.79: "C9",
  0.78: "C7",
  0.77: "C4",
  0.76: "C2",
  0.75: "BF",
  0.74: "BD",
  0.73: "BA",
  0.72: "B8",
  0.71: "B5",
  "0.70": "B3",
  0.69: "B0",
  0.68: "AD",
  0.67: "AB",
  0.66: "A8",
  0.65: "A6",
  0.64: "A3",
  0.63: "A1",
  0.62: "9E",
  0.61: "9C",
  "0.60": "99",
  0.59: "96",
  0.58: "94",
  0.57: "91",
  0.56: "8F",
  0.55: "8C",
  0.54: "8A",
  0.53: "87",
  0.52: "85",
  0.51: "82",
  "0.50": "80",
  0.49: "7D",
  0.48: "7A",
  0.47: "78",
  0.46: "75",
  0.45: "73",
  0.44: "70",
  0.43: "6E",
  0.42: "6B",
  0.41: "69",
  "0.40": "66",
  0.39: "63",
  0.38: "61",
  0.37: "5E",
  0.36: "5C",
  0.35: "59",
  0.34: "57",
  0.33: "54",
  0.32: "52",
  0.31: "4F",
  "0.30": "4D",
  0.29: "4A",
  0.28: "47",
  0.27: "45",
  0.26: "42",
  0.25: "40",
  0.24: "3D",
  0.23: "3B",
  0.22: "38",
  0.21: "36",
  "0.20": "33",
  0.19: "30",
  0.18: "2E",
  0.17: "2B",
  0.16: "29",
  0.15: "26",
  0.14: "24",
  0.13: "21",
  0.12: "1F",
  0.11: "1C",
  "0.10": "1A",
  0.09: "17",
  0.08: "14",
  0.07: "12",
  0.06: "0F",
  0.05: "0D",
  0.04: "0A",
  0.03: "08",
  0.02: "05",
  0.01: "03",
  "0.00": "00",
};
export const getHexWithAlpha = ({ hex, rgb = { a: 1 } }) => {
  return (
    (hex.indexOf("#") !== -1
      ? hex.substr(1, hex.length - 1)
      : hex
    )?.toUpperCase() + alphaHexMap[rgb?.a?.toFixed(2)]
  );
};
export const setStyleConfig = (notNeed) => {
  const style = {
    "--title-textColor": "rgba(17, 17, 17, 1)",
    "--title-bgColor": "rgba(255, 255, 255, 1)",
    "--title-bgImg": "",
    "--title-bgSize": "cover",
    "--title-bgRepeat": "no-repeat",
    "--content-textColor": "rgba(17, 17, 17, 1)",
    "--content-bgColor": "rgba(255, 255, 255, 1)",
    "--content-bgImg": "",
    "--content-bgSize": "cover",
    "--content-bgRepeat": "no-repeat",
    "--borderBottomRightRadius": 12,
    "--borderTopRightRadius": 12,
    "--borderBottomLeftRadius": 12,
    "--borderTopLeftRadius": 12,
    "--marginTop": 18,
    "--marginBottom": 18,
    "--marginLeft": 18,
    "--marginRight": 18,
  };
  notNeed &&
    notNeed.forEach((item) => {
      Object.keys(style).forEach((key) => {
        if (key.slice(2).split("-")[0] === item) {
          delete style[key];
        }
      });
    });
  return style;
};
/**
 * @description: 处理rgba
 * @param {obj,string} :rgba||color参数,color转rgba对象  rgba对象时转color色值
 * @return {obj} 返回一个{r,g,b,a} 对象 || rgba()颜色
 */
export const transRgba = (data) => {
  let obj = null;
  if (typeof data === "string" && data.indexOf("rgba") === -1) {
    return data;
  } else if (Object.prototype.toString.call(data) === "[object String]") {
    let arr = data.match(/\(([^)]*)\)/)[1].split(",");
    obj = {
      r: Number(arr[0]),
      g: Number(arr[1]),
      b: Number(arr[2]),
      a: Number(arr[3]),
    };
  } else {
    let { r, g, b, a } = data;
    obj = `rgba(${r},${g},${b},${a})`;
  }
  return obj;
};
/**
 * @description: rgba转hex
 * @param {type}
 * @return {type}
 */
export const getHexColor = (color) => {
  var values = color
    .replace(/rgba?\(/, "")
    .replace(/\)/, "")
    .replace(/[\s+]/g, "")
    .split(",");
  var a = parseFloat(values[3] || 1),
    r = Math.floor(a * parseInt(values[0]) + (1 - a) * 255),
    g = Math.floor(a * parseInt(values[1]) + (1 - a) * 255),
    b = Math.floor(a * parseInt(values[2]) + (1 - a) * 255);
  return (
    "#" +
    ("0" + r.toString(16)).slice(-2) +
    ("0" + g.toString(16)).slice(-2) +
    ("0" + b.toString(16)).slice(-2)
  );
};
/**
 * @description: hex转rgba
 * @param {type}
 * @return {type}
 */
export const getRgba = (sHex, type, alpha = 1) => {
  // 十六进制颜色值的正则表达式
  var reg = /^#([0-9a-fA-f]{3}|[0-9a-fA-f]{4}|[0-9a-fA-f]{6}|[0-9a-fA-f]{8})$/;
  /* 16进制颜色转为RGB格式 */
  var sColor = sHex.toLowerCase();
  var alpha = alpha;
  if (sColor && reg.test(sColor)) {
    if (sColor.length === 4 || sColor.length === 5) {
      var sColorNew = "#";
      for (var i = 1; i < sColor.length; i += 1) {
        sColorNew += sColor.slice(i, i + 1).concat(sColor.slice(i, i + 1));
      }
      sColor = sColorNew;
    }
    // 如果有透明度再执行
    if (sColor.length === 9) {
      alpha = (parseInt("0x" + sColor.slice(7, 9)) / 255).toFixed(2);
    }
    //  处理六位的颜色值
    var sColorChange = [];
    for (var i = 1; i < 7; i += 2) {
      sColorChange.push(parseInt("0x" + sColor.slice(i, i + 2)));
    }
    if (type == "obj") {
      return {
        r: Number(sColorChange[0]),
        g: Number(sColorChange[1]),
        b: Number(sColorChange[2]),
        a: alpha,
      };
    } else {
      return "rgba(" + sColorChange.join(",") + "," + alpha + ")";
    }
  } else {
    return sColor;
  }
};
/**
 * @description: 删除||添加 前缀
 * @param {type}
 * @return {type}
 */
export const addReduceSymbol = (obj, symbol) => {
  var newObj;
  if (Object.prototype.toString.call(obj) === "[object Object]") {
    newObj = {};
    Object.keys(obj).forEach((key) => {
      if (key.indexOf(symbol) === 0) {
        newObj[key.slice(symbol.length)] = obj[key];
      } else {
        newObj[symbol + key] = obj[key];
      }
    });
  }
  return newObj;
};
/**
 * @description: 重置样式单位 追加px 等 自己扩展
 * @param {obj}
 * @return {obj}
 */
export const resetStyle = (obj) => {
  let newObj = obj || {};
  Object.keys(obj).forEach((key) => {
    if (typeof obj[key] === "number") {
      newObj[key] = newObj[key] + "px";
    }
  });
  return newObj;
};

export const getQuery = (url, name) => {
  var reg = new RegExp("(^|&)" + name + "=([^&]*)(&|$)");
  var r = url.substr(url.indexOf("?") + 1).match(reg);
  if (r !== null) return unescape(r[2]);
  return null;
};
export const hexToRgba = (hex, opacity) => {
  var shorthandRegex = /^#?([a-f\d])([a-f\d])([a-f\d])$/i;
  hex = hex.replace(shorthandRegex, function (m, r, g, b) {
    return r + r + g + g + b + b;
  });

  var result = /^#?([a-f\d]{2})([a-f\d]{2})([a-f\d]{2})$/i.exec(hex);
  opacity = opacity >= 0 && opacity <= 1 ? Number(opacity) : 1;
  return result
    ? {
        r: parseInt(result[1], 16),
        g: parseInt(result[2], 16),
        b: parseInt(result[3], 16),
        a: opacity,
      }
    : hex;
};
const unitsList = ["KB", "MB", "GB", "TB", "PB", "EB", "ZB"];

export const b2ValueUnit = (val: number, fmt = "0,0.00") => {
  let unit = "B";
  let value = numeral(val);
  unitsList.forEach((item) => {
    if (value.value() >= 1024) {
      value = value.divide(1024);
      unit = item;
    }
  });
  return [numeral(value).format(fmt), unit];
};
export const debounce = (fn, ms) => {
  let timeoutId;
  return function () {
    clearTimeout(timeoutId);
    timeoutId = setTimeout(() => {
      fn.apply(this, arguments);
    }, ms);
  };
};
export const debounceSimplify = (fn, delay) => {
  let timer = null; //借助闭包
  return function () {
    if (timer) {
      clearTimeout(timer);
    }
    timer = setTimeout(fn, delay); // 简化写法
  };
};
//数组数据去重
export const unique = (arr) => {
  let newArr = [];
  arr.forEach((item) => {
    if (!newArr.includes(item)) newArr.push(item);
  });
  return newArr;
};
// 数组中随机取几个元素
export const getRandomArrayElements = (arr, count) => {
  var shuffled = arr.slice(0),
    i = arr.length,
    min = i - count,
    temp,
    index;
  while (i-- > min) {
    index = Math.floor((i + 1) * Math.random());
    temp = shuffled[index];
    shuffled[index] = shuffled[i];
    shuffled[i] = temp;
  }
  return shuffled.slice(min);
};
/**
 * 不压缩剪裁
 */
/**
 * 图片居中剪裁显示
 * @param {*} ImgBox 图片的父元素，也就是它的容器元素
 * @param {*} ImgD 图片元素对象，也就是将要被缩放的图片
 * @param {*} iwidth 容器元素的宽度
 * @param {*} iheight 容器元素的高度
 * @param {*} type 剪裁类型 1：logo 2:头像
 */
export const DrawImage = (ImgBox, ImgD, iwidth, iheight, type) => {
  if (ImgBox) {
    ImgBox.style.overflow = "hidden";
  }
  var width, height;

  if (ImgD.width() > 0 && ImgD.height() > 0) {
    if (type === 1) {
      if (ImgD.width() / ImgD.height() > iwidth / iheight) {
        height = "100%";
        width = iwidth;
      } else if (ImgD.width() / ImgD.height() < iwidth / iheight) {
        width = "100%";
        height = iheight;
      } else {
        width = iwidth;
        height = iheight;
      }
      ImgD.css({
        width: width,
        height: "auto",
      });
    } else {
      if (ImgD.width() / ImgD.height() >= iwidth / iheight) {
        height = iheight;
        width = (ImgD.width() / ImgD.height()) * iheight;
      } else {
        width = iwidth;
        height = (ImgD.height() / ImgD.width()) * iwidth;
      }
      ImgD.css({
        width: width,
        height: height,
        left: "50%",
        transform: "translateX(-50%)",
        position: "absolute",
      });
    }
  }
};
/**
 * 图片加载并剪裁显示
 * @param {*} avater 头像路径
 * @param {*} $avater 当前头像容器img
 * @param {*} $avaterWH 头像设置宽高
 * @param {*} type 剪裁类型 1：logo 2:头像
 */
export const Imageinit = (avater, $avater, $avaterWH, type) => {
  let img = new Image();
  img.src = avater;
  if (img.complete) {
    DrawImage(
      null,
      $avater,
      $avaterWH.outerWidth(),
      $avaterWH.outerWidth(),
      type
    );
  } else {
    img.onload = function () {
      // 经测试IE/FF都支持(测了IE8/FF10)
      if (!img.complete) return;
      DrawImage(
        null,
        $avater,
        $avaterWH.outerWidth(),
        $avaterWH.outerWidth(),
        type
      );
    };
  }
};

//判断图片是否损坏
export const CheckImgExists = (imgurl) => {
  var ImgObj = new Image(); //判断图片是否存在
  ImgObj.src = imgurl;
  //没有图片，则返回-1
  if (ImgObj.fileSize > 0 || (ImgObj.width > 0 && ImgObj.height > 0)) {
    return true;
  } else {
    return false;
  }
};
/**
 * 隐藏手机号中间四位，正则替换
 * @param {*} phone
 * @returns
 */
export const hidePhone = (phone) => {
  let reg = /^(\d{3})\d{4}(\d{4})$/;
  return phone.replace(reg, "$1****$2");
};
/**
 * val 需要添加星号的值
 * front 前面保留几位
 * after 后面保留几位
 */
export const addStar = (val, front, after) => {
  return (
    val.substring(0, front) +
    "*".repeat(val.length - (front + after)) +
    val.substring(val.length - after)
  );
};

/**
 *根据数据条数与每页多少条数据计算页数
 * totalnum 数据条数
 * limit 每页多少条
 */

export const pageCount = (totalnum, limit) => {
  return totalnum > 0
    ? totalnum < limit
      ? 1
      : totalnum % limit
      ? parseInt(totalnum / limit) + 1
      : totalnum / limit
    : 0;
};
export const ArrayObj = (obj) => {
  var arr = [];
  for (let i in obj) {
    let o = {};
    o[i] = obj[i];
    arr.push(o);
  }
  return arr;
};
/**
 * @description 给数字或者金额千分位添加逗号
 * @param money {number} 必须传入数字类型
 * @param precision {number} 精度 默认是小数点后两位
 * @param splitDesc {string} 分隔符
 * console.log(formatMoney(12121313.55555)) // 保留小数点后两位
 * console.log(formatMoney(12121313.55555, 0)) // 相当于四舍五入取整
 * console.log(formatMoney(12121313.55555, 1)) // 保留小数点后一位
 * console.log(formatMoney(12121313, 1)) // 保留小数点后一位
 * console.log(formatMoney(12121313, 2)) // 保留小数点后两位位
 * console.log(formatMoney(12121313, 2, '#')) // 分隔符为#
 * */
export const formatMoney = (money, precision = 2, splitDesc = ",") => {
  // console.log(money, "money");
  precision = +precision; // 这里为了处理precision传入null  +null=0
  const str = Number(money).toFixed(precision);
  const reg =
    str.indexOf(".") > -1 ? /(\d)(?=(\d{3})+\.)/g : /(\d)(?=(?:\d{3})+$)/g;
  return str.replace(reg, "$1" + splitDesc);
};
// 数字转换
export const numberFormat = (value) => {
  var param = {};
  var k = 10000,
    sizes = ["", "万", "亿", "万亿"],
    i;
  if (value < k) {
    param.value = value;
    param.unit = "";
  } else {
    i = Math.floor(Math.log(value) / Math.log(k));

    param.value = (value / Math.pow(k, i)).toFixed(0);
    param.unit = sizes[i];
  }
  return param;
};
//检验数字1
export function isNumber(value) {
  let s = trim(value);
  var patrn = /^(-)?\d+(\.\d+)?$/;
  if (patrn.exec(s) == null || s == "") {
    return false;
  } else {
    return true;
  }
}
//检验数字2
export function validatorNumber(rule, value, callback) {
  let s = trim(value);
  if (!s) {
    return callback();
  } else {
    var patrn = /^(-)?\d+(\.\d+)?$/;
    if (patrn.exec(s) == null || s == "") {
      return callback(new Error("必须为数字"));
    } else {
      return callback();
    }
  }
}
//表单检验身份证 #4015  身份证校验
export function validatorIdentityCardNo(rule, value, callback) {
  let s = trim(value);
  if (!s) {
    return callback();
  } else {
    if (
      !/^[1-9]\d{5}(18|19|2([0-9]))\d{2}(0[0-9]|10|11|12)([0-2][1-9]|30|31)\d{3}[0-9Xx]$/.test(
        s
      )
    ) {
      return callback(new Error("身份证格式不正确"));
    } else {
      return callback();
    }
  }
}
//检验手机1
export function checkPhone(value) {
  let s = trim(value);
  if (!/^1[3|4|5|7|8][0-9]\d{4,8}$/.test(s)) {
    return false;
  } else {
    return true;
  }
}
//表单检验手机2
export function validatorPhone(rule, value, callback) {
  let s = trim(value);
  if (!s) {
    return callback();
  } else {
    //#3357 联系人移动电话填写175号段的号码都会提示“手机格式不正确”

    var patrn =
      /^(0|86|17951)?(13[0-9]|15[012356789]|17[0-9]|18[0-9]|14[0-9]|14[0-9]|19[0-9]|16[0-9])[0-9]{8}$/;
    if (patrn.exec(s) == null || s == "") {
      return callback(new Error("手机格式不正确"));
    } else {
      return callback();
    }
  }
}
//检验电话1
export function checkTel(value) {
  let s = trim(value);
  if (!/^(\(\d{3,4}\)|\d{3,4}-|\s)?\d{7,14}$/.test(s)) {
    return false;
  } else {
    return true;
  }
}
//表单检验电话2
export function validatorTel(rule, value, callback) {
  let s = trim(value);
  if (!s) {
    return callback();
  } else {
    if (!/^(\(\d{3,4}\)|\d{3,4}-|\s)?\d{7,14}$/.test(s)) {
      return callback(new Error("电话格式不正确"));
    } else {
      return callback();
    }
  }
}
//表单检验办公电话
export function validatorbgTel(value) {
  let s = value.trim();
  if (!s) {
    return "";
  } else {
    if (
      !/((\d{11})|^((\d{7,8})|(\d{4}|\d{3})-(\d{7,8})|(\d{4}|\d{3})-(\d{7,8})-(\d{4}|\d{3})|(\d{7,8})-(\d{4}|\d{3}))$)/.test(
        s
      )
    ) {
      return false;
    } else {
      return true;
    }
  }
}
//添加传真的验证
export function validatorbgFax(rule, value, callback) {
  let s = trim(value);
  if (!s) {
    return callback();
  } else {
    if (
      !/((\d{11})|^((\d{7,8})|(\d{4}|\d{3})-(\d{7,8})|(\d{4}|\d{3})-(\d{7,8})-(\d{4}|\d{3})|(\d{7,8})-(\d{4}|\d{3}))$)/.test(
        s
      )
    ) {
      return callback(new Error("传真格式不正确"));
    } else {
      return callback();
    }
  }
}
//传真正则
export function validatorFax(rule, value, callback) {
  let s = trim(value);
  if (!s) {
    return callback();
  } else {
    if (!/^(\d{3,4}-)?\d{7,8}$/.test(s)) {
      return callback(new Error("传真格式不正确"));
    } else {
      return callback();
    }
  }
}

//表单检验邮编1
export function validatorPostCode(rule, value, callback) {
  let s = trim(value);
  if (!s) {
    return callback();
  } else {
    if (!/^[0-9][0-9]{5}$/.test(s)) {
      return callback(new Error("格式不正确"));
    } else {
      return callback();
    }
  }
}
//表单检验邮编2
export function isPostCode(value) {
  let s = trim(value);
  if (!s) {
    return true;
  } else {
    if (!/^[0-9][0-9]{5}$/.test(s)) {
      return false;
    } else {
      return true;
    }
  }
}
//表单检验邮箱1
export function isEmail(value) {
  let s = trim(value);
  if (!s) {
    return true;
  } else {
    if (!/^[\w\-\.]+@[\w\-\.]+(\.\w+)+$/.test(s)) {
      return false;
    } else {
      return true;
    }
  }
}
//表单检验邮箱2
export function validatorEmail(rule, value, callback) {
  let s = trim(value);
  if (!s) {
    return callback();
  } else {
    if (!/^[\w\-\.]+@[\w\-\.]+(\.\w+)+$/.test(s)) {
      return callback(new Error("邮箱格式不正确"));
    } else {
      return callback();
    }
  }
}

//表单检验银行账号
export function validatorBankAccount(rule, value, callback) {
  let s = trim(value);
  if (!s) {
    return callback();
  } else {
    if (/[\u4E00-\u9FA5]/g.test(s)) {
      return callback(new Error("银行账户格式不正确"));
    } else {
      return callback();
    }
  }
}
// if (!/^[0-9a-zA-Z,'',-]{1,}$/.test(s)

//表单检验企业信用代码
export function validatorSocialCreditCode(rule, value, callback) {
  let s = trim(value);
  if (!s) {
    return callback(new Error("社会信用代码格式不能为空"));
  } else {
    if (s.length > 18 || s.length < 18) {
      return callback(new Error("社会信用代码格式不正确"));
    } else {
      if (!/[0-9A-Z]{18}/.test(s)) {
        return callback(new Error("社会信用代码格式不正确"));
      } else {
        return callback();
      }
    }
  }
}
//检验企业信用代码
export function isSocialCreditCode(value) {
  var s = trim(value);
  if (!s) {
    return false;
  } else {
    if (s.length > 18 || s.length < 18) {
      return false;
    } else {
      if (!/[0-9A-Z]{18}/.test(s)) {
        return false;
      } else {
        return true;
      }
    }
  }
}
export function getCircle(id, circleR, border, progress, gradient) {
  drawProgress(id, progress);
  /**
   * 画圆
   * @param {number} cx x坐标
   * @param {number} cy y坐标
   * @param {number} r  半径
   * @param {number} lineWidth  线宽度
   * @return null
   */
  function circle(ctx, cx, cy, r, lineWidth) {
    if (!ctx) return;
    ctx.beginPath();
    ctx.lineWidth = lineWidth;
    ctx.strokeStyle = "#eee";
    ctx.arc(cx, cy, r, 0, Math.PI * 10, true);
    ctx.stroke();
  }

  /**
   * 画弧线
   * @param {number} cx x坐标
   * @param {number} cy y坐标
   * @param {number} r  半径
   * @param {number} lineWidth  线宽度
   * @param {number} startAngle 开始角度 按照360算
   * @param {number} endAngle 结束角度 按照360算
   * @return null
   */
  function sector(ctx, cx, cy, r, lineWidth, startAngle = 0, endAngle = 360) {
    if (!ctx) return;
    ctx.beginPath();
    ctx.lineWidth = lineWidth;

    // 渐变色
    let linGrad = ctx.createLinearGradient(
      cx - r - lineWidth,
      cy,
      cx + r + lineWidth,
      cy
    );
    linGrad.addColorStop(0.0, gradient.star);
    linGrad.addColorStop(1.0, gradient.end);
    ctx.strokeStyle = linGrad;

    // 单色
    // ctx.strokeStyle = 'red';

    //圆弧两端的样式
    ctx.lineCap = "round";

    //圆弧
    const _startAng = Math.PI * ((startAngle - 90) / 180);
    const _endAng = Math.PI * ((endAngle - 90) / 180);
    ctx.arc(cx, cy, r, _startAng, _endAng, false);
    ctx.stroke();
  }

  /**
   * 刷新进度条
   * @param {number} cx x坐标
   * @param {number} cy y坐标
   * @param {number} r  半径
   * @param {number} lineWidth  线宽度
   * @param {number} process 完成进度
   * @return null
   */
  function freshProgress(ctx, cx, cy, r, lineWidth, process) {
    if (!ctx) return;

    //清除canvas内容
    ctx.clearRect(0, 0, cx * 2, cy * 2);

    //圆形
    circle(ctx, cx, cy, r, lineWidth);

    //圆弧
    sector(ctx, cx, cy, r, lineWidth, 0, process * 3.6);
  }

  /**
   * 绘制进度条 有动画的
   * @param {string} id canvas 组件的id
   * @param {number} progress 进度 最大100 是一圈
   * @param {number} time 执行时间 单位秒 p.s. 好像算的有点不对 大概
   */
  function drawProgress(id, progress, time = 1) {
    const canvas = id, // canvas进度条
      circleX = canvas.width / 2, //中心x坐标
      circleY = canvas.height / 2, //中心y坐标
      radius = circleR, //圆环半径
      lineWidth = border, //圆形线条的宽度
      delay = (time / progress) * 2000; // 延迟时间
    const ctx = canvas.getContext("2d");
    let nowProgress = 0; //显示进度
    let circleLoading = window.setInterval(function () {
      if (nowProgress > progress) {
        clearInterval(circleLoading);
      } else {
        freshProgress(ctx, circleX, circleY, radius, lineWidth, nowProgress);
        nowProgress += 1.0;
      }
    }, delay);
  }
}
export const getTimeState = () => {
  // 获取当前时间
  let timeNow = new Date();
  // 获取当前小时
  let hours = timeNow.getHours();
  // 设置默认文字
  let text = ``;
  // 判断当前时间段
  if (hours >= 0 && hours <= 10) {
    text = `早上好`;
  } else if (hours > 10 && hours <= 14) {
    text = `中午好`;
  } else if (hours > 14 && hours <= 18) {
    text = `下午好`;
  } else if (hours > 18 && hours <= 24) {
    text = `晚上好`;
  }
  // 返回当前时间段对应的状态
  return text;
};
export const handleDownload = (url, filename) => {
  const x = new window.XMLHttpRequest();
  x.open("GET", url, true);
  x.responseType = "blob";
  x.onload = () => {
    let time = new Date().toLocaleDateString();
    const url = window.URL.createObjectURL(x.response);
    const a = document.createElement("a");
    a.href = url;
    a.download = filename + "_" + time + ".csv";
    a.click();
  };
  x.send();
};
/**
 * 随机生成RGB颜色
 * @returns
 */
export const randomRgbColor = (opacity) => {
  var r = Math.floor(Math.random() * 255); //随机生成256以内r值
  var g = Math.floor(Math.random() * 255); //随机生成256以内g值
  var b = Math.floor(Math.random() * 255); //随机生成256以内b值
  // return `rgb(${r},${g},${b},${opacity ? opacity : 1})`; //返回rgb(r,g,b)格式颜色
  // let color = `#${r.toString(16)}${g.toString(16)}${b.toString(16)}`;
  let color = `rgb(${r},${g},${b},1)`;
  let Factorcolor = `rgb(${r},${g},${b},${opacity ? opacity : 0.2})`;
  return {
    color: color,
    bgcolor: Factorcolor,
  };
};
/**
 * 获取颜色值
 */
export const color2RGB = (color) => {
  if (typeof color !== "string" || (color.length !== 7 && color.length !== 4))
    return [0, 0, 0];
  color = color.substr(1);
  if (color.length === 3) {
    return color.split("").map((str) => parseInt(str + str, 16));
  }

  let result = [];
  for (let i = 0; i < 6; i += 2) {
    result.push(parseInt(color.substr(i, 2), 16));
  }

  return result;
};
/**
 * 加深：correctionFactor<0
   变亮：correctionFactor>0
   -1.0f <= correctionFactor <= 1.0f

   @colorStr  颜色值：#ff0000
 */
export const changeColor = (colorStr, correctionFactor) => {
  let color = color2RGB(colorStr);
  let red = parseFloat(color[0]);
  let green = parseFloat(color[1]);
  let blue = parseFloat(color[2]);

  if (correctionFactor < 0) {
    correctionFactor = 1 + correctionFactor;
    red *= correctionFactor;
    green *= correctionFactor;
    blue *= correctionFactor;
  } else {
    red = (255 - red) * correctionFactor + red;
    green = (255 - green) * correctionFactor + green;
    blue = (255 - blue) * correctionFactor + blue;
  }

  if (red < 0) red = 0;

  if (red > 255) red = 255;

  if (green < 0) green = 0;

  if (green > 255) green = 255;

  if (blue < 0) blue = 0;

  if (blue > 255) blue = 255;

  red = parseInt(red);
  green = parseInt(green);
  blue = parseInt(blue);

  return [red, green, blue];
};
/**
 * 文字水印生成
 * @param {*} html
 * @param {*} str
 * @param {*} options
 */
export const watermarkText = (html, str, options = {}) => {
  //调用案例
  // let html = document.getElementById('c1')
  // watermarkText(html, '韦尼克平台', { width: 200, start: -30 })
  let {
    fontSize = 18, // 设置字体大小
    fontFamily = "Vedana", // 设置字体
    width = str.length * fontSize, // 设置画布的宽
    height = 124, // 设置画布的高
    color = "rgba(61, 61, 61, 0.1)", // 设置填充绘画的颜色/渐变模式
    angle = -25, // 旋转角度
    textAlign = "left", // 设置文本内容的对齐方式
    font, // 设置字体及大小
    textBaseline = "Middle", // 设置文本基线
    start = 0, // 开始绘制文本的X坐标位置
    end = height / 1.2, // 开始绘制文本的Y坐标位置
  } = options;

  // 创建画布
  let cans = createCanvas(width, height);
  let canvas = cans.canvas;
  let ctx = cans.ctx;
  ctx.rotate((angle * Math.PI) / 180);
  ctx.font = font || fontSize + "px " + fontFamily;
  ctx.fillStyle = color;
  ctx.textAlign = textAlign;
  ctx.textBaseline = textBaseline;
  // 在画布上绘制填色的文本（输出的文本，开始绘制文本的X坐标位置，开始绘制文本的Y坐标位置）
  ctx.fillText(str, start, end);
  // 填充水印
  fillMark(canvas, html);
};
/**
 * 图片水印生成
 * @param {*} html
 * @param {*} options
 */
export const watermarkImg = (html, options = {}) => {
  //调用案例
  // let html2 = document.getElementById('c2')
  // watermarkImg(html2, { src: '//image.yoojober.com/upload-m/2023-11/656494742574b.png', x: -20 })
  let {
    width = 200, // 设置画布的宽
    height = 120, // 设置画布的高
    angle = -25, // 旋转角度
    x = 0, // 放置图像的x坐标
    y = height / 1.5, // 放置图像的y坐标
    src = "", // 图片地址
  } = options;

  // 创建画布
  let cans = createCanvas(width, height);
  let canvas = cans.canvas;
  let ctx = cans.ctx;
  ctx.rotate((angle * Math.PI) / 180);
  let img = new Image(); // 创建图片
  img.onload = function (e) {
    // 监听图片加载完成
    ctx.drawImage(img, x, y, this.width, this.height);
    // 填充水印
    fillMark(canvas, html);
  };
  img.src = src;
};
/**
 * 创建画布
 * @param {*} width
 * @param {*} height
 * @returns
 */
function createCanvas(width, height) {
  // 创建一个画布
  let canvas = document.createElement("canvas");
  // 设置画布的宽高
  canvas.width = width;
  canvas.height = height;

  return {
    canvas,
    ctx: canvas.getContext("2d"),
  };
}

/**
 * 填充水印
 * @param {*} canvas
 * @param {*} html
 */
function fillMark(canvas, html) {
  html.style.pointerEvents = "none";
  html.style.background =
    "url(" + canvas.toDataURL("image/png") + ") left top repeat";
}
export default {
  transRgba,
  setStyleConfig,
  getQuery,
  resetStyle,
  hexToRgba,
  getRgba,
  b2ValueUnit,
  debounce,
  debounceSimplify,
  unique,
  getRandomArrayElements,
  Imageinit,
  hidePhone,
  pageCount,
  ArrayObj,
  formatMoney,
  addStar,
  getTimeState,
  handleDownload,
  randomRgbColor,
  color2RGB,
  changeColor,
  watermarkText,
  watermarkImg,
};
