import React, { Component } from 'react'
import {
  Layout,
  Typography,
  Button,
  Space,
  Empty,
  Input,
  Select,
  Notification,
  Divider,
  InputTag,
  Form,
} from '@arco-design/web-react'
import { IconPlus } from '@arco-design/web-react/icon'
import Emptydata_img from 'asserts/Emptydata_img.svg'
import Generating_img from 'asserts/Generating_img.gif'
import errorIcon from 'asserts/error_icon.svg'
import {
  getApiInfo,
  getRequest,
  putmodelversion,
  putapp,
  check_resource,
  getAllapiList,
  newGetappList,
  newEditappList,
} from 'api/apis'
import history from 'common/utils/history'
import { CreateLink } from 'common/utils/business'
import { debounce } from 'common/utils'
import { sourceCancelClass } from 'api/cancel-request'
import copy from 'copy-to-clipboard'
import UpgradeTips from 'components/UpgradeTips'
import { localAxios } from "api/request";
import './enteringGinseng.scss'
const Sider = Layout.Sider
const Content = Layout.Content
const Option = Select.Option
const TextArea = Input.TextArea
const FormItem = Form.Item
// 封装组件
class enteringGinseng extends Component {
  constructor(props) {
    super(props)
    this.formRef = React.createRef()
    this.state = {
      no_data: false,
      optionVlue: '',
      versions: [],
      ParagraphContinuation: [],
      ContinuationLoding: false,
      record: JSON.parse(localStorage.getItem('record')),
      apiInfo: {
        model_id: '',
      },
      number: 6,
      requestMsg: '',
      requestMsgText: '您还未输入文段',
      ner: null,
      visibleUpgrade: false,
      upgradetips: null,
      size: 'default',
      sellPotionTextLength: 0,
      sellPotionText30Length: 0,
      sellPotionText800Length: 0,
      modelData: {
        num: '1',
        text_length: '长',
      },
      isShow: false,
      formDataShow: {},
      textareaDesc: '',
      istext: '',
      identity: JSON.parse(localStorage.getItem('userinfo'))
        ? JSON.parse(localStorage.getItem('userinfo')).group_permission
        : '',
      apiValue:'',
      interfaceOption:[],
      api_id:'',
      postValue:'',
      selectOption:[],
      selectValue:'',
      model_params: {},
      model_id: '',
    }
    this.isPhoneLegal = debounce(this.isPhoneLegal, 1000)
    this.isputapp = debounce(this.isputapp, 1000)
  }
  onChangeFormData(key, value) {
    // this.state.formDataShow[key] = value
    // console.log("onChangeFormData",this.state.formDataShow);
    this.setState({
      formDataShow: { ...this.state.formDataShow, [key]: value },
    })
  }

  componentDidMount() {
    this.getApiInfo()
    window.addEventListener('resize', this.handleResize)
    setTimeout(() => {
      this.handleResize()
    }, 300)
  }
  componentWillUnmount() {
    localStorage.removeItem('props')
    window.removeEventListener('resize', this.handleResize)
  }

  handleResize = () => {
    var winHeight = 0
    if (window.innerHeight) {
      winHeight = window.innerHeight
    } else if (document.body && document.body.clientHeight) {
      winHeight = document.body.clientHeight
    }
    if (document.documentElement && document.documentElement.clientHeight) {
      winHeight = document.documentElement.clientHeight
    }
    let layoutHeaders_h = document.getElementById('layoutHeaders').clientHeight
    if (layoutHeaders_h > winHeight) {
      layoutHeaders_h = 64
    }
    let height =
      winHeight -
      layoutHeaders_h -
      document.getElementById('renderHeaders').clientHeight -
      70
    document.getElementById('layout_id').style.height = height + 'px'

    setTimeout(() => {
      if (document.getElementById('continuation')) {
        let height2 =
          height -
          document.getElementById('result_title').clientHeight -
          document.getElementById('iconSync-btn').clientHeight -
          33
        document.getElementById('continuation').style.height =
          height2 - 42 + 'px'
      }
    }, 100)
  }
  putmodelversion(optionVlue) {
    let params = {
      id: optionVlue,
    }
    this.setState({
      ContinuationLoding: true,
    })
    putmodelversion(params).then((res) => {
      if (res.code === 200) {
        this.isPhoneLegal()
      } else {
        this.setState({
          ParagraphContinuation: [],
          ContinuationLoding: false,
          requestMsg: '',
          requestMsgText: '模型版本激活失败',
        })
      }
    })
  }

  onChangeParams(index){
    const {selectOption } = this.state;
    let model_params = {}
    selectOption[index].params.forEach((item,index)=>[
      model_params[item.id] = item
    ])
    this.setState({
      selectValue:selectOption[index].title,
      model_params:model_params,
      model_id:selectOption[index].model_id,
      formDataShow:{}
    })
  }
  onPressEnter() {
    const { postValue } = this.state;

    // this.setState({
    //   ContinuationLoding: true,
    // })

    // let params = {
    //   "text":keyword || '',
    // }
    let postApi = localAxios("get", '/copydone/model');
    postApi().then((res) => {
      if(res.code === 200){
        this.setState({
          selectOption:res.data,
          // ContinuationLoding: false,
        })
      } else {
        Notification.error({ content: res.msg })
      }
      this.onChangeParams(0)
    })
  }

  getCheckResources() {
    const { postValue,model_id } = this.state;
    const text = this.transformToArrays(this.state.formDataShow)
    let params = {
      "model_id":model_id,
      "text":JSON.stringify(text),
      "to":'zh'
    }
    this.setState({
      ContinuationLoding: true,
    })
    let postApi = localAxios("post", '/copydone/article');
    postApi(params).then((res) => {
      if(res.code === 200){
        const resData = res.data;
        // 分割字符串
        const parts = resData.split('#### ');
        // 创建对象数组
        let paragraphContent = []
        parts.forEach((part,index) => {
          if(index == 0){
            paragraphContent.push( { sentence: part.replace(/### /g, '').replace(/<br>/g, '') })
          } else {
            paragraphContent.push( { sentence: part.replace(/<br>/g, '') })
          }
        });
        this.setState({
          ParagraphContinuation:paragraphContent,
          ContinuationLoding: false,
        })
      } else if(res.code === 3003){
        this.setState({
          ParagraphContinuation:[],
          ContinuationLoding: false,
        })
        Notification.warning({ content: res.msg })
      } else {
        this.setState({
          ParagraphContinuation:[],
          ContinuationLoding: false,
        })
        Notification.error({ content: res.msg })
      }
      // this.onChangeParams(0)
    })
  }
  transformToArrays(obj) {
    return Object.fromEntries(
      Object.entries(obj).map(([key, value]) => [key, [value]])
    );
  }
  
  openInterface(value){
    const { interfaceOption } = this.state;
    this.setState({
      api_id: interfaceOption[value].api_id,
      apiValue: interfaceOption[value].value,
      newApi_id: interfaceOption[value].api_id,
    })
  }
  
  getApiInfo() {
    const { interfaceOption } = this.state;
    let params = {
      id: this.state.record.show_id,
    }
    newGetappList(params).then((res) => {
      if (res.code === 200) {
        let list = res.data
        res.data.api_list.forEach((item,index) => {
          let api_option = {
            value:'',
            label:'',
            api_id:''
          }
          api_option.value = index;
          api_option.label = item.name;
          api_option.api_id = item.api_id;
          interfaceOption.push(api_option)
        })
        this.setState({
          apiInfo: list,
          apiValue: res.data.api_list[0].name,
          api_id:res.data.api_list[0].api_id,
          interfaceOption:interfaceOption,
          newApi_id:res.data.api_list
        })
      
        // 将type === 'tags'放到对象最后
        // const TagsEle = {}
        // Object.keys(res.data.model_params).forEach((key) => {
        //   if (res.data.model_params[key].type === 'tags') {
        //     TagsEle[key] = res.data.model_params[key]
        //     delete res.data.model_params[key]
        //   }
        // })
        // Object.keys(TagsEle).forEach((key) => {
        //   res.data.model_params[key] = TagsEle[key]
        // })

        // let list = res.data
        // let modelParams = list.model_params
        // Object.keys(modelParams).forEach((item, index) => {
        //   switch (modelParams[item].type) {
        //     case 'search':
        //     case 'select':
        //     case 'input':
        //       this.state.modelData[item] = modelParams[item].label[0]
        //       break
        //     case 'tags':
        //       this.state.modelData[item] = modelParams[item].label
        //     default:
        //   }
        // })
        // Object.keys(modelParams).forEach((key) => {
        //   this.state.formDataShow[key] = ''
        // })
        this.getInterfaceParameter()

        //console.log(res.data, "api服务");
      } else {
        Notification.warning({ content: res.data.message })
      }
    })
  }
  onsetPosition(value) {
    //console.log(value, "value");
    this.setState({
      optionVlue: value,
    })
    this.putmodelversion(value)
  }
  onKeyDown(event) {
    sourceCancelClass()
    // console.log(event.keyCode, "键盘事件");
    if (event.shiftKey && event.keyCode === 13) {
    } else if (event.ctrlKey && event.keyCode === 13) {
      event.cancelBubble = true
      event.preventDefault()
      event.stopPropagation()
      event.returnValue = false
      this.setState({
        ParagraphContinuation: [],
        ContinuationLoding: true,
        requestMsg: '',
      })
      this.isPhoneLegal()
      return false
    } else if (event.keyCode === 13) {
      this.setState({
        ParagraphContinuation: [],
        ContinuationLoding: true,
        requestMsg: '',
      })
      event.cancelBubble = true
      event.preventDefault()
      event.stopPropagation()
      this.isPhoneLegal()
      event.returnValue = false
      return false
    }
  }
  onSellPoiont(key, val) {
    this.setState({
      sellPotionTextLength: val.length,
    })
    this.setState({
      textareaDesc: val,
    })
    this.state.formDataShow[key] = val
    // console.log('formDataShow ', this.state.formDataShow)
  }
  onSellPoiont30(key, val) {
    this.setState({
      sellPotionText30Length: val.length,
    })
    this.setState({
      textareaDesc: val,
    })
    this.state.formDataShow[key] = val
    // console.log('formDataShow ', this.state.formDataShow)
  }
  onSellPoiont800(key, val) {
    this.setState({
      sellPotionText800Length: val.length,
    })
    this.setState({
      textareaDesc: val,
    })
    this.state.formDataShow[key] = val
    // console.log('formDataShow ', this.state.formDataShow)
  }
  ChangeBatch() {
    let svg = document.querySelector('.changeBatch svg')
    let degerr = 0
    let timer = setInterval(() => {
      degerr += 30
      if (degerr > 360) {
        // degerr = 0
        clearInterval(timer)
      }
      svg.style.transform = 'rotate(' + degerr + 'deg)'
    }, 100)
  }

  goGeneration() {
    const { model_params } = this.state;
    const formDataKey = Object.keys(this.state.formDataShow);
    const paramKey = Object.keys(model_params);
    const uniqueParamKey = paramKey.filter(key => !formDataKey.includes(key));
    Object.keys(this.state.formDataShow).forEach((key) => {
      if (this.state.formDataShow[key] === '') {
        this.setState(
          (prevState) => ({
            formDataShow: {
              ...prevState.formDataShow,
              [key]: this.state.apiInfo.model_params[key].sample,
            },
          }),
          () => {
            // console.log('this.state.formDataShow', this.state.formDataShow[key])
          }
        )
      }
    })

    if(formDataKey.length == 0 || formDataKey.length != paramKey.length){
      uniqueParamKey.forEach((item,index)=>{
        this.state.formDataShow[item] = model_params[item].label[0]
      })
    }
    this.setState({
      ContinuationLoding: true,
      isShow: true,
    })
    this.getCheckResources()
    sourceCancelClass()
    // this.isPhoneLegal()
  }


  isPhoneLegal = () => {
    this.setState({
      ParagraphContinuation: [],
      ContinuationLoding: true,
      requestMsg: '',
    })
    this.setRequest()
  }
  ChangeAbatch() {
    this.setState({
      ContinuationLoding: true,
      requestMsg: '',
    })
    setTimeout(() => {
      this.setRequest()
    }, 1000)
  }
  setRequest() {
    // console.log('json', this.state.formDataShow)
    if (!this.state.formDataShow.text_length) {
      this.setState((prevState) => ({
        formDataShow: {
          ...prevState.formDataShow,
          text_length: '长',
        },
      }))
    }
    let params = {
      app_id: this.state.record.id,
      json: JSON.stringify(this.state.formDataShow),
    }
    if (this.state.optionVlue) {
      params.version_id = this.state.optionVlue
    }

    let that = this
    this.getCheckResource(function (result) {
      if (result.code === 200) {
        getRequest(params).then((res) => {
          if (res.code === 200) {
            let list = res.data
            setTimeout(() => {
              that.setState({
                ner: list.ner,
                ParagraphContinuation: list.generations
                  ? that.uniqueList(list.generations)
                  : [],
                ContinuationLoding: false,
                requestMsg: '',
              })
              if (that.uniqueList(list.generations).length === 0) {
                that.setState({
                  ParagraphContinuation: [],
                  ContinuationLoding: false,
                  requestMsg: '',
                  requestMsgText: '请换个内容试试',
                })
              }
              that.handleResize()
            }, 100)
          } else if (res.code === 400 || res.code === 3003) {
            that.setState({
              ParagraphContinuation: [],
              ContinuationLoding: false,
              requestMsg: '',
              requestMsgText: res.msg,
            })
          } else if (res.code === 204) {
            that.setState({
              ParagraphContinuation: [],
              ContinuationLoding: false,
              requestMsg: '',
              requestMsgText: '请换个内容试试',
            })
          } else {
            that.setState({
              ParagraphContinuation: [],
              ContinuationLoding: false,
              requestMsg: 'error',
              requestMsgText: res.msg,
            })
          }
        })
      } else {
        that.setState({
          ParagraphContinuation: [],
          ContinuationLoding: false,
          requestMsg: 'error',
          requestMsgText: result.msg,
        })
      }
    })
  }
  getCheckResource(callback) {
    let params = { model_capability: this.state.apiInfo?.model_capability }
    let PACKAGES = ['PACKAGE_FINISH', 'PACKAGE_EXPIRED']
    check_resource(params).then((res) => {
      if (res.code === 200) {
        if (res.data) {
          let new_upgradetips = res.data
          new_upgradetips.model_capability = params.model_capability
          this.setState({
            upgradetips: new_upgradetips,
          })
          if (PACKAGES[new_upgradetips.notice_type]) {
            Notification.warning({ content: new_upgradetips.message })
          } else {
            setTimeout(() => {
              this.setState({
                visibleUpgrade: true,
              })
            }, 100)
          }
        }
        callback(res)
      } else {
        callback(res)
      }
    })
  }
  // 根据下标替换字符
  oneReplace(str, index) {
    let arr = str.split('')
    return arr[index]
  }
  lengthReplace(text, start, stop) {
    let newstr = text.substring(start, stop + 1)
    return newstr
  }
  replaceAllText(newstr) {
    let nerlist = this.state.ner
    let oldnewstr = newstr
    Object.keys(nerlist ? nerlist : []).map((item, i) => {
      nerlist[item].map((key, index) => {
        let replaceText = key[0]
        // if (key[0].length === 1) {
        //   replaceText = this.oneReplace(oldnewstr, key[1][0])
        // } else {
        //   let endindex = key[1].length - 1
        //   let end = key[1][endindex] //结束下标
        //   let start = key[1][0] //开始小标
        //   replaceText = this.lengthReplace(oldnewstr, start, end)
        // }
        let rehtml = `<span style='color: #406EFF'>` + replaceText + `</span>`
        // let reg = key[0].toLowerCase()
        // var flagnew = key[0].replace(/[^a-z\d]/ig, "");
        // if (flagnew != null) {
        newstr = newstr.replaceAll(new RegExp(replaceText, 'ig'), rehtml)
        // }
      })
    })

    let html = { __html: newstr.replaceAll('\n', '<br/>') }
    return <div dangerouslySetInnerHTML={html}></div>
  }
  // 数组中对象的某个属性值进行去重
  uniqueList = (arr) => {
    const res = new Map()
    return arr.filter((a) => !res.has(a.sentence) && res.set(a.sentence, 1))
  }
  goResources() {
    history.push('/myapp/resources')
    localStorage.setItem('record', JSON.stringify(this.state.record))
    window.location.reload()
  }
  goInterfacetest() {
    history.push('/myapp/interfacetest')
    localStorage.setItem('record', JSON.stringify(this.state.record))
    window.location.reload()
  }
  oncopy(item) {
    let value = item.sentence
    if (copy(value)) {
      Notification.success({ content: '复制成功!' })
    } else {
      Notification.error({ content: '复制失败!' })
    }
  }
  onGomanagement() {
    history.push('/enginefactory/model/management')
    localStorage.removeItem('props')
    let apiInfo = this.state.apiInfo
    let record = {
      id: apiInfo.model_id,
      model_capability: apiInfo.model_capability,
      name: apiInfo.model_name,
    }
    localStorage.setItem('record', JSON.stringify(record))
    window.location.reload()
  }
  getInterfaceParameter = () =>{
    const { api_id,newApi_id } = this.state;

    let params = {
        id: newApi_id ? newApi_id[0].api_id : api_id,
        // id: '95YT1z',
    };
    getAllapiList(params).then((res) => {
      if (res.code === 200) {
        this.setState({
          postValue:res.data.api_url
        })
        this.onPressEnter()
      }
    })
  }
  
  isputapp = (name, desc) => {
    this.putapp(name, desc)
  }
  putapp(name, desc) {
    let params = {
      id: this.state.record.show_id,
      desc: desc,
      name:name,
    }
    newEditappList(params).then((res) => {
      if (res.code === 200) {
      } else {
        this.getApiInfo(true)
        Notification.error({ content: res.msg })
      }
    })
  }
  onChangetitle(type, value) {
    //console.log(value, "value");
    var apiInfo = this.state.apiInfo
    if (type === 'desc') {
      if (value.length > 100) {
        apiInfo.desc = value.substring(0, 100)
        Notification.warning({ content: '修改内容超过100个字！' })
        return
      }
      apiInfo.desc = value
      this.setState({
        apiInfo: apiInfo,
      })
      this.putapp(apiInfo.name, value)
    } else if (type === 'name') {
      if (value.length > 20) {
        apiInfo.name = value.substring(0, 20)
        Notification.warning({ content: '修改标题超过20个字！' })
        return
      }
      apiInfo.name = value
      this.setState({
        apiInfo: apiInfo,
      })
      this.putapp(value, apiInfo.desc)
    }
  }
  goaddApp() {
    history.replace('/myapp/addmyapp')
    let state = {
      model_capability: this.state.apiInfo.model_capability,
      checkedtype: this.state.apiInfo.model_id,
      model_id: this.state.apiInfo.model_id,
      name: this.state.apiInfo.model_name,
    }
    localStorage.setItem('props', JSON.stringify(state))
    localStorage.removeItem('record')
    window.location.reload()
  }
  addRow() {
    localStorage.removeItem('props')
    localStorage.setItem('record', JSON.stringify(this.state.apiInfo))
    history.replace('/enginefactory/model/management/add')
    window.location.reload()
  }
  setvisibleUpgrade(value) {
    this.setState({
      visibleUpgrade: value,
    })
  }
  render() {
    const {
      visibleUpgrade,
      upgradetips,
      apiInfo,
      versions,
      ParagraphContinuation,
      ContinuationLoding,
      record,
      optionVlue,
      requestMsg,
      requestMsgText,
      size,
      sellPotionTextLength,
      sellPotionText30Length,
      sellPotionText800Length,
      isShow,
      modelData,
      formDataShow,
      textareaDesc,
      apiValue,
      interfaceOption,
      selectOption,
      selectValue,
      model_params,
    } = this.state
    const props = {
      size,
    }
    return (
      <div className="embroidery">
        <div id="renderHeaders">
          <div className="GroupTable-fixe GroupTable-fixe-end">
            <div className="edit-title">
              <div className="Header-init">
                <div className="Header-title">
                  <Typography.Paragraph
                    className="Header-uptitle"
                    editable={
                      apiInfo.type === 0
                        ? null
                        : {
                            onChange: this.onChangetitle.bind(this, 'name'),
                          }
                    }
                  >
                    {apiInfo.name}
                  </Typography.Paragraph>
                </div>
              </div>
              <div className="Header-upParagraph">
                <Typography.Paragraph
                  className="Header-uptitle"
                  editable={
                    apiInfo.type === 0
                      ? null
                      : {
                          onChange: this.onChangetitle.bind(this, 'desc'),
                        }
                  }
                >
                  {apiInfo.desc}
                </Typography.Paragraph>
              </div>
            </div>
            <Space size={'medium'} align="center">
              {apiInfo.type === 0 ? (
                <Button
                  className="primarybtn custom-White-button"
                  onClick={this.goaddApp.bind(this)}
                >
                  保存应用
                </Button>
              ) : (
                <>
                  {versions.length === 0 ? (
                    <div></div>
                  ) : (
                    <Select
                      value={optionVlue}
                      placeholder="版本选择"
                      style={{ width: 220 }}
                      className="versionsSelect"
                      onChange={this.onsetPosition.bind(this)}
                      dropdownRender={(menu) => (
                        <div>
                          {menu}
                          <Divider
                            style={{
                              margin: 0,
                              marginTop: 6,
                              borderColor: '#f2f2f2',
                            }}
                          />
                          <div
                            style={{
                              display: 'flex',
                              alignItems: 'center',
                              padding: '5px 0px',
                            }}
                          >
                            <Button
                              style={{
                                width: '100%',
                                height: 41,
                                fontSize: 14,
                                padding: '0 6px',
                                borderRadius: 10,
                              }}
                              type="text"
                              onClick={this.addRow.bind(this)}
                            >
                              <IconPlus />
                              新增模型版本
                            </Button>
                          </div>
                        </div>
                      )}
                    >
                      {versions.map((option, index) => (
                        <Option key={index} value={option.id}>
                          {apiInfo.model_name}V{option.version}
                        </Option>
                      ))}
                    </Select>
                  )}
                  {apiInfo.type === 9 ? (
                    <div className="primary_model_name">
                      <div className="primary_model_name_fix">
                        <span className="primary_model_name_title">
                          模型
                          <Divider type="vertical" />
                        </span>
                        <Typography.Paragraph ellipsis>
                          {apiInfo.model_name}
                        </Typography.Paragraph>
                      </div>
                    </div>
                  ) : (
                    ''
                  )}
                  {/* <Button
                    shape="circle"
                    className="custom-White-button"
                    onClick={this.goResources.bind(this)}
                  >
                    应用资源
                  </Button> */}
                  {/* <Button
                    shape="circle"
                    className="custom-White-button share_button_wnk"
                    onClick={CreateLink.bind(this, apiInfo)}
                  >
                    分享应用
                  </Button> */}
                  <Button
                    style={{
                      display:
                        this.state.identity &&
                        this.state.identity !== 'PERSONAL'
                          ? 'inline-block'
                          : 'none',
                    }}
                    shape="circle"
                    className="custom-White-button"
                    onClick={this.goInterfacetest.bind(this)}
                  >
                    接口调用
                  </Button>
                     {/* {
                      interfaceOption.length >= 1 ?
                      <Button 
                      className='openInterface-btn'
                      style={{
                        backgroundColor:'transparent',
                      }}>
                        <Select
                        placeholder="请选择接口"
                        value={apiValue}
                        onChange={(value) =>
                          this.openInterface(value)
                          }
                          options={interfaceOption}
                        ></Select>
                      </Button>
                      : <><div className="debugg-title">暂无接口</div></>
                    } */}
                    
                </>
              )}
            </Space>
          </div>
        </div>
        <Layout className="semanticprototype-Layout" id="layout_id">
          <Sider className="Sider-left">
            {selectOption.length > 0 ? (
              <div className='select-type'>
                 <Typography style={{ marginTop: 17, fontSize: 16 }}>
                 文案场景
                 </Typography>
                   <Select
                  className="normalSelect"
                  style={{ width: 374 }}
                  // placeholder={
                  //   '示例：' + apiInfo.model_params[key].sample
                  // }
                  value={
                    selectValue ? selectValue : selectOption[0].title
                  }
                  onChange={(value) =>
                    // console.log("value",key,value)
                    this.onChangeParams(value)
                  }
                >
                  {selectOption.map(
                    (option, index) => (
                      <Option key={option.model_id} value={index}>
                        {option.title}
                      </Option>
                    )
                  )}
                </Select> 
              </div>
            )
             : ""}
       
             

            {model_params ? (
              <div className="EnteringGinseng">
                <Typography style={{ marginTop: 17, fontSize: 16 }}>
                  输入内容
                </Typography>
                {Object.keys(model_params).map((key) => {
                  switch (model_params[key].type) {
                    case 'select':
                      return (
                        <div key={key}>
                          <Typography>
                            {model_params[key].name}
                          </Typography>
                          <Select
                            className="normalSelect"
                            style={{ width: 374 }}
                            placeholder={
                              '示例：' + model_params[key].sample[0]
                            }
                            value={
                              formDataShow[key] ? formDataShow[key] : undefined
                            }
                            onChange={(value) =>
                              // console.log("value",key,value)
                              this.onChangeFormData(key, value)
                            }
                          >
                            {model_params[key].label.map(
                              (option, index) => (
                                <Option key={option} value={option}>
                                  {option}
                                </Option>
                              )
                            )}
                          </Select>
                        </div>
                      )
                    case 'search':
                      return (
                        <div key={key}>
                          <Typography>
                            {model_params[key].name}
                          </Typography>
                          <Select
                            placeholder={
                              '示例：' + model_params[key].sample
                            }
                            allowClear
                            showSearch
                            className="searchSelect"
                            value={
                              formDataShow[key] ? formDataShow[key] : undefined
                            }
                            onChange={(value) =>
                              this.onChangeFormData(key, value)
                            }
                          >
                            {model_params[key].label.map(
                              (option, index) => (
                                <Option key={option} value={option}>
                                  {option}
                                </Option>
                              )
                            )}
                          </Select>
                        </div>
                      )

                    case 'tags':
                      return (
                        <div key={key}>
                          <Typography>
                            {model_params[key].name}{' '}
                          </Typography>
                          <div className="sellingPoints">
                            <InputTag
                              size={size}
                              defaultValue={model_params[key].label}
                              placeholder="请输入关键词"
                              onChange={(value) =>
                                this.onChangeFormData(key, value)
                              }
                            />
                          </div>
                        </div>
                      )
                    case 'input':
                      return (
                        <div key={key}>
                          <Typography style={{ position: 'relative' }}>
                            {model_params[key].name}
                            <span className="wordCount">
                              {sellPotionTextLength} / 100
                            </span>
                          </Typography>
                          <Space wrap className="miaoshu">
                            <TextArea
                              style={{ height: 100 }}
                              maxLength={100}
                              value={
                                formDataShow[key]
                                  ? formDataShow[key]
                                  : undefined
                              }
                              onChange={this.onSellPoiont.bind(this, key)}
                              placeholder={model_params[key].sample}
                            />
                          </Space>
                        </div>
                      )
                    case 'input_30':
                      return (
                        <div key={key}>
                          <Typography style={{ position: 'relative' }}>
                            {model_params[key].name}
                            <span className="wordCount">
                              {formDataShow[key] ? formDataShow[key].length : 0} / 30
                            </span>
                          </Typography>
                          <Space wrap className="miaoshu">
                            <TextArea
                              style={{ height: 52 }}
                              maxLength={30}
                              value={
                                formDataShow[key]
                                  ? formDataShow[key]
                                  : undefined
                              }
                              onChange={this.onSellPoiont30.bind(this, key)}
                              placeholder={model_params[key].sample}
                            />
                          </Space>
                        </div>
                      )
                    case 'input_800':
                      return (
                        <div key={key}>
                          <Typography style={{ position: 'relative' }}>
                            {model_params[key].name}
                            <span className="wordCount">
                              {sellPotionText800Length} / 800
                            </span>
                          </Typography>
                          <Space wrap className="miaoshu">
                            <TextArea
                              style={{ height: 200 }}
                              maxLength={800}
                              value={
                                formDataShow[key]
                                  ? formDataShow[key]
                                  : undefined
                              }
                              onChange={this.onSellPoiont800.bind(this, key)}
                              placeholder={model_params[key].sample}
                            />
                          </Space>
                        </div>
                      )
                    default:
                      break
                  }
                })}

                <div className="generatedNumber">
                  {/* 生成 */}
  
                  {/* <Space size="large" style={{ marginLeft: 10 }}>
                    <Select
                      defaultValue="长文案"
                      style={{ width: 93, color: '#1A1A1A' }}
                      onChange={this.onChangeFormData.bind(this, 'text_length')}
                    >
                      {['长', '中', '短'].map((option, index) => (
                        <Option
                          key={option}
                          disabled={index === 3}
                          value={option}
                        >
                          {option + '文案'}
                        </Option>
                      ))}
                    </Select>
                  </Space> */}
                  <Button
                    type="primary"
                    // ref={(node) => {this.container = node; }}
                    onClick={this.goGeneration.bind(this)}
                  >
                    立即生成
                  </Button>
                </div>
                <div style={{ height: 200 }} className="parent-centered "></div>
              </div>
            ) : (
              <div></div>
            )}
          </Sider>

          <Content>
            <div className="experience-result" id="result">
              <div id="result_title" className="rowSpaceBetween result_title">
                <Typography>生成结果</Typography>
                <Typography.Paragraph className="result-remarks">
                  (内容基于韦尼克AI创作引擎生成，仅供参考)
                </Typography.Paragraph>
              </div>

              {ParagraphContinuation.length > 0 || ContinuationLoding ? (
                <div className="Paragraph-generate">
                  {ContinuationLoding ? (
                    <Empty
                      className="Continuation-loding"
                      icon={<img src={Generating_img} />}
                      description={
                        <Typography
                          style={{
                            color: '#4e5969',
                            marginTop: 21,
                            fontSize: 16,
                          }}
                        >
                          正在生成中...
                        </Typography>
                      }
                    />
                  ) : (
                    <>

                        <>
                          {ParagraphContinuation.map((item, index) => {
                            return (     
                              <div className="generateResult">
                                {/* <div className="generateTitle">学生党闭眼入的眼霜<span>标题</span></div> */}
                                <div>
                                  <Space wrap>
                                    <TextArea
                                      autoSize
                                      value={item.sentence}
                                      style={{ minHeight: 64, width: '100%' }}
                                    />
                                  </Space>
                                  <div className="generateBtn">
                                    <span>{item.sentence.length} 字</span>
                                    <button
                                      onClick={this.oncopy.bind(this, item)}
                                    >
                                      复制
                                    </button>
                                  </div>
                                </div>
                              </div>
                              )
                              })}
                        </>
                
                    </>
                  )}
                </div>
              ) : requestMsg === 'error' ? (
                <Empty
                  className="Continuation-Empty Empty-error"
                  imgSrc={errorIcon}
                  description={
                    <Space direction="vertical">
                      <Typography
                        style={{
                          color: '#4e5969',
                          marginTop: 30,
                          fontSize: 16,
                        }}
                      >
                        抱歉，服务器生成错误
                      </Typography>
                      <Typography style={{ color: '#4e5969', fontSize: 16 }}>
                        可尝试
                        <span
                          style={{ color: '#406EFF', cursor: 'pointer' }}
                          onClick={this.ChangeAbatch.bind(this)}
                        >
                          {' '}
                          重新生成
                        </span>
                      </Typography>
                    </Space>
                  }
                />
              ) : (
                <Empty
                  className="Continuation-Empty Empty-none"
                  imgSrc={Emptydata_img}
                  description={
                    <Space direction="vertical">
                      <Typography
                        style={{
                          color: '#4e5969',
                          marginTop: 30,
                          fontSize: 16,
                        }}
                      >
                        暂无生成结果
                      </Typography>
                      <Typography style={{ color: '#4e5969', fontSize: 16 }}>
                        {requestMsgText}
                      </Typography>
                    </Space>
                  }
                />
              )}
            </div>
          </Content>
        </Layout>
        <UpgradeTips
          visible={visibleUpgrade}
          notification_status={upgradetips?.notice_type}
          notification_message={upgradetips?.message}
          model_capability={upgradetips?.model_capability}
          onCancel={() => this.setvisibleUpgrade(false)}
        ></UpgradeTips>
      </div>
    )
  }
}

export default enteringGinseng
