import React, { useState, useEffect, useRef } from "react";
import { useNavigate, Outlet } from "react-router-dom";
import {
  Layout,
  Grid,
  Space,
  Button,
  Typography,
  Link,
  Modal,
  Form,
  Input,
  Select,
  Message,
  Result,
  Avatar,
  Upload,
  Progress,
  Notification,
  Tag,
  Popconfirm,
} from "@arco-design/web-react";
import {
  IconPlus,
  IconEdit,
  IconExclamationCircleFill,
  IconRefresh,
  IconCamera,
} from "@arco-design/web-react/icon";
import {
  birthEight,
  getuserInfo,
  putuserInfo,
  putusermobile,
  checkWechatBind,
  deleteWechatBind,
  outAuth,
  getgrouppowerinfo,
  deleteuser,
  getconfig,
} from "api/apis";
import upload from "api/uploads";
import { b2ValueUnit } from "common/utils/index";
import history from "common/utils/history";
import IdentitySelection from "components/IdentitySelection";
import JQ from "jquery";
import "./personalcenter.scss";
import wxlogo from "asserts/wxlogo.png";
import errorImg from "asserts/Figure_crack.svg";
const Header = Layout.Header;
const Row = Grid.Row;
const Col = Grid.Col;
const datas = [
  {
    label: "账号名称",
    value: "",
    key: "nickname",
  },
  {
    label: "联系手机",
    value: "",
    key: "mobile",
    edit: false,
  },
  {
    label: "联系邮箱",
    value: "",
    key: "email",
  },
  {
    label: "用户ID",
    value: "",
    key: "id",
  },
  {
    label: "微信",
    value: false,
    key: "bind_wechat",
  },
  {
    label: "公司名称",
    value: "",
    key: "enterprise_name",
  },
];
const identityList = {
  PERSONAL: "个人用户",
  SUPER_ADMIN: "超级管理员",
  ADMIN: "管理员",
  STAFF: "成员",
};

export default function PersonalCenterinfo() {
  const [align, setAlign] = useState("center");
  const [data, setStr] = useState(datas);
  const navigate = useNavigate();
  const [other, setOther] = useState(0); //触发 render刷新 非必须可用项目其他方式
  const [isEdit, setisEdit] = useState(false);
  const [visibloperatio, setvisibloperatio] = useState(false);
  const [visible, setVisible] = React.useState(false);
  const [unbound, setUnbound] = React.useState(false);
  const [loading, setLoading] = React.useState(false);
  const [userinfo, setUserinfo] = useState({});
  const [showqrcode, setShowqrcode] = useState();
  const [refresh, setRefresh] = useState(false);
  const [secondsdata, setSecondsdata] = useState(false);
  const [avatar, setAvatar] = useState();
  const [file, setFile] = React.useState();
  const [identity, setidentity] = useState("");
  const [pathname, setpathname] = useState("");
  const [visibloperatio2, setVisibleoperation2] = useState(false);
  const [powerinfo, setpowerinfo] = useState();
  const [explain, setexplain] = useState();
  const cs = `arco-upload-list-item${
    file && file.status === "error" ? " is-error" : ""
  }`;
  const timeout = useRef(null);
  const setStrs = (label, value) => {
    data.map((ii, idx) => {
      if (ii.label == label) {
        data[idx].value = value;
      }
    });
    setOther(Math.random());
  };
  const setvisibleUpgrade = () => {
    history.push("/privilege");
    history.go();
  };
  const handlePreservation = () => {
    var regEmail = /^([a-zA-Z0-9_-])+@([a-zA-Z0-9_-])+(\.[a-zA-Z0-9_-])+/;
    if (data[2].value && !regEmail.test(data[2].value)) {
      Notification.warning({ content: "邮箱格式信息错误！" });
      return false;
    } else if (data[0].value.length < 2) {
      Notification.warning({ content: "账号名称不能小于两个字符！" });
      return false;
    }
    let params = {
      nickname: data[0].value,
      email: data[2].value,
      company: data[5].value,
      avatar: avatar,
    };
    //console.log(params, '修改数据');
    putuserInfo(params).then((res) => {
      if (res.code === 200) {
        Message.success("保存成功!");
        JQ("#infoselect").find(".username-ellipsis").text(params.nickname);
      }
    });
    // let mobileparams = {
    //     mobile: data[1].value,
    //     token: '',
    //     code: ''
    // }
    // putusermobile(mobileparams).then(res => {
    // })
    setisEdit(false);
  };
  const handleUnbound = () => {
    setVisible(true);
    setUnbound(true);
  };
  const getRecord = (news) => {
    setRefresh(false);
    birthEight().then((res) => {
      //console.log(res);
      if (res.code == 200) {
        let showqrcode =
          "https://mp.weixin.qq.com/cgi-bin/showqrcode?ticket=" +
          res.data.ticket;
        //console.log(showqrcode, 'showqrcode')
        let expire_seconds = res.data.expire_seconds;
        let reskey = res.data.key;
        setShowqrcode(showqrcode);
        setSecondsdata(res.data);
        setOther(Math.random());
        if (news) {
          expire_timer();
        }
      }
    });
  };
  const checkBind = (keys) => {
    checkWechatBind({ key: keys }).then((res) => {
      if (res.code === 200) {
        // let data = res.data.auth
        // localStorage.setItem('md5_key', data.signKey)
        // localStorage.setItem('token', data.token)
        usrinfo();
        clearTimeout(timeout.current);
        setVisible(false);
      } else if (res.code === 2102) {
        if (secondsdata.expire_seconds === 0) {
          clearTimeout(timeout.current);
          setRefresh(true);
          return;
        }
        expire_timer();
      } else {
        Notification.error({ content: res.msg });
      }
    });
  };
  const expire_timer = (start) => {
    if (start) {
      setVisible(true);
    }
    clearTimeout(timeout.current);
    timeout.current = setTimeout(() => {
      secondsdata.expire_seconds -= 1;
      //console.log(secondsdata.expire_seconds, 'seconds')
      checkBind(secondsdata.key);
    }, secondsdata.expire_seconds * 6);
  };
  const closeafter = () => {
    setVisible(false);
    clearTimeout(timeout.current);
  };
  const onUnbound = () => {
    setLoading(true);
    deleteWechatBind().then((res) => {
      if (res.code === 200) {
        setisEdit(false);
        usrinfo();
        setOther(Math.random());
      }
      setLoading(false);
      setVisible(false);
    });
  };
  const usrinfo = () => {
    getuserInfo().then((res) => {
      if (res.code === 200) {
        let userinfodata = res.data;
        setUserinfo(userinfodata);
        setAvatar(userinfodata.avatar);
        setidentity(userinfodata.group_permission);
        data.map((ii, idx) => {
          for (let i in userinfodata) {
            if (ii.key == i) {
              data[idx].value = userinfodata[ii.key];
            }
          }
        });
        setOther(Math.random());
        localStorage.setItem("userinfo", JSON.stringify(userinfodata));
      }
    });
  };
  const grouppowerinfo = () => {
    getgrouppowerinfo().then((res) => {
      if (res.code === 200) {
        let info = res.data;
        setpowerinfo(info);
        //console.log(info, 'powerinfo')
      }
    });
  };
  const handleUpload = (option) => {
    upload(option);
  };
  const setFiles = (fileList, file) => {
    setFile({
      ...file,
      url: URL.createObjectURL(file.originFile),
    });
    if (file.status === "done") {
      if (file.response) {
        setAvatar(file.response.url);
        let params = {
          avatar: file.response.url,
        };
        //console.log(params, '修改数据');
        putuserInfo(params).then((res) => {
          if (res.code === 200) {
            document.getElementById("headeravatar").src = file.response.url;
            Message.success("修改成功!");
          }
        });
      }
      //console.log(fileList, file, 'setFile')
    } else if (file.status === "error") {
      Notification.error({ content: "导入失败!" });
    }
  };
  const goManagement = () => {
    history.push("/personalcenter/membermanagement");
    history.go();
  };
  const goJump = (value) => {
    history.push(value);
    window.location.reload();
  };
  const setoutAuth = () => {
    if (identityList[identity] === "超级管理员") {
      setvisibloperatio(true);
    } else {
      setVisibleoperation2(true);
    }
  };
  const delTeamMember = () => {
    let params = { ids: JSON.stringify([userinfo.id]) };
    deleteuser(params).then((res) => {
      if (res.code === 200) {
        outAuth().then((res) => {
          if (res.code === 200) {
            localStorage.clear();
            window.location.reload();
            setVisibleoperation2(false);
          } else {
            Notification.error({ content: res.msg });
          }
        });
      } else {
        Notification.error({ content: res.msg });
      }
    });
  };
  const goQuitTeam = () => {
    delTeamMember();
  };
  const colsEdit = () => {
    setisEdit(false);
    usrinfo();
  };
  const setbackground = (color) => {
    return color ? color : "transparent";
  };
  const setconfig = () => {
    getconfig({ key: "account-privileges" }).then((res) => {
      if (res.code === 200) {
        let list = res.data;
        let obj = list.value;
        setexplain(obj);
      }
    });
  };
  const setvip = (res) => {
    let list = [];
    let isvip = "PERSONAL";
    let vip_grade = userinfo && userinfo.vip_level;
    if (!res[identity]) {
      isvip = "COMPANY";
    }
    list = res[isvip]["vip" + vip_grade];
    return list;
  };
  function imgError(e) {
    let evn = e;
    let img = evn.srcElement ? evn.srcElement : evn.target;
    img.src = errorImg;
  }
  useEffect(() => {
    const urlParams = new URL(window.location.href);
    const pathname = urlParams?.pathname;
    setpathname(pathname);
    if (pathname === "/personalcenter") {
      setStr(data);
      getRecord();
      usrinfo();
      grouppowerinfo();
      setconfig();
    }
  }, []);

  return (
    <div className="main-content personal">
      <>
        {pathname !== "/personalcenter" ? (
          <Outlet />
        ) : (
          <>
            <Header>
              <div className="Header-init">
                <div className="Header-title">
                  <Space align={"center"}>
                    <Typography.Text>个人中心</Typography.Text>
                  </Space>
                </div>
              </div>
            </Header>
            <div className="Card-main">
              <div className="personal-order-card">
                <div className="personal-card-title">基本信息</div>
                <div className="personal-card-header-extra">
                  {isEdit ? (
                    <Space align={align}>
                      <Button
                        className={"custom-cancel-button"}
                        style={{ padding: "0 7px" }}
                        onClick={() => colsEdit()}
                      >
                        取消
                      </Button>
                      <Button
                        className={"custom-confirm-button"}
                        style={{ padding: "0 7px" }}
                        onClick={() => handlePreservation()}
                      >
                        保存
                      </Button>
                    </Space>
                  ) : (
                    <Button type="outline" onClick={() => setisEdit(true)}>
                      修改
                    </Button>
                  )}
                </div>
                <div className="personal-card-table">
                  <Row className="grid-gutter-demo" gutter={72}>
                    <Col flex="116px">
                      <div className="arco-descriptions-row">
                        <div className="arco-descriptions-row-label">
                          账号头像：
                        </div>
                        <div className="arco-descriptions-item-value">
                          <div className="personal-avatar">
                            <Upload
                              fileList={file ? [file] : []}
                              showUploadList={false}
                              accept=".jpg,.png"
                              customRequest={(option) => {
                                handleUpload(option);
                              }}
                              onChange={(_, currentFile) => {
                                setFiles(_, currentFile);
                              }}
                              beforeUpload={(file) => {
                                var AllImgExt = ".jpg|.png|";
                                var extName = file.name
                                  .substring(file.name.lastIndexOf("."))
                                  .toLowerCase(); //（把路径中的所有字母全部转换为小写）
                                if (AllImgExt.indexOf(extName + "|") === -1) {
                                  let ErrMsg =
                                    "该文件类型不允许上传。请上传 .jpg、.png  类型的文件，当前文件类型为" +
                                    extName;
                                  Message.error(ErrMsg);
                                  return false;
                                }
                                const isLt10M = !(
                                  b2ValueUnit(file.size)[1] === "B" ||
                                  b2ValueUnit(file.size)[1] === "KB" ||
                                  (b2ValueUnit(file.size)[1] === "MB" &&
                                    b2ValueUnit(file.size)[0] <= 5)
                                );
                                return new Promise((resolve, reject) => {
                                  if (isLt10M) {
                                    Message.error("上传文件不能大于5MB");
                                    reject();
                                  } else {
                                    resolve();
                                  }
                                });
                              }}
                            >
                              <div className={cs}>
                                {file && file.url ? (
                                  <>
                                    <Avatar
                                      triggerIcon={<span>修改</span>}
                                      triggerType="mask"
                                      size={56}
                                    >
                                      <img
                                        alt="avatar"
                                        src={avatar}
                                        onError={imgError}
                                        style={{ height: "auto" }}
                                      />
                                    </Avatar>
                                    {file.status === "uploading" &&
                                      file.percent < 100 && (
                                        <Progress
                                          percent={file.percent}
                                          type="circle"
                                          size="mini"
                                          style={{
                                            position: "absolute",
                                            left: "50%",
                                            top: "50%",
                                            transform:
                                              "translateX(-50%) translateY(-50%)",
                                          }}
                                        />
                                      )}
                                  </>
                                ) : (
                                  <Avatar
                                    triggerIcon={<span>修改</span>}
                                    triggerType="mask"
                                    size={56}
                                  >
                                    {avatar ? (
                                      <img
                                        alt="avatar"
                                        src={avatar}
                                        onError={imgError}
                                        style={{ height: "auto" }}
                                      />
                                    ) : (
                                      ""
                                    )}
                                  </Avatar>
                                )}
                              </div>
                            </Upload>
                          </div>
                        </div>
                      </div>
                    </Col>
                    <Col flex="auto" className="flex-auto">
                      {identity && identity !== "PERSONAL" ? (
                        <div className="arco-descriptions-row">
                          <div className="arco-descriptions-row-label">
                            身份认证：
                          </div>
                          <div className="arco-descriptions-item-value">
                            <span className="text-value">
                              {identityList[identity]}
                            </span>
                          </div>
                        </div>
                      ) : (
                        ""
                      )}
                    </Col>
                  </Row>
                  <Row className="grid-gutter-demo" gutter={72}>
                    <Col flex="116px">
                      <div className="arco-descriptions-row">
                        <div className="arco-descriptions-row-label">
                          {data[0].label}：
                        </div>
                        <div className="arco-descriptions-item-value">
                          {isEdit ? (
                            <Input
                              placeholder=""
                              value={data[0].value}
                              maxLength={15}
                              onChange={(value) =>
                                setStrs(data[0].label, value)
                              }
                            />
                          ) : (
                            <>
                              <span className="text-value">
                                {data[0].value}
                              </span>
                            </>
                          )}
                        </div>
                      </div>
                    </Col>
                    <Col flex="auto" className="flex-auto">
                      <div className="arco-descriptions-row">
                        <div className="arco-descriptions-row-label">
                          {data[2].label}：
                        </div>
                        <div className="arco-descriptions-item-value">
                          {isEdit ? (
                            <Input
                              placeholder=""
                              value={data[2].value}
                              onChange={(value) =>
                                setStrs(data[2].label, value)
                              }
                            />
                          ) : (
                            <span className="text-value">{data[2].value}</span>
                          )}
                        </div>
                      </div>
                    </Col>
                  </Row>
                  <Row className="grid-gutter-demo" gutter={72}>
                    <Col flex="116px">
                      <div className="arco-descriptions-row">
                        <div
                          className="arco-descriptions-row-label"
                          style={{ marginRight: 14 }}
                        >
                          {data[3].label}：
                        </div>
                        <div className="arco-descriptions-item-value">
                          <Typography.Paragraph
                            copyable
                            className={`copyable-Paragraph ${
                              isEdit ? "text-value-edit" : ""
                            }`}
                          >
                            {data[3].value}
                          </Typography.Paragraph>
                        </div>
                      </div>
                    </Col>
                    <Col flex="auto" className="flex-auto">
                      <div className="arco-descriptions-row">
                        <div
                          className="arco-descriptions-row-label"
                          style={{ marginRight: 28 }}
                        >
                          {data[4].label}：
                        </div>
                        <div className="arco-descriptions-item-value">
                          {data[4].value ? (
                            <span
                              className="text-value"
                              style={{ paddingRight: 2 }}
                            >
                              已绑定
                            </span>
                          ) : (
                            <Link
                              style={{ textDecoration: "underline" }}
                              onClick={() => expire_timer(true)}
                            >
                              点击绑定微信
                            </Link>
                          )}
                          {data[4].value && isEdit ? (
                            <Link
                              style={{ textDecoration: "underline" }}
                              onClick={() => handleUnbound()}
                            >
                              点击解绑
                            </Link>
                          ) : (
                            ""
                          )}
                        </div>
                      </div>
                    </Col>
                  </Row>
                  <Row className="grid-gutter-demo" gutter={72}>
                    <Col flex="116px">
                      <div className="arco-descriptions-row">
                        <div className="arco-descriptions-row-label">
                          {data[1].label}：
                        </div>
                        <div className="arco-descriptions-item-value">
                          {isEdit && data[1].edit ? (
                            <Input
                              placeholder=""
                              value={data[1].value}
                              maxLength="15"
                              onChange={(value) =>
                                setStrs(data[1].label, value)
                              }
                            />
                          ) : (
                            <span
                              className={`text-value ${
                                isEdit ? "text-value-edit" : ""
                              }`}
                            >
                              {data[1].value}
                            </span>
                          )}
                        </div>
                      </div>
                    </Col>
                    <Col flex="auto" className="flex-auto">
                      {identity && identity !== "PERSONAL" ? (
                        <div className="arco-descriptions-row">
                          <div className="arco-descriptions-row-label">
                            {data[5].label}：
                          </div>
                          <div className="arco-descriptions-item-value">
                            <span
                              className={`text-value ${
                                isEdit ? "text-value-edit" : ""
                              }`}
                            >
                              {data[5].value}
                            </span>
                            {!isEdit && identity !== "PERSONAL" ? (
                              <span
                                className="Account-operation"
                                onClick={() => goManagement()}
                              >
                                {identityList[identity] === "超级管理员" ||
                                identityList[identity] === "管理员"
                                  ? "团队管理"
                                  : "查看团队"}
                              </span>
                            ) : (
                              ""
                            )}
                          </div>
                        </div>
                      ) : (
                        ""
                      )}
                    </Col>
                  </Row>
                </div>
              </div>
              {/* <div className="Account-authority">
                <div className="personal-card-title rowSpaceBetween">
                  <div>
                    账户权限
                    <div
                      className={`skew-x ${
                        identity === "PERSONAL" || !identity ? "bule" : ""
                      }`}
                      onClick={() => setvisibleUpgrade(true)}
                    >
                      <div className="AlibabaPuHuiTi-Medium">
                        VIP
                        {userinfo && userinfo.vip_level > 0
                          ? userinfo.vip_level
                          : ""}
                      </div>
                    </div>
                  </div>
                  {identity === "PERSONAL" || !identity ? (
                    <span
                      className="Account-tag AlibabaPuHuiTi-Medium"
                      onClick={() => goJump("/authentication")}
                    >
                      <span></span>升级企业尊享版
                    </span>
                  ) : (
                    ""
                  )}
                </div>
                <div className="billing-list personal-card-table">
                  <div className="billing-table">
                    <div className="table-bodys">
                      <div
                        className="table-hr"
                        style={{ "--left": "20%" }}
                      ></div>
                      {explain?.bodys.length > 0 &&
                        explain.bodys.map((item, key) => {
                          let id = "th_id" + key;
                          return (
                            <div
                              className="table-th rowStart"
                              key={key}
                              id={id}
                            >
                              <div
                                className="table-td columnCenter"
                                style={{
                                  "--width": "20%",
                                }}
                              >
                                <div
                                  className="table-td-full columnCenter"
                                  style={{
                                    "--background": setbackground("#FAFAFA"),
                                  }}
                                >
                                  {item.type}
                                </div>
                              </div>
                              <div
                                className="table-td"
                                style={{
                                  "--width": "80%",
                                }}
                              >
                                {item.body &&
                                  item.body.map((item2, key2) => {
                                    return (
                                      <div
                                        className="table-th rowCenter"
                                        key={key2}
                                      >
                                        <div
                                          className="table-hr"
                                          style={{ "--left": "30%" }}
                                        ></div>
                                        <div
                                          className="table-hr"
                                          style={{ "--left": "65%" }}
                                        ></div>
                                        <div
                                          className="table-hr"
                                          style={{ "--left": "100%" }}
                                        ></div>
                                        <div
                                          className="table-td"
                                          style={{
                                            "--width": "30%",
                                          }}
                                        >
                                          {item2.title}
                                        </div>
                                        <div
                                          className="table-td"
                                          style={{
                                            "--width": "35%",
                                          }}
                                        >
                                          <div className="table-th">
                                            {item2.model.map((item3, index) => {
                                              return (
                                                <div
                                                  className="table-th columnCenter"
                                                  key={index}
                                                >
                                                  <div>{item3}</div>
                                                </div>
                                              );
                                            })}
                                          </div>
                                        </div>
                                        <div
                                          className="table-td"
                                          style={{
                                            "--width": "35%",
                                          }}
                                        >
                                          <div className="table-th" key={key2}>
                                            {setvip(item2) &&
                                              setvip(item2).map(
                                                (item3, index) => {
                                                  return (
                                                    <div
                                                      className="table-th columnCenter"
                                                      key={index}
                                                    >
                                                      <div>{item3}</div>
                                                    </div>
                                                  );
                                                }
                                              )}
                                          </div>
                                        </div>
                                      </div>
                                    );
                                  })}
                              </div>
                            </div>
                          );
                        })}
                    </div>
                  </div>
                </div>
              </div> */}
            </div>

            <Modal
              title={
                <div style={{ textAlign: "left" }}>
                  {data[4].value ? "解除绑定" : "绑定提示"}
                </div>
              }
              footer={
                data[4].value ? (
                  <>
                    <Button
                      onClick={() => {
                        setVisible(false);
                      }}
                      className="custom-cancel-button"
                    >
                      否
                    </Button>
                    <Button
                      loading={loading}
                      onClick={() => {
                        onUnbound();
                      }}
                      type="primary"
                      style={{ marginLeft: 12 }}
                      className="custom-confirm-button"
                    >
                      是
                    </Button>
                  </>
                ) : null
              }
              visible={visible}
              onCancel={() => closeafter()}
              className="Modal-ma"
            >
              {data[4].value ? (
                <p className={"unboundstyle"}>是否解除当前微信绑定？</p>
              ) : null}

              {data[4].value ? (
                ""
              ) : (
                <>
                  <div className="Modal-wxma">
                    <div className="logowx">
                      <img src={wxlogo} />
                    </div>
                    <img src={showqrcode} />
                    {refresh ? (
                      <div
                        className="order_code_mask"
                        onClick={() => getRecord(true)}
                      >
                        <IconRefresh className="freshicon" />
                        <div>二维码已过期，点击刷新</div>
                      </div>
                    ) : (
                      ""
                    )}
                  </div>
                  <div>请扫码进行微信绑定</div>
                </>
              )}
            </Modal>
            <Modal
              style={{
                width: 472,
              }}
              className="dissolution"
              title="解散团队"
              visible={visibloperatio}
              footer={null}
              onCancel={() => setvisibloperatio(false)}
            >
              <div style={{ textAlign: "center" }}>
                <div className="serviceQRcode"></div>
                <div>您好，解散团队涉及较多信息确认程序</div>
                <div>请联系官方客服处理</div>
              </div>
            </Modal>
            <Modal
              style={{
                width: 472,
              }}
              className="deleteModal Quit-team"
              title="退出团队"
              visible={visibloperatio2}
              okText="确认"
              cancelText="取消"
              onOk={() => goQuitTeam()}
              onCancel={() => setVisibleoperation2(false)}
            >
              <p>是否确定退出当前企业团队？</p>
            </Modal>
          </>
        )}
      </>
    </div>
  );
}
