import ReactJson from 'react-json-view'
import React, { Component } from 'react'

class Index extends Component {
  constructor(props) {
    super(props)
    this.state = {
      propsVal: {
        name: false, // JSON数据的根节点(用默认或指定的根节点包裹自己的数据)
        src: { ...this.props.data }, // 需要展示的JSON数据
        theme: 'rjv-default', // 支持base-16主题
        iconStyle: 'circle', // circle(圆)、triangle(三角形)、square(正方形)
        indentWidth: 4, // 首行缩进长度
        collapsed: 20, // 节点折叠
        collapseStringsAfterLength: false, // 超出内容会变成…的功能
        displayDataTypes: true, // 数据类型会出现在数据的前缀值
        displayObjectSize: true, // 对象和数组被标记为大
      },
    }
  }

  render() {
    return <ReactJson {...this.state.propsVal} />
  }
}

export default Index
