import React, { Component } from "react";
import {
    Grid,
    Card,
    Popover,
    Modal,
    Collapse
} from "@arco-design/web-react";

const CollapseItem = Collapse.Item;

class comboModal extends Component {
    constructor(props) {
        super(props);
        this.state = {
            heads: {
                //头部配置-width：头部对应宽度
                '功能': { width: "20%" },
                '模式': { width: "20%" },
                '功能描述': { width: "60%" },
            },
            bodys1: [
                {
                    "type": "PPT生成",
                    "payservice": [
                        {
                            "function": ["快速模式"],
                            "metering": [
                                "基于用户指令生成标题、大纲，模版、丰富度，最终生成PPT",
                            ],
                        },
                    ],
                },
                {
                    "type": "PPT下载",
                    "payservice": [
                        {
                            "function": ["PDF", "PNG"],
                            "metering": [
                                "可免费下载PDF文件",
                                "可免费下载PNG文件",
                            ],
                        },
                    ],
                },
                {
                    "type": "编辑器",
                    "payservice": [
                        {
                            "function": ["/"],
                            "metering": [
                                "在线PPT编辑器",
                            ],
                        },
                    ],
                },
            ],
            bodys2: [
                {
                    "type": "PPT生成",
                    "payservice": [
                        {
                            "function": ["快速模式"],
                            "metering": [
                                "基于用户指令生成标题、大纲，模版、丰富度，最终生成PPT",
                            ],
                        },
                    ],
                },
                {
                    "type": "长文本生成",
                    "payservice": [
                        {
                            "function": ["快速模式"],
                            "metering": [
                                "基于用户长文本生成标题、大纲，模版、丰富度，最终生成PPT",
                            ],
                        },
                    ],
                },
                {
                    "type": "多文本生成",
                    "payservice": [
                        {
                            "function": ["/"],
                            "metering": [
                                "基于用户上传多份文档生成标题、流式大纲，模版、丰富度，最终生成PPT",
                            ],
                        },
                    ],
                },
                {
                    "type": "编辑器",
                    "payservice": [
                        {
                            "function": ["/"],
                            "metering": [
                                "在线PPT编辑器",
                            ],
                        },
                    ],
                },
                {
                    "type": "PPT下载",
                    "payservice": [
                        {
                            "function": ["PDF", "PNG","PPTX"],
                            "metering": [
                                "可免费下载PDF文件",
                                "可免费下载PNG文件",
                                "可下载PPTX文件",
                            ],
                        },
                    ],
                },
            ],
            bodys3: [
                {
                    "type": "PPT生成",
                    "payservice": [
                        {
                            "function": ["快速模式"],
                            "metering": [
                                "基于用户指令生成标题、大纲，模版、丰富度，最终生成PPT",
                            ],
                        },
                    ],
                },
                {
                    "type": "长文本生成",
                    "payservice": [
                        {
                            "function": ["快速模式"],
                            "metering": [
                                "基于用户长文本生成标题、大纲，模版、丰富度，最终生成PPT",
                            ],
                        },
                    ],
                },
                {
                    "type": "多文本生成",
                    "payservice": [
                        {
                            "function": ["/"],
                            "metering": [
                                "基于用户上传多份文档生成标题、流式大纲，模版、丰富度，最终生成PPT",
                            ],
                        },
                    ],
                },
                {
                    "type": "编辑器",
                    "payservice": [
                        {
                            "function": ["/"],
                            "metering": [
                                "在线PPT编辑器",
                            ],
                        },
                    ],
                },
                {
                    "type": "PPT下载",
                    "payservice": [
                        {
                            "function": ["PDF", "PNG","PPTX"],
                            "metering": [
                                "可免费下载PDF文件",
                                "可免费下载PNG文件",
                                "可下载PPTX文件",
                            ],
                        },
                    ],
                },
                {
                    "type": "指令更换主题色",
                    "payservice": [
                        {
                            "function": ["文档级"],
                            "metering": [
                                "根据指令更换模版颜色",
                            ],
                        },
                    ],
                },
                {
                    "type": "指令更换模版",
                    "payservice": [
                        {
                            "function": ["文档级"],
                            "metering": [
                                "根据所选模版，基于已生成的内容生成新的ppt",
                            ],
                        },
                    ],
                },
                {
                    "type": "指令更换字体",
                    "payservice": [
                        {
                            "function": ["文档级"],
                            "metering": [
                                "全文更换字体",
                            ],
                        },
                    ],
                },
                {
                    "type": "指令生成PPT单页",
                    "payservice": [
                        {
                            "function": ["页面级"],
                            "metering": [
                                "生成单页PPT插入",
                            ],
                        },
                    ],
                },
            ],
        };
    }

    componentDidMount() {

    }
    setbackground(color) {
        return color ? color : "transparent";
    }
    setObject(arr) {
        let newarray = [];
        for (let key in arr) {
            newarray.push(key);
        }
        return newarray;
    }

    render() {
        const {
            heads,
            bodys1,
            bodys2,
            bodys3,
        } = this.state;
        var widthleft = 0;
        var widthleft2 = 0;
        var widthleft3 = 0;
        return (
            <div className="billing-list">


                <Collapse
                    defaultActiveKey={['1']}
                    style={{ maxWidth: 1180 }}
                >
                    <CollapseItem header='免费版' name='1'>

                        <div className="billing-table">
                            <div className="table-th table-head rowStart">
                                {this.setObject(heads).map((item, key) => {
                                    return (
                                        <div
                                            className="table-td rowCenter"
                                            key={key}
                                            style={{ "--width": heads[item].width }}
                                        >
                                            {item}
                                        </div>
                                    );
                                })}
                            </div>
                            <div className="table-bodys">
                                {this.setObject(heads).map((item, key) => {
                                    widthleft += parseInt(heads[item].width);
                                    return (
                                        <div
                                            className="table-hr"
                                            key={key}
                                            style={{ "--left": widthleft + "%" }}
                                        ></div>
                                    );
                                })}

                                {bodys1.map((item, key) => {
                                    let id = "th_id" + key;
                                    return (
                                        <div className="table-th rowStart" key={key} id={id}>
                                            <div
                                                className="table-td columnCenter"
                                                style={{
                                                    "--width":
                                                        heads[this.setObject(heads)[0]].width,
                                                }}
                                            >
                                                <div
                                                    className="table-td-full columnCenter"
                                                    style={{
                                                        "--background": this.setbackground("#FAFAFA"),
                                                        "--width":
                                                            heads[this.setObject(heads)[0]].width,
                                                    }}
                                                >
                                                    {item.type}
                                                </div>
                                            </div>

                                            <div
                                                className="table-td"
                                                style={{
                                                    "--width":
                                                        parseInt(
                                                            heads[this.setObject(heads)[1]].width
                                                        ) +
                                                        parseInt(
                                                            heads[this.setObject(heads)[2]].width
                                                        ) +
                                                        "%",
                                                }}
                                            >
                                                {item.payservice.map((item2, key2) => {
                                                    let ids = id + key2;
                                                    return (
                                                        <div
                                                            className="table-th rowCenter"
                                                            key={key2}
                                                            id={ids}
                                                        >

                                                            <div className="table-td"
                                                                style={{
                                                                    "--width": "25%",
                                                                    "position": "relative"
                                                                }}>
                                                                <div className="table-th" key={key2}
                                                                    style={{
                                                                        "textAlign": "center",
                                                                    }}
                                                                >
                                                                    {item2.function.map((item3, index) => {
                                                                        return (
                                                                            <div
                                                                                className="table-th columnCenter"
                                                                                key={index}

                                                                            >
                                                                                <div>{item3}</div>
                                                                            </div>
                                                                        );
                                                                    })}

                                                                </div>

                                                            </div>

                                                            <div className="table-td"
                                                                style={{
                                                                    "--width": "75%",
                                                                }}>
                                                                <div className="table-th">
                                                                    {item2.metering.map((item3, index) => {
                                                                        return (
                                                                            <div
                                                                                className="table-th columnCenter"
                                                                                key={index}
                                                                            >
                                                                                <div
                                                                                    style={{
                                                                                        "width": "95%",
                                                                                    }}>{item3}</div>
                                                                            </div>
                                                                        );
                                                                    })}
                                                                </div>
                                                            </div>

                                                        </div>
                                                    );
                                                })}
                                            </div>

                                        </div>
                                    );
                                })}
                            </div>
                        </div>


                    </CollapseItem>

                    <CollapseItem header='基础生成版' name='2'>

                        <div className="billing-table">
                            <div className="table-th table-head rowStart">
                                {this.setObject(heads).map((item, key) => {
                                    return (
                                        <div
                                            className="table-td rowCenter"
                                            key={key}
                                            style={{ "--width": heads[item].width }}
                                        >
                                            {item}
                                        </div>
                                    );
                                })}
                            </div>
                            <div className="table-bodys">
                                {this.setObject(heads).map((item, key) => {
                                    widthleft2 += parseInt(heads[item].width);
                                    return (
                                        <div
                                            className="table-hr"
                                            key={key}
                                            style={{ "--left": widthleft2 + "%" }}
                                        ></div>
                                    );
                                })}

                                {bodys2.map((item, key) => {
                                    let id = "th_id" + key;
                                    return (
                                        <div className="table-th rowStart" key={key} id={id}>
                                            <div
                                                className="table-td columnCenter"
                                                style={{
                                                    "--width":
                                                        heads[this.setObject(heads)[0]].width,
                                                }}
                                            >
                                                <div
                                                    className="table-td-full columnCenter"
                                                    style={{
                                                        "--background": this.setbackground("#FAFAFA"),
                                                        "--width":
                                                            heads[this.setObject(heads)[0]].width,
                                                    }}
                                                >
                                                    {item.type}
                                                </div>
                                            </div>

                                            <div
                                                className="table-td"
                                                style={{
                                                    "--width":
                                                        parseInt(
                                                            heads[this.setObject(heads)[1]].width
                                                        ) +
                                                        parseInt(
                                                            heads[this.setObject(heads)[2]].width
                                                        ) +
                                                        "%",
                                                }}
                                            >
                                                {item.payservice.map((item2, key2) => {
                                                    let ids = id + key2;
                                                    return (
                                                        <div
                                                            className="table-th rowCenter"
                                                            key={key2}
                                                            id={ids}
                                                        >

                                                            <div className="table-td"
                                                                style={{
                                                                    "--width": "25%",
                                                                    "position": "relative"
                                                                }}>
                                                                <div className="table-th" key={key2}
                                                                    style={{
                                                                        "textAlign": "center",
                                                                    }}
                                                                >
                                                                    {item2.function.map((item3, index) => {
                                                                        return (
                                                                            <div
                                                                                className="table-th columnCenter"
                                                                                key={index}

                                                                            >
                                                                                <div>{item3}</div>
                                                                            </div>
                                                                        );
                                                                    })}

                                                                </div>

                                                            </div>

                                                            <div className="table-td"
                                                                style={{
                                                                    "--width": "75%",
                                                                }}>
                                                                <div className="table-th">
                                                                    {item2.metering.map((item3, index) => {
                                                                        return (
                                                                            <div
                                                                                className="table-th columnCenter"
                                                                                key={index}
                                                                            >
                                                                                <div
                                                                                    style={{
                                                                                        "width": "95%",
                                                                                    }}>{item3}</div>
                                                                            </div>
                                                                        );
                                                                    })}
                                                                </div>
                                                            </div>

                                                        </div>
                                                    );
                                                })}
                                            </div>


                                        </div>
                                    );
                                })}
                            </div>
                        </div>

                    </CollapseItem>

                    <CollapseItem header='智能编辑版' name='3'>
                        <div className="billing-table">
                            <div className="table-th table-head rowStart">
                                {this.setObject(heads).map((item, key) => {
                                    return (
                                        <div
                                            className="table-td rowCenter"
                                            key={key}
                                            style={{ "--width": heads[item].width }}
                                        >
                                            {item}
                                        </div>
                                    );
                                })}
                            </div>
                            <div className="table-bodys">
                                {this.setObject(heads).map((item, key) => {
                                    widthleft3 += parseInt(heads[item].width);
                                    return (
                                        <div
                                            className="table-hr"
                                            key={key}
                                            style={{ "--left": widthleft3 + "%" }}
                                        ></div>
                                    );
                                })}

                                {bodys3.map((item, key) => {
                                    let id = "th_id" + key;
                                    return (
                                        <div className="table-th rowStart" key={key} id={id}>
                                            <div
                                                className="table-td columnCenter"
                                                style={{
                                                    "--width":
                                                        heads[this.setObject(heads)[0]].width,
                                                }}
                                            >
                                                <div
                                                    className="table-td-full columnCenter"
                                                    style={{
                                                        "--background": this.setbackground("#FAFAFA"),
                                                        "--width":
                                                            heads[this.setObject(heads)[0]].width,
                                                    }}
                                                >
                                                    {item.type}
                                                </div>
                                            </div>

                                            <div
                                                className="table-td"
                                                style={{
                                                    "--width":
                                                        parseInt(
                                                            heads[this.setObject(heads)[1]].width
                                                        ) +
                                                        parseInt(
                                                            heads[this.setObject(heads)[2]].width
                                                        ) +
                                                        "%",
                                                }}
                                            >
                                                {item.payservice.map((item2, key2) => {
                                                    let ids = id + key2;
                                                    return (
                                                        <div
                                                            className="table-th rowCenter"
                                                            key={key2}
                                                            id={ids}
                                                        >

                                                            <div className="table-td"
                                                                style={{
                                                                    "--width": "25%",
                                                                    "position": "relative"
                                                                }}>
                                                                <div className="table-th" key={key2}
                                                                    style={{
                                                                        "textAlign": "center",
                                                                    }}
                                                                >
                                                                    {item2.function.map((item3, index) => {
                                                                        return (
                                                                            <div
                                                                                className="table-th columnCenter"
                                                                                key={index}

                                                                            >
                                                                                <div>{item3}</div>
                                                                            </div>
                                                                        );
                                                                    })}

                                                                </div>

                                                            </div>


                                                            <div className="table-td"
                                                                style={{
                                                                    "--width": "75%",
                                                                }}>
                                                                <div className="table-th">
                                                                    {item2.metering.map((item3, index) => {
                                                                        return (
                                                                            <div
                                                                                className="table-th columnCenter"
                                                                                key={index}
                                                                            >
                                                                                <div
                                                                                    style={{
                                                                                        "width": "95%",
                                                                                    }}>{item3}</div>
                                                                            </div>
                                                                        );
                                                                    })}
                                                                </div>
                                                            </div>

                                                        </div>
                                                    );
                                                })}
                                            </div>


                                        </div>
                                    );
                                })}
                            </div>
                        </div>

                    </CollapseItem>
                </Collapse>

            </div>
        );
    }
}
export default comboModal;