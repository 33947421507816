import React, { Component } from 'react'
import {
  Layout,
  Typography,
  Button,
  Space,
  Empty,
  Input,
  Notification,
  Select,
  Divider,
  Tag,
  Checkbox,
} from '@arco-design/web-react'
import { IconPlus, IconSync } from '@arco-design/web-react/icon'
import Emptydata_img from 'asserts/Emptydata_img.svg'
import Generating_img from 'asserts/Generating_img.gif'
import errorIcon from 'asserts/error_icon.svg'
import {
  getApiInfo,
  getRequest,
  putapp,
  getmarketing,
  knowledgeFreq,
  putmodelversion,
  check_resource,
  getconfig,
} from 'api/apis'
import history from 'common/utils/history'
import { CreateLink } from 'common/utils/business'
import { debounce, randomRgbColor, color2RGB } from 'common/utils'
import D3V5KG from '../../common/utils/d3.v5.kg'
import JQ from 'jquery'
import UpgradeTips from 'components/UpgradeTips'
import { sourceCancelClass } from 'api/cancel-request'
import './knowledge.scss'
import './knowledgereasoning.scss'
const Sider = Layout.Sider
const Content = Layout.Content
const TextArea = Input.TextArea
const Option = Select.Option
const CheckboxGroup = Checkbox.Group
// 封装组件
class knowledgereasoning extends Component {
  constructor(props) {
    super(props)

    this.state = {
      Selectoptions: JSON.parse(localStorage.getItem('Selectoptions')),
      no_data: false,
      optionVlue: '',
      versions: [],
      ParagraphContinuation: [],
      ContinuationLoding: false,
      record: JSON.parse(localStorage.getItem('record')),
      apiInfo: {
        model_id: '',
      },
      hasList: false,
      inputVal: '',
      defaultNodes: [
        {
          id: '0',
          name: '求职者',
          type: '求职者',
        },
      ],
      requestMsg: '',
      requestMsgText: '您还未输入关键信息',
      requestMsgText2: '',
      loading: false,
      input_describe: '输入文本',
      visibleUpgrade: false,
      upgradetips: null,
      inference: [],
      num: 6,
      deleteTage: false,
      graphTage: '',
      tags: '',
      brandOptions: [],
      processorOptions: [],
      selected: ['屏幕', '电池'],
      marketing: { question: '', rels: ['屏幕', '电池'], phones: '' },
      identity: JSON.parse(localStorage.getItem('userinfo'))
        ? JSON.parse(localStorage.getItem('userinfo')).group_permission
        : '',
    }
    this.isputapp = debounce(this.isputapp, 1000)
  }
  componentDidMount() {
    this.getApiInfo()

    window.addEventListener('resize', this.handleResize)
  }
  componentWillUnmount() {
    localStorage.removeItem('props')
    window.removeEventListener('resize', this.handleResize)
  }
  handleResize = () => {
    let winHeight = 0
    if (window.innerHeight) {
      winHeight = window.innerHeight
    } else if (document.body && document.body.clientHeight) {
      winHeight = document.body.clientHeight
    }
    if (document.documentElement && document.documentElement.clientHeight) {
      winHeight = document.documentElement.clientHeight
    }
    let layoutHeaders_h = document.getElementById('layoutHeaders').clientHeight
    if (layoutHeaders_h > winHeight) {
      layoutHeaders_h = 64
    }
    let height =
      winHeight -
      layoutHeaders_h -
      document.getElementById('renderHeaders').clientHeight -
      JQ('#evaluate-input').outerHeight(true)
    document.getElementById('layout_id').style.height = height + 'px'
    JQ('#continuation,#continuation-kg').css(
      'height',
      height - JQ('.result_title').eq(0).height() - 100
    )
  }
  setconfig() {
    getconfig({ key: 'knowledge_reasoning-params' }).then((res) => {
      if (res.code === 200) {
        let list = res.data.value

        let processorOptions = list.processorOptions
        this.setState({
          processorOptions: processorOptions,
        })
      }
    })
  }
  init() {
    this.renderKg(
      {
        links: [],
        nodes: this.state.defaultNodes,
      },
      ''
    )
  }
  createInstruct(data) {
    let html = ''
    for (let tag in data) {
      html +=
        '<li data-type="' +
        tag +
        '"><i style="background-color: ' +
        data[tag].fill +
        ';border-color: ' +
        data[tag].stroke +
        '"></i><b>' +
        tag +
        '</b></li>'
    }

    if (html) {
      JQ('#continuation-kg')
        .find('.kg-instruct')
        .show()
        .html('<ul>' + html + '</ul>')
    } else {
      JQ('#continuation-kg').find('.kg-instruct').hide().html('')
    }
  }
  lightHigh(content, items) {
    let regString = ''

    for (let i in items) {
      regString += '|' + items[i].name.replace(/(\|)/g, '\\$1')
    }

    if (!regString) {
      return content
    }

    regString = regString.substr(1)

    regString = regString.replace(/([\+\(\)\{\}\.\-\$\#\&\*\/])+/g, '\\$1')
    regString = '(' + regString + ')'
    let reg = new RegExp(regString, 'ig')

    content = content.replace(reg, '<a class="lh">$1</a>')

    return content
  }
  renderKg(data, text, after = false) {
    try {
      let $content = JQ('#result-kg')
      JQ('body').children('.more-info').length > 0 &&
        JQ('body').children('.more-info').remove()

      if (!after) {
        D3V5KG.clearNode()
      }
      D3V5KG.init('#continuation-kg', data, {
        width: $content.width(),
        height: $content.height() - 65,
        keyword: text,
        clickCallback: (data, index) => {
          //   this.setState({
          //     inputVal: data.name,
          //   });
          //   this.send("", true, false, data.id);
        },
      })

      this.createInstruct(D3V5KG.colorsMapNode)
    } catch (err) {
      console.error(err)
    }
  }
  createFrequencyLoading(borderColor) {
    return (
      '<svg width="14px" height="14px" viewBox="0 0 14 14" version="1.1" xmlns="http://www.w3.org/2000/svg" xmlns:xlink="http://www.w3.org/1999/xlink">\n' +
      '    <g stroke="none" stroke-width="1" fill="none" fill-rule="evenodd" stroke-linecap="round" stroke-linejoin="round">\n' +
      '        <g transform="translate(-1027.000000, -5525.000000)" stroke="' +
      borderColor +
      '">\n' +
      '            <g transform="translate(1028.450649, 5526.381275)">\n' +
      '                <path d="M11.264195,3.11872452 C10.2447155,1.26005063 8.26950806,0 6,0 C2.6862915,0 0,2.6862915 0,6 C0,9.3137085 2.6862915,12 6,12 C8.5186733,12 10.6748772,10.448084 11.5645408,8.24832289"></path>\n' +
      '                <polyline points="11.630406 0.38581436 11.630406 3.50453888 8.68436106 3.50453888"></polyline>\n' +
      '            </g>\n' +
      '        </g>\n' +
      '    </g>\n' +
      '</svg>'
    )
  }
  filter($self, type, borderColor) {
    if (!type) {
      if ($self.find('svg').length > 0) {
        $self.find('svg').remove()
        $self.find('i').show()
      }

      D3V5KG.filter(type, {})
      return
    }

    let arr = []

    for (let i in D3V5KG.nodes) {
      if (D3V5KG.nodes[i].type === type) {
        arr.push(D3V5KG.nodes[i].name)
      }
    }

    if (arr.length === 0) {
      return
    }

    let load = this.createFrequencyLoading(borderColor)

    $self.find('i').hide()
    $self.prepend(load)

    let items = {}
    items[type] = arr

    knowledgeFreq({
      items: JSON.stringify(items),
    }).then((res) => {
      if (res.code === 200) {
        D3V5KG.filter(type, res.data[type])
      } else {
        Notification.warning({ content: res.msg })
      }
      $self.find('svg').remove()
      $self.find('i').show()
    })
  }
  send(text, after = false, cut = true, nodesId = null) {
    const { apiInfo, marketing } = this.state
    let params = { app_id: this.state.record.id, num: this.state.num }
    if (apiInfo.graphic === 'evaluation') {
      params.text = JQ.trim(JQ('#kg-text').val())

      if (!params.text) {
        // Notification.warning({ content: "关键词不能为空！" });
        this.setState({
          requestMsg: '',
          requestMsgText2: '',
          requestMsgText: '关键词不能为空！',
          ContinuationLoading: false,
          loading: false,
          ParagraphContinuation: [],
          deleteTage: false,
          hasList: false,
          inference: [],
        })
        this.init()
        return false
      }
    } else if (apiInfo.graphic === 'marketing') {
      params.key = marketing.phones
      params.rels =
        marketing.rels.length > 0 ? JSON.stringify(marketing.rels) : '[]'
      if (marketing.rels.length === 0 || params.key === '') {
        // Notification.warning({ content: "关键词不能为空！" });
        this.setState({
          requestMsg: '',
          requestMsgText2: '',
          requestMsgText:
            params.key === '品牌不能为空！' ? '' : '参数类型不能为空！',
          ContinuationLoading: false,
          loading: false,
          ParagraphContinuation: [],
          deleteTage: false,
          hasList: false,
          inference: [],
        })
        this.init()
        return false
      }
    }

    if (this.state.deleteTage && this.state.inference.length === 0) {
      this.setState({
        requestMsg: '',
        requestMsgText2: '未发现更多推理条件',
        requestMsgText: '点击生成，获取更多推理式生成结果',
        ContinuationLoading: false,
        loading: false,
        ParagraphContinuation: [],
        deleteTage: false,
        hasList: false,
        inference: [],
      })
      this.init()
      return false
    }

    JQ('#kg-list').hide()

    this.setState({
      hasList: false,
      ContinuationLoading: true,
      loading: true,
      requestMsgText2: '',
    })
    if (this.state.inference.length > 0 && this.state.deleteTage) {
      let tags = []
      let inference = this.state.inference
      inference.map((item, i) => {
        tags.push(item.source)
      })
      params.tags = tags.join(' ')
      params.key = 'build_content'
      //   params.tags = params.tags.toString();
    }

    if (nodesId && params.tags) {
      params.nodes_id = nodesId
      params.graph = localStorage.getItem('graphTage')
      //   console.log(
      //     JSON.parse(localStorage.getItem("graphTage")),
      //     'localStorage.getItem("graphTage")'
      //   );
    }
    let that = this
    sourceCancelClass()
    this.getCheckResource(function (result) {
      if (result.code === 200) {
        getRequest(params).then((res) => {
          that.setState({
            loading: false,
          })
          if (res.code === 200) {
            let generations = []
            let renderKgValue = null
            if (apiInfo.graphic === 'marketing') {
              generations = res.data.generations

              params.text = params.key
              let arrayNodes = []
              let links = []
              res.data.text.forEach(function (item) {
                arrayNodes.push(item[0], item[2])
                links.push({ rela: item[1], source: item[0], target: item[2] })
              })
              let object = {}

              Array.from(new Set(arrayNodes)).forEach(function (key) {
                object[key] = {
                  name: key,
                  type: key,
                }
              })
              renderKgValue = {
                links: links,
                nodes: object,
              }
            } else {
              generations = res.data.text
              renderKgValue = res.data.graph
            }
            if (that.state.deleteTage) {
              that.setState({
                ParagraphContinuation: that.uniqueList(generations) || [],
                ContinuationLoading: false,
                loading: false,
                requestMsg: '',
                hasList: true,
                deleteTage: false,
              })
              localStorage.setItem('graphTage', JSON.stringify(renderKgValue))
              that.renderKg(renderKgValue, params.text, false)
            } else {
              that.setState({
                ParagraphContinuation: that.uniqueList(generations) || [],
                ContinuationLoading: false,
                loading: false,
                requestMsg: '',
                hasList: true,
                inference: res.data.inference
                  ? that.randomColor(res.data.inference)
                  : [],
                deleteTage: false,
              })
              localStorage.setItem('graphTage', JSON.stringify(renderKgValue))
              that.renderKg(renderKgValue, params.text, after)
            }
          } else if (res.code === 204) {
            that.setState({
              requestMsg: '',
              requestMsgText: '无法生成关联内容，请换个输入信息试试！',
              ContinuationLoading: false,
              loading: false,
              ParagraphContinuation: [],
              deleteTage: false,
              inference: [],
            })
          } else if (res.code === 400 || res.code === 3003) {
            that.setState({
              requestMsg: '',
              requestMsgText: res.msg,
              ContinuationLoading: false,
              loading: false,
              ParagraphContinuation: [],
              deleteTage: false,
              inference: [],
            })
          } else {
            that.setState({
              requestMsg: 'error',
              requestMsgText: res.msg,
              ContinuationLoading: false,
              loading: false,
              ParagraphContinuation: [],
              deleteTage: false,
              inference: [],
            })
          }
        })
      } else {
        that.setState({
          requestMsg: 'error',
          requestMsgText: result.msg,
          ContinuationLoading: false,
          loading: false,
          ParagraphContinuation: [],
          deleteTage: false,
        })
      }
    })
  }
  getCheckResource(callback) {
    let params = { model_capability: this.state.apiInfo?.model_capability }
    let PACKAGES = ['PACKAGE_FINISH', 'PACKAGE_EXPIRED']
    check_resource(params).then((res) => {
      if (res.code === 200) {
        if (res.data) {
          let new_upgradetips = res.data
          new_upgradetips.model_capability = params.model_capability
          this.setState({
            upgradetips: new_upgradetips,
          })
          if (PACKAGES[new_upgradetips.notice_type]) {
            Notification.warning({ content: new_upgradetips.message })
          } else {
            setTimeout(() => {
              this.setState({
                visibleUpgrade: true,
              })
            }, 100)
          }
        }
        callback(res)
      } else {
        callback(res)
      }
    })
  }
  getApiInfo(oneinit) {
    let params = {
      app_id: this.state.record.id,
    }
    // evaluation 评价  marketing 营销
    getApiInfo(params).then((res) => {
      if (res.code === 200) {
        let list = res.data
        if (res.data.model_params) {
          history.push('/myapp/enteringGinseng')
          history.go()
        } else {
          this.setState({
            apiInfo: list,
            versions: list.versions,
          })
          if (!oneinit) {
            setTimeout(() => {
              this.handleResize()
              this.init()
            }, 300)
          }
          if (list.graphic === 'evaluation') {
            if (list.type === 0) {
              this.onTextArea(
                list.input_describe
                  ? list.input_describe
                  : '技术发烧友 名列前茅 自学能力 主动积极'
              )
              this.isPhoneLegal()
            }
          } else if (list.graphic === 'marketing') {
            this.setconfig()
          }
          if (list.versions.length > 0) {
            this.setState({
              optionVlue: list.versions[0].id,
            })
            this.putmodelversion(list.versions[0].id)
          }
        }
      } else {
      }
    })
  }
  putmodelversion(optionVlue) {
    let params = {
      id: optionVlue,
    }
    putmodelversion(params).then((res) => {
      if (res.code === 200) {
        let list = res.data
      } else {
      }
    })
  }
  onsetPosition(value) {
    //console.log(value, "value");
    this.setState({
      optionVlue: value,
    })
    this.putmodelversion(value)
    this.isPhoneLegal()
  }
  onKeyDown(event) {
    //console.log(event.keyCode, "键盘事件");
    if (event.shiftKey && event.keyCode === 13) {
      var e = this.state.inputVal
      this.setState({
        inputVal: e,
      })
    } else if (event.ctrlKey && event.keyCode === 13) {
      event.cancelBubble = true
      event.preventDefault()
      event.stopPropagation()
      event.returnValue = false
      this.isPhoneLegal()
      return false
    } else if (event.keyCode === 13) {
      event.cancelBubble = true
      event.preventDefault()
      event.stopPropagation()
      this.isPhoneLegal()
      event.returnValue = false
      return false
    }
  }
  onTextArea(value) {
    this.setState({
      inputVal: value,
    })
  }
  isPhoneLegal = () => {
    setTimeout(() => {
      this.send()
    }, 500)

    return false
  }
  goResources() {
    history.push('/myapp/resources')
    localStorage.setItem('record', JSON.stringify(this.state.record))
    window.location.reload()
  }
  goInterfacetest() {
    history.push('/myapp/interfacetest')
    localStorage.setItem('record', JSON.stringify(this.state.record))
    window.location.reload()
  }
  ChangeAbatch(type) {
    // this.setState({
    //   ParagraphContinuation: [],
    //   loading: true,
    //   ContinuationLoading: true,
    //   requestMsg: "",
    // });
    setTimeout(() => {
      this.send()
    }, 500)
  }
  isputapp = (name, desc) => {
    this.putapp(name, desc)
  }
  putapp(name, desc) {
    let params = {
      id: this.state.record.id,
    }
    if (desc !== false) {
      params.desc = desc
    } else if (name !== false) {
      params.name = name
    }
    putapp(params).then((res) => {
      if (res.code === 200) {
      } else {
        this.getApiInfo(true)
        Notification.error({ content: res.msg })
      }
    })
  }
  onChangetitle(type, value) {
    //console.log(value, "value");
    var apiInfo = this.state.apiInfo
    if (type === 'desc') {
      if (value.length > 100) {
        apiInfo.app_desc = value.substring(0, 100)
        Notification.warning({ content: '修改内容超过100个字！' })
        return
      }
      apiInfo.app_desc = value
      this.setState({
        apiInfo: apiInfo,
      })
      this.isputapp(false, value)
    } else if (type === 'name') {
      if (value.length > 10) {
        apiInfo.app_name = value.substring(0, 20)
        Notification.warning({ content: '修改标题超过20个字！' })
        return
      }
      apiInfo.app_name = value
      this.setState({
        apiInfo: apiInfo,
      })
      this.isputapp(value, false)
    }
  }
  goaddApp() {
    history.replace('/myapp/addmyapp')
    let state = {
      model_capability: this.state.apiInfo.model_capability,
      checkedtype: this.state.apiInfo.model_id,
      model_id: this.state.apiInfo.model_id,
      name: this.state.apiInfo.model_name,
    }
    localStorage.setItem('props', JSON.stringify(state))
    localStorage.removeItem('record')
    window.location.reload()
  }
  addRow() {
    localStorage.removeItem('props')
    localStorage.setItem('record', JSON.stringify(this.state.apiInfo))
    history.replace('/enginefactory/model/management/add')
    window.location.reload()
  }
  setvisibleUpgrade(value) {
    this.setState({
      visibleUpgrade: value,
    })
  }
  randomColor(list) {
    if (list.length > 0) {
      list.map((item, i) => {
        let randomColor = randomRgbColor()
        item.color = randomColor.color
        item.bgcolor = randomColor.bgcolor
      })
    }
    // console.log(list, "list");
    return list
  }
  setNewVal(value) {
    //替换字符串中的所有特殊字符（包含空格）
    if (value != '') {
      const pattern =
        /[`~!@#$^&*()=|{}':;',\\\[\]\.<>\/?~！@#￥……&*（）——|{}【】'；：""'。，、？\s]/g
      return value.replace(pattern, '')
    }
  }
  // 数组中对象的某个属性值进行去重
  uniqueList = (arr) => {
    const res = new Map()
    return arr.filter(
      (a) =>
        !res.has(this.setNewVal(a.sentence)) &&
        res.set(this.setNewVal(a.sentence), 1)
    )
  }
  Onreplace(newstr) {
    let titleAry = ['招聘岗位：', '岗位要求：']
    let TextAreaValue = this.state.inputVal.toLowerCase()

    let strAry = TextAreaValue.split(/\s+/).filter(Boolean)

    if (this.state.apiInfo.graphic === 'marketing') {
      if (this.state.marketing.question) {
        strAry.push(this.state.marketing.question.split(/\s+/).filter(Boolean))
      }
      if (this.state.marketing.phones) {
        strAry.push(this.state.marketing.phones)
      }
      if (this.state.marketing.rels.length > 0) {
        this.state.marketing.rels.map((item, index) => {
          strAry.push(item.source)
        })
      }
    } else {
      if (this.state.inference.length > 0) {
        this.state.inference.map((item, index) => {
          strAry.push(item.source)
        })
      }
    }
    //console.log(strAry, "strAry");
    strAry.map((item, index) => {
      let reg = item
      let html = `<span class='Preamble'>${reg}</span>`
      newstr = newstr.replaceAll(reg, html)
    })
    titleAry.map((item, index) => {
      let reg = item
      let html = `<span class='item-title AlibabaPuHuiTi-Medium'>${reg}</span>`
      newstr = newstr.replace(reg, html)
    })
    let question = ''
    if (this.state.marketing.question) {
      question = `<span class="Preamble">${this.state.marketing.question}：</span>`
    }

    //console.log(strAry, "strAry");
    let htmls = { __html: question + newstr }
    return <div dangerouslySetInnerHTML={htmls}></div>
  }
  onCloseTage(name, key) {
    if (this.state.deleteTage) {
      Notification.warning({ content: '图谱正在生成中，请稍等！' })
      return false
    }
    this.setState({
      deleteTage: true,
    })
    let list = JSON.parse(localStorage.getItem('graphTage'))
    let nodesl = list.nodes
    let inference = this.state.inference
    for (let index in nodesl) {
      const element = nodesl[index]
      if (element.name === name) {
        let nodes_id = element.id
        inference.splice(key, 1)
        this.setState({
          inference: inference,
        })
        setTimeout(() => {
          this.send('', false, false, nodes_id)
        }, 10)
        return
      }
    }
  }
  setSelected(select) {
    // console.log(select, 'select')
    let marketing = this.state.marketing
    marketing.rels = select
    this.setState({
      marketing: marketing,
    })
  }
  onQuestion(val) {
    let marketing = this.state.marketing
    marketing.question = val
    this.setState({
      marketing: marketing,
    })
  }
  onChangeMarket(val) {
    let marketing = this.state.marketing
    marketing.phones = val
    this.setState({
      marketing: marketing,
    })
  }
  setMarketing(value) {
    sourceCancelClass()
    let params = {
      key: value,
      num: 10,
      mode: 'list_phones',
    }
    getmarketing(params).then((res) => {
      if (res.code === 200) {
        this.setState({
          brandOptions: res.data,
        })
      }
    })
  }

  render() {
    const {
      visibleUpgrade,
      upgradetips,
      apiInfo,
      versions,
      optionVlue,
      input_describe,
      inputVal,
      hasList,
      requestMsg,
      ContinuationLoading,
      requestMsgText,
      loading,
      inference,
      ParagraphContinuation,
      brandOptions,
      processorOptions,
      marketing,
    } = this.state

    return (
      <div className="knowledge knowledgereasoning">
        <div id="renderHeaders">
          <div className="GroupTable-fixe GroupTable-fixe-end">
            <div className="edit-title">
              <div className="Header-init">
                <div className="Header-title">
                  <Typography.Paragraph
                    className="Header-uptitle"
                    editable={
                      apiInfo.type === 0
                        ? null
                        : {
                            onChange: this.onChangetitle.bind(this, 'name'),
                          }
                    }
                  >
                    {apiInfo.app_name}
                  </Typography.Paragraph>
                </div>
              </div>
              <div className="Header-upParagraph">
                <Typography.Paragraph
                  className="Header-uptitle"
                  editable={
                    apiInfo.type === 0
                      ? null
                      : {
                          onChange: this.onChangetitle.bind(this, 'desc'),
                        }
                  }
                >
                  {apiInfo.app_desc}
                </Typography.Paragraph>
              </div>
            </div>
            <Space size={'medium'} align="center">
              {apiInfo.type === 0 ? (
                <Button
                  className="primarybtn custom-White-button"
                  onClick={this.goaddApp.bind(this)}
                >
                  保存应用
                </Button>
              ) : (
                <>
                  {versions.length === 0 ? (
                    <div></div>
                  ) : (
                    <Select
                      value={optionVlue}
                      placeholder="版本选择"
                      style={{ width: 220 }}
                      className="versionsSelect"
                      onChange={this.onsetPosition.bind(this)}
                      dropdownRender={(menu) => (
                        <div>
                          {menu}
                          <Divider
                            style={{
                              margin: 0,
                              marginTop: 6,
                              borderColor: '#f2f2f2',
                            }}
                          />
                          <div
                            style={{
                              display: 'flex',
                              alignItems: 'center',
                              padding: '5px 0px',
                            }}
                          >
                            <Button
                              style={{
                                width: '100%',
                                height: 41,
                                fontSize: 14,
                                padding: '0 6px',
                                borderRadius: 10,
                              }}
                              type="text"
                              onClick={this.addRow.bind(this)}
                            >
                              <IconPlus />
                              新增模型版本
                            </Button>
                          </div>
                        </div>
                      )}
                    >
                      {versions.map((option, index) => (
                        <Option key={index} value={option.id}>
                          {apiInfo.model_name}V{option.version}
                        </Option>
                      ))}
                    </Select>
                  )}
                  {apiInfo.type === 9 ? (
                    <div className="primary_model_name">
                      <span className="primary_model_name_title">
                        模型
                        <Divider type="vertical" />
                      </span>
                      {apiInfo.model_name}
                    </div>
                  ) : (
                    ''
                  )}
                  {/* <Button
                    shape="circle"
                    className="custom-White-button"
                    onClick={this.goResources.bind(this)}
                  >
                    应用资源
                  </Button> */}
                  {/* <Button
                    shape="circle"
                    className="custom-White-button share_button_wnk"
                    onClick={CreateLink.bind(this, apiInfo)}
                  >
                    分享应用
                  </Button> */}
                  <Button
                    style={{
                      display:
                        this.state.identity &&
                        this.state.identity !== 'PERSONAL'
                          ? 'inline-block'
                          : 'none',
                    }}
                    shape="circle"
                    className="custom-White-button"
                    onClick={this.goInterfacetest.bind(this)}
                  >
                    接口调用
                  </Button>
                </>
              )}
            </Space>
          </div>
        </div>
        <div className="semanticprototype-Layout" id="layout_id">
          <div id="evaluate-input">
            {apiInfo?.graphic === 'marketing' || apiInfo?.graphic === '' ? (
              <div className="Marketing-input">
                <Space size="large">
                  <div className="Marketing-Select">
                    <Select
                      placeholder="请选择品牌"
                      onChange={(value) => this.onChangeMarket(value)}
                      onInputValueChange={(value) => this.setMarketing(value)}
                      onFocus={() => this.setMarketing()}
                      className="versionsSelect brand"
                      showSearch={{
                        retainInputValue: true,
                      }}
                    >
                      {brandOptions.map((option, index) => (
                        <Option key={option} value={option}>
                          {option}
                        </Option>
                      ))}
                    </Select>
                  </div>
                  <Space size="medium">
                    <span style={{ fontSize: 14, marginLeft: 2 }}>
                      参数类型
                    </span>
                    <CheckboxGroup
                      value={marketing.rels}
                      options={processorOptions}
                      onChange={this.setSelected.bind(this)}
                    />
                  </Space>

                  <div className="Marketing-Select">
                    <Input
                      prefix={'标题'}
                      allowClear
                      placeholder="请输入营销标题"
                      onChange={this.onQuestion.bind(this)}
                    />
                  </div>
                  <Button
                    type="primary"
                    className="Start-generation"
                    // disabled={loading || !inputVal}
                    onClick={this.isPhoneLegal.bind(this)}
                  >
                    生成
                  </Button>
                </Space>
              </div>
            ) : apiInfo?.graphic === 'evaluation' ? (
              <div className="evaluate-input">
                <div className="evaluate-text">
                  <TextArea
                    className="Search"
                    placeholder={input_describe}
                    id="kg-text"
                    value={inputVal}
                    onChange={this.onTextArea.bind(this)}
                    onKeyDown={(event) => this.onKeyDown(event)}
                  />
                </div>
                <Divider />
                <div className="evaluate-tags">
                  {inference.length > 0 &&
                    inference.map((item, i) => (
                      <Tag
                        key={i}
                        closable
                        className="evaluate-tag"
                        style={{
                          margin: '7px 7px',
                          borderRadius: 4,
                          '--color': item.color,
                          '--bgcolor': item.bgcolor,
                        }}
                        size="medium"
                        bordered
                        onClose={this.onCloseTage.bind(this, item.source, i)}
                      >
                        {item.source}
                      </Tag>
                    ))}
                </div>
                <Button
                  type="primary"
                  className="Start-generation"
                  // disabled={loading || !inputVal}
                  onClick={this.isPhoneLegal.bind(this)}
                >
                  生成
                </Button>
              </div>
            ) : (
              <></>
            )}
          </div>
          <div className="evaluate-layout-content ">
            <div className="experience-result kg-result" id="result-kg">
              <div className="result_title">
                <Typography>知识图谱</Typography>
              </div>
              <div className="continuation-body" id="continuation-kg">
                <div className="kg-instruct" />
              </div>
            </div>
            <div className="experience-result kg-jd-list" id="result">
              <div className="rowSpaceBetween result_title">
                <Typography>生成关联内容</Typography>
                <Typography.Paragraph className="result-remarks">
                  (内容基于韦尼克AI创作引擎生成，仅供参考)
                </Typography.Paragraph>
              </div>
              <div className="continuation-body" id="continuation">
                {hasList || ContinuationLoading ? (
                  <>
                    <div id="kg-list" className="result-list" />
                    {ContinuationLoading ? (
                      <Empty
                        className="Continuation-loding"
                        icon={<img src={Generating_img} />}
                        description={
                          <Typography
                            style={{
                              color: '#4e5969',
                              marginTop: 21,
                              fontSize: 16,
                            }}
                          >
                            正在生成中...
                          </Typography>
                        }
                      />
                    ) : (
                      <div className="Continuation-result">
                        <div className="Continuation-list">
                          {ParagraphContinuation.map((item, index) => {
                            return (
                              <div className="Continuation-item" key={index}>
                                {this.Onreplace(item.sentence)}
                              </div>
                            )
                          })}
                        </div>
                      </div>
                    )}
                  </>
                ) : requestMsg === 'error' ? (
                  <Empty
                    className="Continuation-Empty Empty-error"
                    imgSrc={errorIcon}
                    description={
                      <Space direction="vertical">
                        <Typography
                          style={{
                            color: '#4e5969',
                            marginTop: 30,
                            fontSize: 16,
                          }}
                        >
                          抱歉，服务器生成错误
                        </Typography>
                        <Typography style={{ color: '#4e5969', fontSize: 16 }}>
                          可尝试
                          <span
                            style={{ color: '#406EFF', cursor: 'pointer' }}
                            onClick={this.ChangeAbatch.bind(this)}
                          >
                            重新生成
                          </span>
                        </Typography>
                      </Space>
                    }
                  />
                ) : (
                  <Empty
                    className="Continuation-Empty Empty-none"
                    imgSrc={Emptydata_img}
                    description={
                      <Space direction="vertical">
                        <Typography
                          style={{
                            color: '#4e5969',
                            marginTop: 30,
                            fontSize: 16,
                          }}
                        >
                          {this.state.requestMsgText2
                            ? this.state.requestMsgText2
                            : '暂无生成结果'}
                        </Typography>
                        <Typography style={{ color: '#4e5969', fontSize: 16 }}>
                          {requestMsgText}
                        </Typography>
                      </Space>
                    }
                  />
                )}
              </div>
              {ParagraphContinuation.length > 0 && !ContinuationLoading ? (
                <Button
                  icon={<IconSync />}
                  className="IconSync-btn custom-White-border-button"
                  id="IconSynbtn"
                  onClick={this.ChangeAbatch.bind(this)}
                >
                  换一批
                </Button>
              ) : null}
            </div>
          </div>
        </div>
        <UpgradeTips
          visible={visibleUpgrade}
          notification_status={upgradetips?.notice_type}
          notification_message={upgradetips?.message}
          model_capability={upgradetips?.model_capability}
          onCancel={() => this.setvisibleUpgrade(false)}
        ></UpgradeTips>
      </div>
    )
  }
}
export default knowledgereasoning
