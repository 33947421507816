import React, { Component } from "react";
import * as echarts from "echarts";

class LineEcharts extends Component {
  constructor(props) {
    super(props);
    this.state = {};
  }

  // 挂载完成之后，因为React初始化echarts时长宽可能会获取到顶层，所以延迟200去生成，不影响视觉效果
  componentDidMount() {
    setTimeout(() => {
      this.initEchart(this.props.data);
    }, 200);
  }

  // 更新props以后调用
  UNSAFE_componentWillReceiveProps(newProps) {
    //console.log(newProps.data, 'newProps.data')
    if (echarts.getInstanceByDom(this.echartsBox) === null) {
      setTimeout(() => {
        this.initEchart(newProps.data);
      }, 200);
    }
  }

  initEchart = (data) => {
    let myEcharts = echarts.init(this.echartsBox);
    let option = {
      color: ["#406EFF", "#44CCBF"], //关键加上这句话，legend的颜色和折线的自定义颜色就一致了
      grid: {
        top: "73",
        left: "3%",
        right: "4%",
        bottom: "4%",
        containLabel: true,
      },
      title: {
        text: this.props.title || "",
        left: "20",
        top: "30",
        textStyle: {
          color: "#0C0C0F", //颜色
          fontStyle: "normal", //风格
          fontWeight: "600", //粗细
          fontFamily: "Microsoft yahei", //字体
          fontSize: 14, //大小
          align: "center", //水平对齐
        },
      },
      tooltip: {
        show: true,
        trigger: "axis",
        axisPointer: {
          type: "cross",
          label: {
            show: false,
          },
        },
        extraCssText: "box-shadow: 0px 6px 58px 0px rgba(196,203,214,0.50)",
      },
      legend: {
        show: false,
        itemHeight: 11,
        itemWidth: 33,
        itemGap: 40, // 设置间距
        icon: "roundRect",
        data: ["成功调用总量", "失败调用总量"],
        left: "left",
        bottom: "bottom",
        textStyle: {
          fontSize: 14,
          color: "#0C0C0F",
        },
      },
      xAxis: {
        type: "category",
        boundaryGap: false,
        data: data.x,
        // axisTick: {
        //   alignWithLabel: true
        // }
      },
      yAxis: {
        type: "value",
        name: this.props.yname,
        nameGap: 15,
        min: 0,
        position: "left",
        axisTick: {
          inside: true,
        },
        axisLabel: {
          formatter: "{value}",
        },
        // axisLabel: {
        //   //保留一位小数
        //   formatter: function (value, index) {
        //     return value;
        //   }
        // }
      },
      series: [
        {
          name: data.y1Name ? data.y1Name : "成功调用总量",
          type: "line",
          data: data.y1,
        },
        {
          name: data.y2Name ? data.y2Name : "失败调用总量",
          type: "line",
          data: data.y2,
        },
      ],
    };
    myEcharts.clear();
    myEcharts.setOption(option);
    // myEcharts.on('finished', () => {
    //   myEcharts.resize()
    // })
    //当屏幕大小发生改变时，重新画图
    window.addEventListener("resize", () => {
      myEcharts.resize();
    });
  };

  render() {
    return (
      <div
        ref={(c) => {
          this.echartsBox = c;
        }}
        style={{ width: "100%", height: "100%" }}
      />
    );
  }
}

export default LineEcharts;
