import React, { Component } from "react";
import { Table, Empty, Card, Button, Tag, Spin } from "@arco-design/web-react";
import history from "common/utils/history";
import { invoice_list } from "api/apis";
import Emptydata_img from "asserts/Emptydata_img.svg";
import "./index.scss";
// 封装组件
class PaymentAdministration extends Component {
  constructor(props) {
    super(props);
    this.state = {
      loadingbody: true,
      userinfodata: JSON.parse(localStorage.getItem("userinfo"))
      ? JSON.parse(localStorage.getItem("userinfo"))
      : null,
      data: [],
      pagination: {
        sizeCanChange: false,
        showTotal: true,
        total: 0,
        pageSize: 10,
        current: 1,
        pageSizeChangeResetCurrent: true,
        showJumper: false,
      },
      loading: false,
      no_data: false,
      columns: [
        {
          title: "发票抬头",
          dataIndex: "invoice_name",
          align: "center",
          headerCellStyle: {
            textAlign: "center",
            height: 68,
          },
        },

        {
          title: "发票类型",
          dataIndex: "invoice_type2",
          align: "center",
          headerCellStyle: {
            textAlign: "center",
          },
        },
        {
          title: "发票金额",
          dataIndex: "total_price",
          align: "center",
          headerCellStyle: {
            textAlign: "center",
          },
        },
        {
          title: "开票状态",
          dataIndex: "status",
          align: "center",
          headerCellStyle: {
            textAlign: "center",
          },
          render: (col, record, index) => (
            <span>
              {
                <Tag
                  color={
                    col === 3
                      ? "green"
                      : col === 2
                      ? "arcoblue"
                      : col === 1
                      ? "orangered"
                      : col === 0
                      ? "red"
                      : "gray"
                  }
                >
                  {col === 0
                    ? "开票失败"
                    : col === 1
                    ? "待处理"
                    : col === 2
                    ? "开票中"
                    : col === 3
                    ? "已开票"
                    : "已取消"}
                </Tag>
              }
            </span>
          ),
        },
        {
          title: "发票号",
          dataIndex: "show_id",
          align: "center",
          headerCellStyle: {
            textAlign: "center",
          },
        },
        {
          title: "交易时间",
          dataIndex: "created_at",
          align: "center",
          headerCellStyle: {
            textAlign: "center",
          },
        },
      ],
    };
  }

  componentDidMount() {
    this.getinit();
  }
  UNSAFE_componentDidUpdate() {}
  getinit() {
    let pagination = this.state.pagination;
    pagination.current = 1;
    this.setState({
      pagination: pagination,
    });
    this.getinvoice_list();
  }
  getinvoice_list() {
    let page = {
      per_page: this.state.pagination.pageSize,
      page: this.state.pagination.current,
    };
    invoice_list(page).then((res) => {
      if (res.code === 200) {
        let list = res.data;
        if (list.count !== 0) {
          let pagination = this.state.pagination;
          pagination.total = list.count;
          this.setState({
            pagination: pagination,
            data: list.data,
            loading: false,
            loadingbody: false,
            no_data: list.count > 0 ? false : true,
          });
        } else {
          this.setState({
            loading: false,
            loadingbody: false,
            no_data: true,
          });
        }
      } else {
        this.setState({
          loading: false,
          loadingbody: false,
          no_data: true,
        });
      }
    });
  }
  onChangeTable(pagination) {
    const { current, pageSize } = pagination;
    let setPagination = this.state.pagination;
    setPagination.current = current;
    setPagination.pageSize = pageSize;
    this.setState({
      loading: false,
      pagination: setPagination,
    });
    this.getinvoice_list();
  }
  goInvoiceinFormation() {
    history.push("/payment/invoiceinformation");
    history.go();
  }
  onViewDetails(record) {
    localStorage.setItem("record", JSON.stringify(record));
    history.push("/payment/invoicedetail");
    history.go();
  }
  render() {
    const {
      loadingbody,
      checkbox,
      checkAll,
      type,
      selectedRowKeys,
      loading,
      columns,
      data,
      pagination,
      no_data,
    } = this.state;
    return (
      <div className="payment-administration">
        <div className="Header-init">
          <div className="Header-title">发票管理</div>
        </div>
        <div className="GroupTable-fixe" style={{ marginTop: 0 }}>
          <div></div>
          <Button
            type="primary"
            className="Header-init-btn"
            onClick={this.goInvoiceinFormation.bind(this)}
          >
            发票信息设置
          </Button>
        </div>
        {loadingbody ? (
          <Spin loading={loadingbody} dot className="loadingbody"></Spin>
        ) : (
          <Card
            bordered={false}
            hoverable
            style={{ marginBottom: 20, borderRadius: 20 }}
            className="Table-card"
          >
            <Table
              style={{ marginTop: 10 }}
              virtualized
              noDataElement={
                <Empty imgSrc={Emptydata_img} description={"暂无发票记录"} />
              }
              no_data={no_data}
              border={{ wrapper: false, cell: false }}
              loading={loading}
              columns={columns.concat({
                title: "操作",
                dataIndex: "operation",
                render: (col, record, index) => (
                  <>
                    <Button
                      type="outline"
                      className="outline"
                      onClick={() => this.onViewDetails(record)}
                    >
                      查看详情
                    </Button>
                  </>
                ),
                fixed: "200",
                align: "center",
              })}
              data={data}
              pagination={pagination.total === 0 ? false : pagination}
              onChange={this.onChangeTable.bind(this)}
              renderPagination={(paginationNode) => (
                <div
                  style={{
                    display: "flex",
                    justifyContent: "flex-end",
                    marginTop: 10,
                  }}
                >
                  {paginationNode}
                </div>
              )}
              rowKey="id"
              rowSelection={
                checkbox && {
                  type,
                  selectedRowKeys,
                  checkAll: checkAll,
                  onChange: (selectedRowKeys, selectedRows) => {
                    //console.log('onChange:', selectedRowKeys, selectedRows);
                    this.setSelectedRowKeys(selectedRowKeys);
                  },
                  onSelect: (selected, record, selectedRows) => {
                    //console.log('onSelect:', selected, record, selectedRows)
                  },
                  checkboxProps: (record) => {
                    return {
                      disabled: !record.enable_del,
                    };
                  },
                }
              }
            />
          </Card>
        )}
      </div>
    );
  }
}
export default PaymentAdministration;
